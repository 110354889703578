import { ORDER_CREATE_TYPES, RecipientType } from 'containers/OrderTypeModal/constants'
import { camelCaseKey } from 'utils/misc'
import { OC_SERVICE_TYPES } from 'containers/Base/constants'
import _ from 'lodash'
import csvMapping from 'utils/csvMapping'

export const generateErrorData = (
  orders,
  isBulkUpload,
  orderCreate,
  mappings,
  shipper,
  isCodSupported,
  isAllowedToUseAccountBalance,
  isB2BBundleSupported,
  allowedDeliveryTimeslots
) => {
  const mapping = _.find(mappings, {
    id: orderCreate?.csvMapping?.id
  })

  let header, labelToKey
  const bulkUploadData = orderCreate?.upload?.data
  if (isBulkUpload && mapping && mapping.includeHeader) {
    header = bulkUploadData[0].map(s => _.trim(s))
    const keyToLabel = _.zipObject(header, mapping.data)
    labelToKey = _.invert(keyToLabel)
  } else {
    let fields
    const ocType = orderCreate?.ocType?.data
    switch (ocType) {
      case ORDER_CREATE_TYPES.REGULAR:
        fields = csvMapping.getOrderFields({
          serviceType: OC_SERVICE_TYPES.PARCEL,
          shipper,
          orderCreate,
          isCodSupported,
          isAllowedToUseAccountBalance,
          isB2BBundleSupported,
          allowedDeliveryTimeslots
        })
        break
      case ORDER_CREATE_TYPES.NINJA_PACK:
        fields = csvMapping.getOrderFields({
          serviceType: OC_SERVICE_TYPES.NINJA_PACKS,
          shipper,
          orderCreate,
          isCodSupported,
          allowedDeliveryTimeslots
        })
        break
      case ORDER_CREATE_TYPES.CORPORATE_AWB:
        fields = csvMapping.getOrderFields({
          serviceType: OC_SERVICE_TYPES.CORPORATE_AWB,
          shipper,
          orderCreate,
          allowedDeliveryTimeslots
        })
        break
      default:
        fields = csvMapping.getAllOrderFields(shipper, orderCreate)
        break
    }
    labelToKey = csvMapping.makeLabelToKeyMap(fields)
    header = Object.keys(labelToKey)
  }

  function getColData (o) {
    return _.map(header, (h, col) => {
      const field = labelToKey[h]
      return field ? o[camelCaseKey(field)] : bulkUploadData?.[o.rowNum]?.[col]
    })
  }
  const rows = orders.map(o => [...getColData(o), o.error])
  return [[...header, 'ERROR DETAILS'], ...rows]
}

export function flattenErrors (data) {
  return _.map(_.tail(data), value => _.slice(value, 0, value.length - 1).concat(_.last(value).join()))
}

export function getServiceType ({
  isReturnOrder,
  isInternational,
  isNinjaPackOrder,
  isCorporateBranch = false,
  isCorpAwbOrder,
  isCorporateHq,
  isDocumentTypeSupported,
  isParcelOCSupported,
  isMarketplaceSeller,
  isMarketplace,
  recipientType,
  isCorporateB2BBundleSupported
}) {
  if (isNinjaPackOrder) {
    return OC_SERVICE_TYPES.NINJA_PACKS
  }
  if (isMarketplace && isInternational) {
    return OC_SERVICE_TYPES.MARKETPLACE_INTERNATIONAL
  }
  if (isInternational) {
    return OC_SERVICE_TYPES.INTERNATIONAL
  }
  if (isReturnOrder) {
    return isCorporateBranch ? OC_SERVICE_TYPES.CORPORATE_RETURN : OC_SERVICE_TYPES.RETURN
  }
  if (isCorporateHq && isCorpAwbOrder) {
    return OC_SERVICE_TYPES.CORPORATE_AWB
  }
  if (isCorporateBranch) {
    return OC_SERVICE_TYPES.CORPORATE
  }
  if (isDocumentTypeSupported && !isParcelOCSupported) {
    return OC_SERVICE_TYPES.DOCUMENT
  }
  if (isMarketplaceSeller) {
    return OC_SERVICE_TYPES.MARKETPLACE
  }
  if (recipientType === RecipientType.B2B) {
    return isCorporateB2BBundleSupported ? OC_SERVICE_TYPES.CORPORATE_B2B_BUNDLE : OC_SERVICE_TYPES.B2B_BUNDLE
  }
  return OC_SERVICE_TYPES.PARCEL
}

export function calculateOrderServiceType (
  isDocumentTypeSupported,
  isCorporateDocumentTypeSupported,
  order,
  topLevelServiceType,
  defaultVal = OC_SERVICE_TYPES.PARCEL
) {
  const orderServiceType = order.serviceType // manual input

  if (isDocumentTypeSupported && topLevelServiceType === OC_SERVICE_TYPES.PARCEL) {
    return orderServiceType || defaultVal // allow overwrite, top level service type
  }

  if (isCorporateDocumentTypeSupported) {
    return orderServiceType || OC_SERVICE_TYPES.CORPORATE
  }

  return orderServiceType || topLevelServiceType
}
