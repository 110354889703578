import React, { useEffect, useMemo, useState } from 'react'
import { Text } from 'components/Text'
import { Tabs } from 'antd/lib'
import { T } from '@nv/react-commons/src/Components'
import { InfoTooltip } from 'containers/FPLOrderRequestList/Fields'
import {
  B2B_FIELD_KEYS,
  B2B_FIELD_KEYS_FOR_GLOBAL,
  B2C_FIELD_KEYS,
  B2C_FIELD_KEYS_FOR_GLOBAL,
  BUNDLE_FIELD_KEYS,
  MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME
} from './constants'
import { FullScreenModal, StyledModalHeader } from 'containers/FPLOrderCreate/styles'
import { useIntl } from 'hooks/common'
import { convertObjectTo2DArray } from 'containers/FPLOrderCreate/dataUtils'
import { makeFriendlyError2DArray, transformErrorsByBoxLevel } from './utils'
import { StyledTabs } from './styles'
import { useSelector } from 'react-redux'
import { selectUser } from 'containers/Base/selectors'
import { ALLOWED_COUNTRY, SERVICE_TYPE } from 'containers/FPLCommon/constants'
import { ReviewOrderTable } from 'containers/FPLOrderCreate/ReviewOrderTable'
import { isEmpty } from 'lodash'
import { Vspace } from 'components/Vspace'
import { DataRow, ReviewSimpleTable } from './ReviewSimpleTable'

const { TabPane } = Tabs

enum TAB_KEY {
  NONE,
  TAB_ONE,
  TAB_TWO,
  TAB_THREE
}

enum TABLE_TYPE {
  SIMPLE,
  COMPLEX
}

const ReviewOrdersModal = ({ onClose, dataSource, serviceType, country }) => {
  const intl = useIntl()
  const [activeKey, setActiveKey] = useState(TAB_KEY.NONE)
  const [tableShowing, setTableShowing] = useState(TABLE_TYPE.SIMPLE)
  const [isGettingSource, setIsGettingSource] = useState(false)
  const [sourceTable, setSourceOrder] = useState([])
  const locale = useSelector(selectUser())?.locale || 'en'

  const tabsData = useMemo(() => {
    switch (serviceType) {
      case SERVICE_TYPE.MMCCB2C:
        if (!isEmpty(dataSource.errorsByBoxLevel) && !isEmpty(dataSource.errorsByParcelLevel)) {
          return [
            {
              label: 'international_invalid_boxes',
              key: TAB_KEY.TAB_ONE,
              quantity: Object.keys(dataSource.errorsByBoxLevel).length
            },
            {
              label: 'international_invalid_items',
              key: TAB_KEY.TAB_TWO,
              quantity: Object.keys(dataSource.errorsByParcelLevel).length
            }
          ]
        }
        return []
      case SERVICE_TYPE.MMCCB2B:
        return [
          {
            label: 'international_valid_boxes',
            key: TAB_KEY.TAB_ONE,
            quantity: Object.keys(dataSource.validItems).length
          },
          {
            label: 'international_invalid_boxes',
            key: TAB_KEY.TAB_TWO,
            quantity: Object.keys(dataSource.invalidItems).length
          },
          {
            label: 'international_total_boxes',
            key: TAB_KEY.TAB_THREE,
            quantity: Object.keys(dataSource.allItems).length
          }
        ]
      case SERVICE_TYPE.B2B_BUNDLE:
        return [
          {
            label: 'valid_bundles',
            key: TAB_KEY.TAB_ONE,
            quantity: Object.keys(dataSource.validItems).length
          },
          {
            label: 'international_invalid_bundles',
            key: TAB_KEY.TAB_TWO,
            quantity: Object.keys(dataSource.invalidItems).length
          },
          {
            label: 'total_bundles',
            key: TAB_KEY.TAB_THREE,
            quantity: Object.keys(dataSource.allItems).length
          }
        ]
    }
  }, [dataSource, serviceType])

  const keys = useMemo(() => {
    const isAllowanceCountry = !!ALLOWED_COUNTRY[country]
    const translator =
      MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME[locale] || MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME['en']

    const translateKeys = keysToTranslate => keysToTranslate.map(key => translator[key])

    if (serviceType === SERVICE_TYPE.MMCCB2C) {
      const B2C_FIELD_KEYS_TO_TRANSLATE = isAllowanceCountry ? B2C_FIELD_KEYS : B2C_FIELD_KEYS_FOR_GLOBAL
      if (tableShowing === TABLE_TYPE.SIMPLE) {
        return translateKeys([B2C_FIELD_KEYS_TO_TRANSLATE[0]])
      }
      return translateKeys(B2C_FIELD_KEYS_TO_TRANSLATE)
    }
    if (serviceType === SERVICE_TYPE.B2B_BUNDLE) {
      return translateKeys(BUNDLE_FIELD_KEYS)
    }

    return isAllowanceCountry ? translateKeys(B2B_FIELD_KEYS) : translateKeys(B2B_FIELD_KEYS_FOR_GLOBAL)
  }, [serviceType, dataSource, tableShowing, locale])

  useEffect(() => {
    const newActiveKey =
      serviceType === SERVICE_TYPE.MMCCB2C && tabsData.length > 0
        ? TAB_KEY.TAB_ONE
        : serviceType === SERVICE_TYPE.MMCCB2B || serviceType === SERVICE_TYPE.B2B_BUNDLE
        ? TAB_KEY.TAB_TWO
        : null
    if (newActiveKey) {
      setActiveKey(newActiveKey)
      setIsGettingSource(true)
    }
  }, [serviceType, tabsData])

  useEffect(() => {
    const isMMCCB2C = serviceType === SERVICE_TYPE.MMCCB2C
    const shouldShowSimpleTable =
      isMMCCB2C &&
      (activeKey === TAB_KEY.TAB_ONE || (activeKey === TAB_KEY.NONE && !isEmpty(dataSource?.errorsByBoxLevel)))
    setTableShowing(shouldShowSimpleTable ? TABLE_TYPE.SIMPLE : TABLE_TYPE.COMPLEX)
    setIsGettingSource(true)
  }, [serviceType, dataSource, activeKey])

  useEffect(() => {
    let sourceTable = []
    if (serviceType === SERVICE_TYPE.MMCCB2B || serviceType === SERVICE_TYPE.B2B_BUNDLE) {
      const source =
        activeKey === TAB_KEY.TAB_ONE
          ? dataSource?.validItems
          : activeKey === TAB_KEY.TAB_TWO
          ? dataSource?.invalidItems
          : dataSource?.allItems
      const sourceArr = convertObjectTo2DArray(source || [])
      sourceTable = makeFriendlyError2DArray(sourceArr, intl)
    } else if (serviceType === SERVICE_TYPE.MMCCB2C) {
      if (activeKey === TAB_KEY.TAB_ONE || (activeKey === TAB_KEY.NONE && tableShowing === TABLE_TYPE.SIMPLE)) {
        sourceTable = transformErrorsByBoxLevel(dataSource)
      } else {
        const source = dataSource?.errorsByParcelLevel
        const sourceArr = convertObjectTo2DArray(source || [])
        sourceTable = makeFriendlyError2DArray(sourceArr, intl)
      }
    }
    setSourceOrder(sourceTable)
    setIsGettingSource(false)
  }, [activeKey, dataSource, serviceType, tableShowing, intl])

  const renderTabHeader = () => {
    return tabsData.map(item => (
      <TabPane
        tab={
          <>
            <T id={item.label} /> <span>{`(${item.quantity})`}</span>
          </>
        }
        key={`${item.key}`}
      />
    ))
  }

  const handleTabClick = key => {
    setActiveKey(+key)
  }

  return (
    <FullScreenModal
      visible
      zIndex={1059}
      width='100vw'
      height='80%'
      wrapClassName='international-overflow-hidden'
      title={
        <StyledModalHeader>
          <div className='flex items-center'>
            <Text
              size={16}
              type='bold'
              id={
                serviceType === SERVICE_TYPE.B2B_BUNDLE
                  ? 'international_bundle_list_detail'
                  : 'international_box_list_detail'
              }
            />
            <InfoTooltip
              tooltipTextId={
                serviceType === SERVICE_TYPE.B2B_BUNDLE
                  ? 'international_bundle_list_items_detail_info'
                  : 'international_box_list_items_detail_info'
              }
            />
          </div>
        </StyledModalHeader>
      }
      onCancel={onClose}
    >
      {tabsData.length > 0 && (
        // @ts-ignore
        <StyledTabs defaultActiveKey={`${activeKey}`} onTabClick={handleTabClick}>
          {renderTabHeader()}
        </StyledTabs>
      )}
      {tableShowing === TABLE_TYPE.SIMPLE && !isGettingSource && (
        <>
          {activeKey === TAB_KEY.NONE && <Vspace height={40} />}
          <ReviewSimpleTable
            dataSource={sourceTable as DataRow[]}
            headerFields={[intl.formatMessage({ id: 'international.no_of_row' }), ...keys]}
          />
        </>
      )}
      {tableShowing === TABLE_TYPE.COMPLEX && !isGettingSource && (
        <ReviewOrderTable
          dataSource={sourceTable as { value: any; errors: any[] }[][]}
          headerFields={[intl.formatMessage({ id: 'international.no_of_row' }), ...keys]}
        />
      )}
    </FullScreenModal>
  )
}

export { ReviewOrdersModal }
