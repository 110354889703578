import mixpanel from 'mixpanel-browser'
import {
  DATE_FORMAT,
  TRACK_ABANDONED_ORDER_CREATION,
  TRACK_CONFIRMED_THE_ORDER,
  TRACK_DELETED_INVALID_ORDER,
  TRACK_DOWLOADED_INVALID_ORDER,
  TRACK_FIXED_ORDER,
  TRACK_PRINTED_AIRWAY_BILL,
  TRACK_REUPLOADED_ORDER,
  TRACK_STARTED_INTL_ORDER_CREATION,
  TRACK_TRACKED_ORDER_ID,
  TRACK_VIEWED_ORDER,
  TRACK_VIEWED_ALL_ORDER,
  TRACK_VISITED_INTERNATIONAL_HOME,
  TRACK_DOWLOADED_ALL_ORDERS,
  TRACK_SEARCH_SINGLE_ORDER,
  TRACK_ADVANCED_SEARCH_ORDER,
  TRACK_FILTERED_INTERNATIONAL_ORDER,
  TRACK_ADDED_INTL_SERVICE_DETAIL,
  TRACK_CLICKED_TO_REPLACE_FILE,
  TRACK_CLICKED_TO_REUPLOAD_FILE,
  TRACK_CLICKED_TO_VIEW_DETAIL_TO_CORRECT_ERROR,
  TRACK_CLICKED_ADD_ANOTHER,
  TRACK_ADDED_INTL_ORDER_DETAIL,
  TRACK_CLICKED_FIX_ORDER,
  TRUTHY_FALSY,
  TRACK_PERFORMANCE_OC,
  TRACK_CLICKED_XB_RETURN_PARCEL,
  TRACK_DISPOSE_XB_RETURN_PARCEL,
  TRACK_RETURNED_TO_ME_XB_RETURN_PARCEL,
  TRACK_RELABELED_XB_RETURN_PARCEL,
  TRACK_CLICKED_SUBMITTED_FOR_FULFILLMENT,
  TRACK_CLICKED_AUTO_DISPOSED,
  RETURN_OPTIONS,
  TRACK_LOADED_ERROR_PAGE,
  TRACK_BULK_UPLOAD_FILE,
  SHOW_ONBOARDING_RATE_CARD,
  DOWNLOAD_RESTRICTION_ONBOARDING,
  SELECT_LANES_ONBOARDING,
  EXIT_INTERNATIONAL_ONBOARDING,
  OPENED_ONBOARDING,
  TRACK_ADDED_BULK_RELABELED_ORDER_DETAIL,
  ADDED_CI_FILE,
  SKIP_CI_FILE,
  ADDED_DO_FILE,
  SKIP_DO_FILE
} from './constants'
import moment from 'moment'
import { GeneralEvents } from 'components/Mixpanel/constants'
import { SEARCH_METHODS_OPTIONS } from 'containers/FPLAllOrders/constants'

export const mpTrackVisitedInternationalHome = data => {
  const { defaultShipperId, locale, partnerId, pageName } = data
  mixpanel.unregister(GeneralEvents.VISITED_PAGE.props.PAGE_NAME)
  mixpanel.track(TRACK_VISITED_INTERNATIONAL_HOME.NAME, {
    [TRACK_VISITED_INTERNATIONAL_HOME.PROPS.PAGE_NAME]: pageName
  })
  mixpanel.register({
    [TRACK_VISITED_INTERNATIONAL_HOME.PROPS.PARTNER_ID]: partnerId,
    [TRACK_VISITED_INTERNATIONAL_HOME.PROPS.SHIPPER_ID]: defaultShipperId
  })
  mixpanel.people.set({ [TRACK_VISITED_INTERNATIONAL_HOME.PROPS.INSTANCE]: locale })
}

export const mpTrackFirstLoginDate = allowFulfillmentService => {
  mixpanel.register({
    [TRACK_VISITED_INTERNATIONAL_HOME.PROPS.XB_FULFILLMENT_STORAGE]: allowFulfillmentService
      ? TRUTHY_FALSY.YES
      : TRUTHY_FALSY.NO
  })
  mixpanel.people.set_once({
    [TRACK_VISITED_INTERNATIONAL_HOME.PEOPLE.FIRST_LOGIN_DATE]: moment().format(DATE_FORMAT)
  })
}

export const mpTrackFirstOnboardedDate = () => {
  mixpanel.people.set_once({
    [TRACK_VISITED_INTERNATIONAL_HOME.PEOPLE.FIRST_ONBOARD_DATE]: moment().format(DATE_FORMAT)
  })
}

export const mpTrackCreatedOrder = ({ pageName, source, orderType }) => {
  mixpanel.people.set_once(TRACK_STARTED_INTL_ORDER_CREATION.PEOPLE.NUMBER_OF_OC_ATTEMPTS, 0)
  mixpanel.people.increment(TRACK_STARTED_INTL_ORDER_CREATION.PEOPLE.NUMBER_OF_OC_ATTEMPTS)
  mixpanel.track(TRACK_STARTED_INTL_ORDER_CREATION.NAME, {
    Source: source,
    [TRACK_STARTED_INTL_ORDER_CREATION.PROPS.PAGE_NAME]: pageName,
    [TRACK_STARTED_INTL_ORDER_CREATION.PROPS.ORDER_TYPE]: orderType
  })
}

export const mpTrackViewedOrder = ({ pageName }) => {
  mixpanel.track(TRACK_VIEWED_ORDER.NAME, {
    [TRACK_VIEWED_ORDER.PROPS.PAGE_NAME]: pageName
  })
}

export const mpTrackViewedAllOrder = ({ pageName }) => {
  mixpanel.track(TRACK_VIEWED_ALL_ORDER.NAME, {
    [TRACK_VIEWED_ALL_ORDER.PROPS.PAGE_NAME]: pageName
  })
}

export const mpTrackFixedOrder = data => {
  mixpanel.track(TRACK_FIXED_ORDER.NAME, { ...data })
}

export const mpTrackDeletedInvalidOrder = () => {
  mixpanel.people.set_once(TRACK_DELETED_INVALID_ORDER.PEOPLE.NUMBER_OF_ORDERS_DELETED, 0)
  mixpanel.people.increment(TRACK_DELETED_INVALID_ORDER.PEOPLE.NUMBER_OF_ORDERS_DELETED)
}

export const mpTrackedOrderIDOrder = ({ pageName }) => {
  mixpanel.people.set_once(TRACK_TRACKED_ORDER_ID.PEOPLE.NUMBER_OF_SEARCHES, 0)
  mixpanel.people.increment(TRACK_TRACKED_ORDER_ID.PEOPLE.NUMBER_OF_SEARCHES)
  mixpanel.track(TRACK_TRACKED_ORDER_ID.NAME, {
    [TRACK_TRACKED_ORDER_ID.PROPS.PAGE_NAME]: pageName
  })
}

export const mpTrackPrintedAirwayBill = ({ status, source, printedOrder, pageName }) => {
  mixpanel.track(TRACK_PRINTED_AIRWAY_BILL.NAME, {
    [TRACK_PRINTED_AIRWAY_BILL.PROPS.PRINTED_AIRWAY_BILL_STATUS]: status,
    [TRACK_PRINTED_AIRWAY_BILL.PROPS.SOURCE]: source,
    [TRACK_PRINTED_AIRWAY_BILL.PROPS.NUMBER_OF_PRINTED_ORDER]: printedOrder,
    [TRACK_PRINTED_AIRWAY_BILL.PROPS.PAGE_NAME]: pageName
  })
}

export const mpTrackConfirmTheOrder = data => {
  const { NAME, PROPS, PEOPLE } = TRACK_CONFIRMED_THE_ORDER
  const {
    inputMethod,
    totalCreated,
    totalNeededActions,
    pageName,
    totalOrders,
    totalCod,
    responseTime,
    orderType
  } = data

  mixpanel.people.set({ [PEOPLE.LAST_OF_CREATED]: moment().format(DATE_FORMAT) })
  mixpanel.people.increment({ [PEOPLE.LT_CREATED_ORDER]: totalCreated })
  mixpanel.track(NAME, {
    [PROPS.NUMBER_OF_VALID_ORDER]: totalCreated,
    [PROPS.NUMBER_OF_INVALID_ORDER]: totalNeededActions,
    [PROPS.TOTAL_ORDERS]: totalOrders,
    [PROPS.ODER_COD_AMOUNT]: totalCod,
    [PROPS.INPUT_METHOD]: inputMethod,
    [PROPS.PAGE_NAME]: pageName,
    [PROPS.FINISH_LOADING_THE_SUCCESS_FAILED_PAGE]: responseTime,
    [PROPS.ORDER_TYPE]: orderType
  })
}

export const mpTrackConfirmRelabelRequest = data => {
  const { NAME, PROPS, PEOPLE } = TRACK_RELABELED_XB_RETURN_PARCEL
  const { numberOfTids, numberOfParcelsSelectedFromWMS, method, uiRenderingTimeInSecond } = data
  mixpanel.people.increment({ [PEOPLE.LT_RELABELED_PARCEL]: numberOfParcelsSelectedFromWMS })
  mixpanel.track(NAME, {
    [PROPS.NUMBER_OF_SELECTED_PARCELS]: numberOfTids,
    [PROPS.METHOD]: method,
    [PROPS.UI_RENDERING_TIME]: uiRenderingTimeInSecond
  })
}

export const mpTrackAddedBulkRelabeledOrderDetail = data => {
  const { NAME, PROPS } = TRACK_ADDED_BULK_RELABELED_ORDER_DETAIL
  const { numberOfValidOrders, numberOfInvalidOrders } = data
  mixpanel.track(NAME, {
    [PROPS.NUMBER_OF_VALID_ORDER]: numberOfValidOrders,
    [PROPS.NUMBER_OF_INVALID_ORDER]: numberOfInvalidOrders
  })
}

export const mpTrackAddedIntlServiceDetail = data => {
  const {
    serviceType,
    pickupOption,
    pageName,
    defaultReturnAddress,
    customsCurrency,
    pickupAddress,
    timeSlot,
    numberOfErrors,
    elementShowError,
    errorMessages,
    orderType
  } = data
  const { NAME, PROPS } = TRACK_ADDED_INTL_SERVICE_DETAIL
  mixpanel.track(NAME, {
    [PROPS.SERVICE_TYPE]: serviceType,
    [PROPS.PICKUP_OPTION]: pickupOption,
    [PROPS.PAGE_NAME]: pageName,
    [PROPS.DEFAULT_RETURN_ADDRESS]: defaultReturnAddress ? TRUTHY_FALSY.YES : TRUTHY_FALSY.NO,
    [PROPS.CUSTOMS_CURRENCY]: customsCurrency,
    [PROPS.PICKUP_ADDRESS]: pickupAddress ? TRUTHY_FALSY.YES : TRUTHY_FALSY.NO,
    [PROPS.TIME_SLOT]: timeSlot ? TRUTHY_FALSY.YES : TRUTHY_FALSY.NO,
    [PROPS.ORDER_TYPE]: orderType,
    ...(numberOfErrors && {
      [PROPS.NUMBER_OF_ERRORS]: numberOfErrors
    }),
    ...(elementShowError && {
      [PROPS.ELEMENT_SHOW_ERROR]: elementShowError
    }),
    ...(errorMessages && {
      [PROPS.ERROR_MESSAGE]: errorMessages
    })
  })
}

export const mpTrackAbandonedOrderCreation = ({ numberOfOrders, pageName, orderType }) => {
  mixpanel.people.set_once({
    [TRACK_ABANDONED_ORDER_CREATION.PEOPLE.LIFETIME_ABANDONED_ORDER]: 0,
    [TRACK_ABANDONED_ORDER_CREATION.PEOPLE.LAST_ABANDONED_ORDER_DATE]: moment().format(DATE_FORMAT)
  })
  mixpanel.people.set({
    [TRACK_ABANDONED_ORDER_CREATION.PEOPLE.LAST_ABANDONED_ORDER_DATE]: moment().format(DATE_FORMAT)
  })
  mixpanel.people.increment({
    [TRACK_ABANDONED_ORDER_CREATION.PEOPLE.LIFETIME_ABANDONED_ORDER]: numberOfOrders
  })
  mixpanel.track(TRACK_ABANDONED_ORDER_CREATION.NAME, {
    [TRACK_ABANDONED_ORDER_CREATION.PROPS.NUMBER_OF_ORDER_ABANDONED]: numberOfOrders,
    [TRACK_ABANDONED_ORDER_CREATION.PROPS.PAGE_NAME]: pageName,
    [TRACK_ABANDONED_ORDER_CREATION.PROPS.ORDER_TYPE]: orderType
  })
}

export const mpTrackDownloadedAllOrders = ({ numberDownloadedOrders }) => {
  mixpanel.unregister(GeneralEvents.VISITED_PAGE.props.PAGE_NAME)
  mixpanel.track(TRACK_DOWLOADED_ALL_ORDERS.NAME, {
    [TRACK_DOWLOADED_ALL_ORDERS.PROPS.NUMBER_DOWNLOADED_ORDERS]: numberDownloadedOrders
  })
}

export const mpTrackedSearchSingleOrder = () => {
  mixpanel.people.set_once(TRACK_SEARCH_SINGLE_ORDER.PEOPLE.NUMBER_OF_SEARCHES, 0)
  mixpanel.people.increment(TRACK_SEARCH_SINGLE_ORDER.PEOPLE.NUMBER_OF_SEARCHES)
}

export const mpTrackFilteredInternationalOrder = ({ friendlyStatuses, dateRange }) => {
  mixpanel.track(TRACK_FILTERED_INTERNATIONAL_ORDER.NAME, {
    [TRACK_FILTERED_INTERNATIONAL_ORDER.PROPS.STATUS_GROUP]: friendlyStatuses,
    [TRACK_FILTERED_INTERNATIONAL_ORDER.PROPS.DATE_RANGE]: dateRange
  })
}

export const mpTrackAdvancedSearchOrder = ({ searchMethod, numberOfSearches }) => {
  mixpanel.track(TRACK_ADVANCED_SEARCH_ORDER.NAME, {
    [TRACK_ADVANCED_SEARCH_ORDER.PROPS.SEARCH_METHOD]: SEARCH_METHODS_OPTIONS[searchMethod],
    [TRACK_ADVANCED_SEARCH_ORDER.PROPS.NUMBER_OF_SEARCHES]: numberOfSearches
  })
}

export const mpTrackClickedToReplaceFile = () => {
  mixpanel.unregister(GeneralEvents.VISITED_PAGE.props.PAGE_NAME)
  mixpanel.track(TRACK_CLICKED_TO_REPLACE_FILE.NAME)
}

export const mpTrackClickedToReUploadFile = () => {
  mixpanel.unregister(GeneralEvents.VISITED_PAGE.props.PAGE_NAME)
  mixpanel.track(TRACK_CLICKED_TO_REUPLOAD_FILE.NAME)
}

export const mpTrackClickedToViewDetailToCorrectError = () => {
  mixpanel.unregister(GeneralEvents.VISITED_PAGE.props.PAGE_NAME)
  mixpanel.track(TRACK_CLICKED_TO_VIEW_DETAIL_TO_CORRECT_ERROR.NAME)
}

export const mpTrackClickedAddAnother = () => {
  mixpanel.unregister(GeneralEvents.VISITED_PAGE.props.PAGE_NAME)
  mixpanel.track(TRACK_CLICKED_ADD_ANOTHER.NAME)
}

export const mpTrackAddedIntlOrderDetail = data => {
  mixpanel.unregister(GeneralEvents.VISITED_PAGE.props.PAGE_NAME)
  mixpanel.track(TRACK_ADDED_INTL_ORDER_DETAIL.NAME, { ...data })
}

export const mpTrackClickedFixOrder = ({ pageName, ltInvalidOrderCreate }) => {
  mixpanel.people.set_once(TRACK_CLICKED_FIX_ORDER.PEOPLE.LT_INVALID_ORDER_CREATED, 0)
  mixpanel.people.increment({
    [TRACK_CLICKED_FIX_ORDER.PEOPLE.LT_INVALID_ORDER_CREATED]: ltInvalidOrderCreate
  })
  mixpanel.track(TRACK_CLICKED_FIX_ORDER.NAME, { [TRACK_CLICKED_FIX_ORDER.PROPS.PAGE_NAME]: pageName })
}

export const mpTrackPerformanceOC = ({ responseTime, uiRenderingTime, orderType }) => {
  mixpanel.track(TRACK_PERFORMANCE_OC.NAME, {
    [TRACK_PERFORMANCE_OC.PROPS.API_LOADING_TIME]: responseTime,
    [TRACK_PERFORMANCE_OC.PROPS.UI_RENDERING_TIME]: uiRenderingTime,
    [TRACK_PERFORMANCE_OC.PROPS.ORDER_TYPE]: orderType
  })
}

export const mpTrackClickedReturnParcel = ({ instance }) => {
  const { PROPS, NAME } = TRACK_CLICKED_XB_RETURN_PARCEL
  mixpanel.track(NAME, {
    [PROPS.INSTANCE]: instance
  })
}

export const mpTrackDisposeReturnParcel = ({ numberOfParcels }) => {
  const { PROPS, NAME, PEOPLE } = TRACK_DISPOSE_XB_RETURN_PARCEL
  mixpanel.track(NAME, {
    [PROPS.NUMBER_OF_SELECTED_PARCELS]: numberOfParcels
  })
  mixpanel.people.set_once(PEOPLE.LT_DISPOSE_PARCEL, 0)
  mixpanel.people.increment({
    [PEOPLE.LT_DISPOSE_PARCEL]: numberOfParcels
  })
}

export const mpTrackReturnedToMeReturnParcel = ({ numberOfParcels, returnOption }) => {
  const { PROPS, NAME, PEOPLE } = TRACK_RETURNED_TO_ME_XB_RETURN_PARCEL
  mixpanel.track(NAME, {
    [PROPS.NUMBER_OF_SELECTED_PARCELS]: numberOfParcels,
    [PROPS.RETURN_OPTIONS]: RETURN_OPTIONS[returnOption]
  })
  mixpanel.people.set_once(PEOPLE.LT_RETURN_TO_ME_PARCEL, 0)
  mixpanel.people.increment({
    [PEOPLE.LT_RETURN_TO_ME_PARCEL]: numberOfParcels
  })
}

export const mpTrackRelabeledReturnParcel = ({
  numberOfParcels,
  method,
  results,
  numberOfValidOrders,
  numberOfInvalidOrders,
  uiRenderingTime
}) => {
  const { PROPS, NAME, PEOPLE } = TRACK_RELABELED_XB_RETURN_PARCEL

  mixpanel.track(NAME, {
    [PROPS.NUMBER_OF_SELECTED_PARCELS]: numberOfParcels,
    [PROPS.METHOD]: method,
    [PROPS.RELABEL_RESULTS]: results,
    [PROPS.NUMBER_OF_VALID_ORDER]: numberOfValidOrders,
    [PROPS.NUMBER_OF_INVALID_ORDER]: numberOfInvalidOrders,
    [PROPS.UI_RENDERING_TIME]: uiRenderingTime
  })
  mixpanel.people.set_once(PEOPLE.LT_RELABELED_PARCEL, 0)
  mixpanel.people.increment({
    [PEOPLE.LT_RELABELED_PARCEL]: numberOfParcels
  })
}

export const mpTrackClickedSubmittedForFulfillment = () => {
  const { NAME } = TRACK_CLICKED_SUBMITTED_FOR_FULFILLMENT
  mixpanel.track(NAME)
}

export const mpTrackClickedAutoDisposed = () => {
  const { NAME } = TRACK_CLICKED_AUTO_DISPOSED
  mixpanel.track(NAME)
}

export const mpTrackLoadedErrorPage = ({ previousPage, errorApi, errorStatus, errorMessage }) => {
  const { NAME, PROPS } = TRACK_LOADED_ERROR_PAGE
  mixpanel.track(NAME, {
    [PROPS.PREVIOUS_PAGE]: previousPage,
    [PROPS.API_CAUSES_ERROR]: errorApi,
    [PROPS.ERROR_STATUS]: errorStatus,
    [PROPS.ERROR_MESSAGE]: errorMessage
  })
}

export const mpTrackUploadFile = data => {
  const { result, numberOfValidOrders, numberOfInvalidOrders, totalOrders, errorMessage, orderType } = data
  const { NAME, PROPS } = TRACK_BULK_UPLOAD_FILE
  mixpanel.track(NAME, {
    [PROPS.UPLOAD_RESULT]: result,
    [PROPS.NUMBER_OF_VALID_ORDER]: numberOfValidOrders,
    [PROPS.NUMBER_OF_INVALID_ORDER]: numberOfInvalidOrders,
    [PROPS.TOTAL_ORDERS]: totalOrders,
    [PROPS.ORDER_TYPE]: orderType,
    ...(errorMessage && {
      [PROPS.ERROR_MESSAGE]: errorMessage
    })
  })
}

export const mpTrackDownloadRateCard = () => {
  mixpanel.track(SHOW_ONBOARDING_RATE_CARD.NAME)
}

export const mpTrackDownloadRestrictionList = () => {
  mixpanel.track(DOWNLOAD_RESTRICTION_ONBOARDING.NAME)
}

export const mpTrackSelectedLanesOnboarding = ({ supportedLanes, unsupportedLanes, weightPerParcel, parcelPerDay }) => {
  const { NAME, PROPS } = SELECT_LANES_ONBOARDING
  mixpanel.track(NAME, {
    [PROPS.LANE]: supportedLanes,
    [PROPS.UNSUPPORTED_LANE]: unsupportedLanes,
    [PROPS.WEIGHT_PER_PARCEL]: weightPerParcel,
    [PROPS.PARCEL_PER_DAY]: parcelPerDay
  })
}

export const mpTrackExitOnboarding = () => {
  mixpanel.track(EXIT_INTERNATIONAL_ONBOARDING.NAME)
}

export const mpTrackOpenedOnboarding = () => {
  mixpanel.track(OPENED_ONBOARDING.NAME)
}

export const mpTrackAddedCIFile = params => {
  const { NAME, PROPS } = ADDED_CI_FILE
  const { fileType, fileSize, numberOfFiles, orderType } = params
  mixpanel.track(NAME, {
    [PROPS.FILE_TYPE]: fileType,
    [PROPS.FILE_SIZE]: fileSize,
    [PROPS.NUMBER_OF_FILES]: numberOfFiles,
    [PROPS.ORDER_TYPE]: orderType
  })
}

export const mpTrackSkipCIFile = params => {
  const { NAME, PROPS } = SKIP_CI_FILE
  mixpanel.track(NAME, {
    [PROPS.ORDER_TYPE]: params.orderType
  })
}

export const mpTrackAddedDOFile = params => {
  const { NAME, PROPS } = ADDED_DO_FILE
  const { fileType, fileSize, numberOfFiles, orderType } = params
  mixpanel.track(NAME, {
    [PROPS.FILE_TYPE]: fileType,
    [PROPS.FILE_SIZE]: fileSize,
    [PROPS.NUMBER_OF_FILES]: numberOfFiles,
    [PROPS.ORDER_TYPE]: orderType
  })
}

export const mpTrackSkipDOFile = params => {
  const { NAME, PROPS } = SKIP_DO_FILE
  mixpanel.track(NAME, {
    [PROPS.ORDER_TYPE]: params.orderType
  })
}
