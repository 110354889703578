import React from 'react'
import { T } from '@nv/react-commons/src/Components'
import { TableCell, TableRow, HeaderCell, HeaderRow, TableWrapper } from './styles'
import { StyledErrorText } from 'containers/FPLOrderCreate/styles'

interface CellData {
  value: string
  errors?: string[]
}

interface DataRow {
  [key: string]: CellData
}

interface ReviewSimpleTableProps {
  dataSource: DataRow[]
  headerFields: string[]
}

const ReviewSimpleTable: React.FC<ReviewSimpleTableProps> = ({ dataSource, headerFields }) => {
  return (
    <TableWrapper>
      <HeaderRow>
        {headerFields.map((column, index) => (
          <HeaderCell key={index} isFirstColumn={index === 0}>
            <strong>{column}</strong>
          </HeaderCell>
        ))}
      </HeaderRow>

      {dataSource.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          {Object.values(row).map((cellData, colIndex) => (
            <TableCell key={colIndex} isFirstColumn={colIndex === 0}>
              <div>{cellData.value}</div>
              {cellData.errors?.length > 0 && (
                <div>
                  {cellData.errors.map(err => (
                    <StyledErrorText key={err}>
                      <T id={err} />
                    </StyledErrorText>
                  ))}
                </div>
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableWrapper>
  )
}

export { ReviewSimpleTable, CellData, DataRow, ReviewSimpleTableProps }
