import { isEmpty, toLower, trim, uniq, upperCase } from 'lodash'

import { EMAIL_REGEX, PHONE_REGEX } from '../FPLOrderCreate/dataUtils'
import { VALIDATORS } from './validationRules'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import {
  ALLOWED_COUNTRY,
  GLOBAL_COUNTRY_CODE,
  MAX_TID_LENGTH,
  MIN_TID_LENGTH,
  SPECIAL_COUNTRY_CODES
} from 'containers/FPLCommon/constants'

export const NUMBER_OF_USELESS_LINE = 2
export const MAX_1000_ORDERS_PER_REQUEST = 1000
export const MAX_10000_ORDERS_PER_REQUEST = 10000
export const POSTCODE_COUNTRY_DIGITS_MAPPING = {
  [COUNTRIES.SG]: 6,
  [COUNTRIES.VN]: 6,
  [COUNTRIES.MY]: 5,
  [COUNTRIES.ID]: 5,
  [COUNTRIES.TH]: 5,
  [COUNTRIES.PH]: 4
}
export const NUMBER_1 = 1
export const NUMBER_255 = 255
export const NUMBER_999 = 999
export const NUMBER_1000 = 1000

export const requestedCartonsTrackingIDsValidator = (cartonTrackingNumbers, cartons, bundles, duplicatedInSystem) => {
  if (isEmpty(cartonTrackingNumbers)) {
    return null
  }
  const noOfCartons = parseInt(cartons, 10)
  const cartonTids = cartonTrackingNumbers.split(',').map(item => trim(item.toUpperCase()))

  if (Number.isNaN(noOfCartons) || cartonTids.length !== noOfCartons) {
    return { id: 'invalid_carton_tids_not_match_no_of_cartons' }
  }

  if (cartonTids.some(item => !item)) {
    return { id: 'invalid_carton_tids_format' }
  }

  if (uniq(cartonTids).length !== cartonTids.length) {
    return { id: 'invalid_carton_tids_duplicated' }
  }

  const allCartonTids = bundles.flatMap(bundle =>
    bundle.requestedCartonTrackingNumbers
      ? bundle.requestedCartonTrackingNumbers.split(',').map(cartonTid => {
          return trim(cartonTid?.toUpperCase())
        })
      : []
  )
  const differentCartonTids = cartonTids.filter(currentCartonTid => allCartonTids.includes(currentCartonTid))
  if (differentCartonTids.length) {
    return {
      id: 'duplicated_carton_tid_across_other_bundles',
      values: {
        x: `[${differentCartonTids.join(', ')}]`
      }
    }
  }

  if (duplicatedInSystem) {
    return {
      id: duplicatedInSystem
    }
  }

  return null
}

export const requestedTrackingIdB2BBundleValidator = (requestedTID, bundles, duplicatedInSystem) => {
  if (requestedTID && requestedTID.length < MIN_TID_LENGTH) {
    return {
      id: 'flexible_min_max_requested_tid_message',
      values: {
        min: MIN_TID_LENGTH,
        max: MAX_TID_LENGTH
      }
    }
  }
  if (requestedTID && requestedTID.length > MAX_TID_LENGTH) {
    return {
      id: 'flexible_min_max_requested_tid_message',
      values: {
        min: MIN_TID_LENGTH,
        max: MAX_TID_LENGTH
      }
    }
  }
  const allOtherRequestedTIDs = bundles.map(bundle => bundle.requestedTrackingID)
  const existedRequestedTID = allOtherRequestedTIDs.find(previousTid => previousTid === requestedTID)
  if (existedRequestedTID) {
    return {
      id: 'requested_tracking_id_in_use',
      values: {
        x: `[${requestedTID}]`
      }
    }
  }
  if (duplicatedInSystem) {
    return {
      id: duplicatedInSystem
    }
  }
  return null
}

export const isRequiredCity = country => {
  if (country === COUNTRIES.TH || country === COUNTRIES.SG) {
    return false
  }
  return true
}

export const isRequiredPostCode = country => {
  if (
    country === COUNTRIES.VN ||
    country === COUNTRIES.ID ||
    country === COUNTRIES.PH ||
    country?.toUpperCase() === SPECIAL_COUNTRY_CODES.HK
  ) {
    return false
  }
  return true
}

export const postcodeDigitsByCountry = country => POSTCODE_COUNTRY_DIGITS_MAPPING[toLower(country)]

export const validateName = name => name && name.length >= NUMBER_1 && name.length <= NUMBER_255
export const validateContact = contact => PHONE_REGEX.test(contact)
export const validateAddress1 = address1 => address1 && address1.length >= NUMBER_1 && address1.length <= NUMBER_255
export const validateCity = (city, country) => {
  if (isRequiredCity(country)) {
    return city && city.length >= NUMBER_1 && city.length <= NUMBER_255
  }
  return !city || (city.length >= NUMBER_1 && city.length <= NUMBER_255)
}
export const validatePostcode = (postcode, country) => {
  const countryCodeInUpperCase = country?.toUpperCase()
  if (countryCodeInUpperCase === SPECIAL_COUNTRY_CODES.HK) {
    return true
  }
  if (!ALLOWED_COUNTRY[countryCodeInUpperCase]) {
    return !isRequiredPostCode(country) || Boolean(postcode?.trim())
  }
  const isRequired = isRequiredPostCode(country)
  const isValidFormat = postcode && VALIDATORS.POSTCODE_VALIDATOR[countryCodeInUpperCase].test(postcode)
  if (isRequired) {
    return isValidFormat
  }
  return !postcode || isValidFormat
}

export const validateAddress2 = address => !address || (address.length >= 1 && address.length <= NUMBER_255)

export const validateEmail = email => !email || EMAIL_REGEX.test(email)

export const validateNumberOfCartons = numberOfCartons =>
  numberOfCartons && +numberOfCartons >= NUMBER_1 && +numberOfCartons <= NUMBER_999

export const validateTotalCartonValues = totalCartonValue => totalCartonValue && +totalCartonValue > 0

export const validateShipperOrderNumber = shipperOrderNumber =>
  !shipperOrderNumber || shipperOrderNumber.length <= NUMBER_255

export const validateOptionalAndFollowingPatternNumber = (value, pattern) => !value || pattern.test(value)

export const validateRequiredAndFollowingPatternNumber = (value, pattern) => value && pattern.test(value)

export const validateRequiredAndLengthOfValue = (value, max) => value && value.length <= max

export const validateOptionalAndLengthOfValue = (value, max) => !value || value.length <= max
