import { ERROR_CODE_MAPPING, RULE_TYPES } from 'containers/FPLOrderCreate/constants'
import { FLOAT_NUMBER_REGEX, NUMBER_REGEX, PHONE_REGEX } from 'containers/FPLOrderCreate/dataUtils'
import {
  FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
  FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
  MMCC_KEYS
} from './constants'
import { ALLOWED_COUNTRY, COUNTRIES_ALLOWANCE_REGEX, MAX_TID_LENGTH } from 'containers/FPLCommon/constants'

const LENGTH_0 = 0
const LENGTH_1 = 1
const LENGTH_255 = 255
const LENGTH_1000 = 1000

export const VALIDATORS = {
  LENGTH_0: 0,
  LENGTH_255: 255,
  LENGTH_1000: 1000,
  PHONE_REGEX: /^[+]*\d{6,32}$/, // Simplified phone regex
  NUMBER_REGEX: /^\d+$/,
  FLOAT_NUMBER_REGEX: /^(?!0\d)\d*(\.\d+)?$/, // Float validation regex
  YES_NO_REGREX: /^(yes|no)$/i,
  POSTCODE_VALIDATOR: {
    SG: /^\d{6}$/, // Singapore: 6 digits
    MY: /^\d{5}$/, // Malaysia: 5 digits
    TH: /^\d{5}$/, // Thailand: 5 digits
    PH: /^\d{4}$/, // Philippines: 4 digits
    ID: /^\d{5}$/, // Indonesia: 5 digits
    VN: /^\d{6}$/ // Vietnam: 6 digits
  }
}

const MMCC_VALIDATION_RULES = {
  [MMCC_KEYS.BOX_ID]: {
    required: true,
    rules: [{ type: RULE_TYPES.MAX_LENGTH, validator: VALIDATORS.LENGTH_255 }]
  },
  [MMCC_KEYS.SHIPPER_ORDER_NUMBER]: {
    required: false,
    rules: [{ type: RULE_TYPES.MAX_LENGTH, validator: VALIDATORS.LENGTH_255 }]
  },
  [MMCC_KEYS.RECIPIENT_NAME]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: VALIDATORS.LENGTH_0 },
      { type: RULE_TYPES.MAX_LENGTH, validator: VALIDATORS.LENGTH_255 }
    ]
  },
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: {
    required: true,
    rules: [{ type: RULE_TYPES.REGEX, validator: VALIDATORS.PHONE_REGEX }]
  },
  [MMCC_KEYS.RECIPIENT_ADDRESS]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: VALIDATORS.LENGTH_0 },
      { type: RULE_TYPES.MAX_LENGTH, validator: VALIDATORS.LENGTH_255 }
    ]
  },
  [MMCC_KEYS.RECIPIENT_CITY]: {
    requiredByCountry: ['MY', 'PH', 'ID', 'VN'],
    rules: [{ type: RULE_TYPES.MAX_LENGTH, validator: VALIDATORS.LENGTH_255 }]
  },
  [MMCC_KEYS.RECIPIENT_POSTCODE]: {
    requiredByCountry: ['SG', 'MY', 'TH'],
    rules: [
      {
        type: RULE_TYPES.REGEX,
        validator: VALIDATORS.POSTCODE_VALIDATOR
      }
    ]
  },
  [MMCC_KEYS.BOX_WEIGHT]: {
    required: false,
    rules: [
      { type: RULE_TYPES.MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.MAX, validator: LENGTH_1000 },
      { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.BOX_LENGTH]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000 }]
  },
  [MMCC_KEYS.BOX_HEIGHT]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000 }]
  },
  [MMCC_KEYS.BOX_WIDTH]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000 }]
  },
  [MMCC_KEYS.GOODS_DESCRIPTION]: {
    required: true,
    rules: [{ type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_255 }]
  },
  [MMCC_KEYS.NO_OF_PARCELS]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.PARCEL_TRACKING_ID]: {
    required: true,
    rules: [{ type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_255 }]
  },
  [MMCC_KEYS.TO_NAME]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: LENGTH_0 },
      { type: RULE_TYPES.MAX_LENGTH, validator: LENGTH_255 }
    ]
  },
  [MMCC_KEYS.TO_ADDRESS]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: LENGTH_0 },
      { type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_255 }
    ]
  },
  [MMCC_KEYS.TO_CONTACT_NUMBER]: {
    required: true,
    rules: [{ type: RULE_TYPES.REGEX, validator: PHONE_REGEX }]
  },
  [MMCC_KEYS.ITEM_QUANTITY]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.ITEM_DESCRIPTION]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: LENGTH_0 },
      { type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_1000 }
    ]
  },
  [MMCC_KEYS.ITEM_VALUE]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.QUANTITY]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.GOODS_VALUE]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.RECIPIENT_STATE_PROVINCE]: {
    requiredByCountry: ['MY', 'TH', 'ID', 'PH'],
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: LENGTH_0 },
      { type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_255 }
    ]
  },
  [MMCC_KEYS.CARTON_WEIGHT]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000 }]
  },
  [MMCC_KEYS.CARTON_LENGTH]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000 }]
  },
  [MMCC_KEYS.CARTON_HEIGHT]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000 }]
  },
  [MMCC_KEYS.CARTON_WIDTH]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000 }]
  },
  [MMCC_KEYS.CARTON_DESCRIPTION]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN_LENGTH, validator: LENGTH_0 },
      { type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_1000 }
    ]
  },
  [MMCC_KEYS.NUMBER_OF_CARTONS]: {
    required: true,
    rules: [
      { type: RULE_TYPES.MIN, validator: LENGTH_1 },
      { type: RULE_TYPES.MAX, validator: LENGTH_1000 },
      { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.TOTAL_CARTON_VALUE]: {
    required: true,
    rules: [
      { type: RULE_TYPES.EQUAL_MIN, validator: LENGTH_0 },
      { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
    ]
  },
  [MMCC_KEYS.HSCODE]: {
    required: false,
    rules: [{ type: RULE_TYPES.EQUAL_MAX_LENGTH, validator: LENGTH_1000 }]
  },
  [MMCC_KEYS.RDO]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: VALIDATORS.YES_NO_REGREX }]
  },
  [MMCC_KEYS.GRN]: {
    required: false,
    rules: [{ type: RULE_TYPES.REGEX, validator: VALIDATORS.YES_NO_REGREX }]
  },
  [MMCC_KEYS.REQUESTED_TRACKING_ID]: {
    required: false,
    rules: [
      { type: RULE_TYPES.MAX_LENGTH, validator: MAX_TID_LENGTH },
      { type: RULE_TYPES.DUPLICATE, validator: 1 }
    ]
  },
  [MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS]: {
    required: false,
    rules: [{ type: RULE_TYPES.EQUAL_LENGTH }, { type: RULE_TYPES.DUPLICATE, validator: 1 }]
  },
  [MMCC_KEYS.TO_COUNTRY]: {
    required: true,
    rules: [{ type: RULE_TYPES.REGEX, validator: COUNTRIES_ALLOWANCE_REGEX }]
  }
}

export const getMMCCValidationRules = (key: string, country: string) => {
  const baseRules = MMCC_VALIDATION_RULES[key]
  if (!baseRules) return null

  switch (key) {
    case MMCC_KEYS.RECIPIENT_POSTCODE:
      let isRequiredPostcode = true
      if (['PH', 'ID', 'VN', 'HK'].includes(country)) {
        isRequiredPostcode = false
      }
      const rules = []
      if (ALLOWED_COUNTRY[country]) {
        rules.push({
          type: RULE_TYPES.REGEX,
          validator: VALIDATORS.POSTCODE_VALIDATOR[country] || VALIDATORS.POSTCODE_VALIDATOR.SG
        })
      }
      return {
        ...baseRules,
        required: isRequiredPostcode,
        rules: rules
      }

    case MMCC_KEYS.RECIPIENT_CITY:
      let isRequiredCity = true
      if (country === 'TH' || country === 'SG') {
        isRequiredCity = false
      }
      return {
        required: isRequiredCity,
        rules: baseRules.rules
      }
    case MMCC_KEYS.RECIPIENT_STATE_PROVINCE:
      return {
        ...baseRules,
        required: baseRules.requiredByCountry?.includes(country) ?? false
      }

    case MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS:
      return {
        ...baseRules,
        rules: [{ type: RULE_TYPES.EQUAL_LENGTH }, { type: RULE_TYPES.DUPLICATE, validator: LENGTH_1 }]
      }
    default:
      return baseRules
  }
}

export const ERRORS_TRANSLATION_KEYS = {
  [MMCC_KEYS.BOX_ID]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_shipper_box_id_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_invalid_shipper_box_id' }
  },
  [MMCC_KEYS.SHIPPER_ORDER_NUMBER]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_invalid_shipper_order_number' }
  },
  [MMCC_KEYS.BOX_WEIGHT]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_bag_weight_is_invalid' }
  },
  [MMCC_KEYS.BOX_LENGTH]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_invalid_bag_length' }
  },
  [MMCC_KEYS.BOX_HEIGHT]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_invalid_bag_height' }
  },
  [MMCC_KEYS.BOX_WIDTH]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_invalid_bag_width' }
  },
  [MMCC_KEYS.GOODS_DESCRIPTION]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_goods_description_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_goods_description_is_invalid' }
  },
  [MMCC_KEYS.NO_OF_PARCELS]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_no_of_parcels_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_no_of_parcels_is_invalid' }
  },
  [MMCC_KEYS.PARCEL_TRACKING_ID]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_parcel_tracking_id_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_parcel_tracking_id_invalid_character_limitation' }
  },
  [MMCC_KEYS.TO_NAME]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_to_name_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_to_name_is_invalid' }
  },
  [MMCC_KEYS.TO_ADDRESS]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_to_address_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_to_address_is_invalid' }
  },
  [MMCC_KEYS.TO_CONTACT_NUMBER]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_to_contact_number_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_to_contact_number_is_invalid' }
  },
  [MMCC_KEYS.ITEM_QUANTITY]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_item_quantity_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_item_quantity_is_invalid' }
  },
  [MMCC_KEYS.ITEM_DESCRIPTION]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_item_description_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_item_description_is_invalid' }
  },
  [MMCC_KEYS.ITEM_VALUE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_item_value_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_item_value_is_invalid' }
  },
  [MMCC_KEYS.QUANTITY]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_quantity_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_possitive_quantity' }
  },
  [MMCC_KEYS.GOODS_VALUE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_goods_value_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_goods_value_is_invalid' }
  },
  [MMCC_KEYS.RECIPIENT_NAME]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_recipient_name_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_name_less_than_255_characters' }
  },
  [MMCC_KEYS.RECIPIENT_PHONE_NUMBER]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_contact_number_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_conact_number_must_be_valid_format' }
  },
  [MMCC_KEYS.RECIPIENT_ADDRESS]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_recipient_address_is_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_address_less_than_255_characters' }
  },
  [MMCC_KEYS.RECIPIENT_CITY]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'international_city_is_required_all_except_sg_and_th' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_city_less_than_255_characters' }
  },
  [MMCC_KEYS.RECIPIENT_POSTCODE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'postcode_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'the_postcode_must_be_the_valid_postcode_format' }
  },
  [MMCC_KEYS.RECIPIENT_STATE_PROVINCE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'types.zone.state.required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_state_less_than_255_characters' }
  },
  [MMCC_KEYS.CARTON_WEIGHT]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_bag_weight_is_invalid' }
  },
  [MMCC_KEYS.CARTON_LENGTH]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'number_must_be_number_between_1_and_1000' }
  },
  [MMCC_KEYS.CARTON_HEIGHT]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'number_must_be_number_between_1_and_1000' }
  },
  [MMCC_KEYS.CARTON_WIDTH]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'number_must_be_number_between_1_and_1000' }
  },
  [MMCC_KEYS.CARTON_DESCRIPTION]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'carton_description_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'the_description_must_be_maximum_1000_characters' }
  },
  [MMCC_KEYS.NUMBER_OF_CARTONS]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'number_of_carton_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'number_of_cartons_invalid_message' }
  },
  [MMCC_KEYS.TOTAL_CARTON_VALUE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'total_carton_value_required' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'the_value_must_be_number_greate_than_0' }
  },
  [MMCC_KEYS.HSCODE]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'hs_code_must_be_maximum_characters' }
  },
  [MMCC_KEYS.RDO]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'accept_yes_no_value' }
  },
  [MMCC_KEYS.GRN]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'accept_yes_no_value' }
  },
  [MMCC_KEYS.REQUESTED_TRACKING_ID]: {
    [ERROR_CODE_MAPPING.INVALID]: { key: 'flexible_min_max_requested_tid_message' },
    [ERROR_CODE_MAPPING.DUPLICATE]: { key: 'requested_tracking_id_in_use' }
  },
  [MMCC_KEYS.REQUESTED_PIECE_TRACKING_NUMBERS]: {
    [ERROR_CODE_MAPPING.DUPLICATE]: { key: 'duplicated_carton_tid_across_other_bundles' }, // SPECIFIC duplicated value
    [ERROR_CODE_MAPPING.MATCHING]: { key: 'invalid_carton_tids_not_match_no_of_cartons' } // SPECIFIC duplicated value
  },
  [MMCC_KEYS.TO_COUNTRY]: {
    [ERROR_CODE_MAPPING.REQUIRED]: { key: 'required_recipient_country' },
    [ERROR_CODE_MAPPING.INVALID]: { key: 'international_invalid_country_code' }
  }
}

export const COLUMN_TRUNCATION_MAP = {
  [MMCC_KEYS.TO_ADDRESS]: LENGTH_255,
  [MMCC_KEYS.GOODS_DESCRIPTION]: LENGTH_255,
  [MMCC_KEYS.ITEM_DESCRIPTION]: LENGTH_1000
}
