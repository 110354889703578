import React, { useEffect, useState } from 'react'
import { OCHeader } from 'components/OCHeader'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { BCWrapper, VerticalStepperContainer } from './styles'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { ServiceDetailsStep } from './ServiceDetailsStep'
import { OrdersStep } from './OrdersStep'
import { StepStatus, VerticalStepper } from '@nv/react-akira'
import {
  DEFAULT_B2B_BUNDLE_FORM_FIELD,
  DEFAULT_MMCC_B2B_FORM_FIELD,
  FILE_UPLOAD_STATUS,
  INTERNAL_STEP_STATUS,
  KEYBOARD_INPUT_STATUS,
  MMCC_OC_STEP,
  SUPPORTED_DOCUMENT_STATUS,
  TRANSLATION_KEY
} from './constants'
import { useIntl } from 'hooks/common'
import { useMarketingContent } from 'containers/FPLMarketingPage/useMarketingContent'
import { MMCCOrderCreationState, Service } from './types'
import { Spin } from '@nv/react-commons/src/Components'
import { buildFullErrorInfo } from 'containers/FPLErrorPage/dataUtils'
import { NOT_FOUND_CODE } from 'containers/FPLErrorPage/constants'
import FPLErrorPage from 'containers/FPLErrorPage'
import FPLMarketingPage from 'containers/FPLMarketingPage'
import { PAGE_NAMES } from 'containers/FPLLogging/constants'
import { NoServicesErrorAlert } from 'containers/FPLOrderCreate/ErrorAlert'
import { NO_PICKUP_NEEDED, TIME_SCHEDULED } from '../FPLOrderCreate/constants'
import { PICKUP_TYPES } from '../PickupType/constants'
import { CommercialInvoiceStep } from './CommercialInvoiceStep'
import { SaveAndContinue } from '../FPLOrderCreate/SaveAndContinue'
import { ReviewStep } from './ReviewStep'
import { UploadAdditionalFileStep } from './UploadAdditionalFileStep'
import { mpTrackAbandonedOrderCreation, mpTrackAddedIntlServiceDetail } from 'containers/FPLLogging/mixpanel'
import { trackAbandonedOrderCreation, trackAddedIntlServiceDetail } from 'containers/FPLLogging/rum'
import useBeforeUnload from 'containers/FPLOrderCreate/hooks/useBeforeUnload'
import {
  getCountriesFromService,
  getCountryInfo,
  getMMCCServices,
  getSortedServices
} from 'containers/FPLCommon/dataUtils'
import {
  SERVICE_TYPE,
  SERVICE_TYPE_LABEL_IN_MIXPANEL,
  INPUT_METHODS,
  GLOBAL_CURRENCY,
  GLOBAL_COUNTRY_CODE,
  CURRENY_COUNTRY_LABELS,
  OCMethod
} from 'containers/FPLCommon/constants'

const initialState: MMCCOrderCreationState = {
  currentStep: MMCC_OC_STEP.SERVICE_DETAILS,
  services: [],
  isSelectingService: false,
  selectedService: undefined,
  senderAddress: undefined,
  activeGroupId: 0,
  mmccB2BFormGroup: {
    [DEFAULT_MMCC_B2B_FORM_FIELD.id]: DEFAULT_MMCC_B2B_FORM_FIELD
  },
  b2bBundleFormGroup: {
    [DEFAULT_B2B_BUNDLE_FORM_FIELD.id]: DEFAULT_B2B_BUNDLE_FORM_FIELD
  },
  visibleAddBoxForm: true,
  recipientAddress: undefined,
  pickupAddress: undefined,
  pickupType: PICKUP_TYPES.NO,
  ocMethod: undefined,
  isAddressLoading: false,
  timeSlot: undefined,
  timeSlots: [],
  fileUploadStatus: FILE_UPLOAD_STATUS.READY_TO_UPLOAD,
  boxOrders: [],
  addresses: [],
  keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING,
  allOrders: {},
  validOrders: {},
  invalidOrders: {},
  validRows: {},
  invalidRows: {},
  errorsByBoxLevel: {},
  errorsByParcelLevel: {},
  fileName: undefined,
  numberOfValidItemsInValidOrders: 0,
  numberOfInvalidItemsInInvalidOrders: 0,
  numberOfValidItemsInInvalidOrders: 0,
  customsCurrency: GLOBAL_CURRENCY,

  // supported document
  commercialStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
  commercialFiles: [],
  commercialErrors: [],
  commercialFileNames: [],

  additionalDocumentUploadStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
  additionalDocumentFiles: [],
  additionalDocumentErrors: [],
  additionalDocumentFileNames: []
}

const MMCCOrderCreation = () => {
  const navigate = useNavigate()
  const { error, detail, loading, fullRes } = useMarketingContent(navigate, location, ROUTES.FPL_MMCC_ORDER_CREATION, {
    noHeader: false
  })

  const intl = useIntl()
  const [state, setState] = useState(initialState)
  const updateState = (newState: Partial<MMCCOrderCreationState>) => {
    setState(prevState => ({ ...prevState, ...newState }))
  }

  const {
    currentStep,
    isSelectingService,
    services,
    selectedService,
    timeSlot,
    pickupAddress,
    customsCurrency,
    boxOrders
  } = state

  const updateService = (newService: Service) => {
    if (selectedService == newService) {
      return
    }

    let newOCMethod = undefined
    if (newService.type === SERVICE_TYPE.MMCCB2C) {
      newOCMethod = OCMethod.UPLOAD
    }

    const { originCountry, destinationCountry } = getCountriesFromService(newService)

    updateState({
      // common info
      selectedService: newService,
      originCountryCode: originCountry,
      destinationCountryCode: destinationCountry,
      currentStep: MMCC_OC_STEP.SERVICE_DETAILS,
      ocMethod: newOCMethod,

      // service details
      senderAddress: undefined,
      recipientAddress: undefined,
      pickupAddress: undefined,
      pickupType: PICKUP_TYPES.NO,
      timeSlot: undefined,
      addresses: [],
      mountedAddress: false,

      // orders info
      boxOrders: [],
      fileUploadStatus: FILE_UPLOAD_STATUS.READY_TO_UPLOAD,
      keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING,
      allOrders: {},
      validOrders: {},
      invalidOrders: {},
      validRows: {},
      invalidRows: {},
      fileName: undefined,
      numberOfValidItemsInValidOrders: 0,
      numberOfInvalidItemsInInvalidOrders: 0,
      numberOfValidItemsInInvalidOrders: 0,
      customsCurrency:
        destinationCountry === GLOBAL_COUNTRY_CODE ? GLOBAL_CURRENCY : getCountryInfo(destinationCountry).currencies[0],
      activeGroupId: 0,
      b2bBundleFormGroup: {
        [DEFAULT_B2B_BUNDLE_FORM_FIELD.id]: DEFAULT_B2B_BUNDLE_FORM_FIELD
      },
      mmccB2BFormGroup: {
        [DEFAULT_MMCC_B2B_FORM_FIELD.id]: DEFAULT_MMCC_B2B_FORM_FIELD
      },

      // CI Supporting docs
      commercialStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      commercialFiles: [],
      commercialErrors: [],
      commercialFileNames: [],
      // Additional document docs
      additionalDocumentUploadStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      additionalDocumentFiles: [],
      additionalDocumentErrors: [],
      additionalDocumentFileNames: []
    })
  }

  useEffect(() => {
    updateState({ isSelectingService: true })
    const partnerServices = detail?.settings?.oc_config?.services || []
    const mmccServices = getMMCCServices(partnerServices)
    const sortedServices = getSortedServices(mmccServices)
    const defaultFirstService = sortedServices.length ? sortedServices[0] : undefined

    const newState: Partial<MMCCOrderCreationState> = {
      isSelectingService: false,
      services: sortedServices,
      selectedService: defaultFirstService
    }
    if (defaultFirstService) {
      const { originCountry, destinationCountry } = getCountriesFromService(defaultFirstService)
      newState.customsCurrency = CURRENY_COUNTRY_LABELS[defaultFirstService.destination_country] || GLOBAL_CURRENCY
      newState.originCountryCode = originCountry
      newState.destinationCountryCode = destinationCountry
    }

    let selectedOCMethod = undefined
    if (defaultFirstService?.type === SERVICE_TYPE.MMCCB2C) {
      selectedOCMethod = OCMethod.UPLOAD
    }

    setState(prevState => ({
      ...prevState,
      ...newState,
      activeGroupId: 0,
      ocMethod: selectedOCMethod,
      mmccB2BFormGroup: {
        [DEFAULT_MMCC_B2B_FORM_FIELD.id]: DEFAULT_MMCC_B2B_FORM_FIELD
      },
      b2bBundleFormGroup: {
        [DEFAULT_B2B_BUNDLE_FORM_FIELD.id]: DEFAULT_B2B_BUNDLE_FORM_FIELD
      }
    }))
  }, [detail])

  const handleTrackMixpanel = () => {
    const numberOfOrders = boxOrders?.length || 0
    const trackingData = {
      numberOfOrders,
      pageName: PAGE_NAMES[ROUTES.FPL_MMCC_ORDER_CREATION],
      orderType: SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    }
    mpTrackAbandonedOrderCreation(trackingData)
    trackAbandonedOrderCreation(trackingData)
  }

  useBeforeUnload(handleTrackMixpanel)

  const handleOnLeaveHeader = () => {
    handleTrackMixpanel()
    navigate(ROUTES.FPL_HOME)
  }

  const changeCurrentStep = (step: number) => {
    setState(prevState => ({ ...prevState, currentStep: step }))
  }

  // error happened when fetch partner
  if (error && !loading) {
    const { apiUrl, message, status } = buildFullErrorInfo(fullRes)
    const errorInfo = {
      errorApi: apiUrl,
      errorMessage: message,
      errorStatus: status
    }
    return error.code !== NOT_FOUND_CODE ? (
      <FPLErrorPage pageName={PAGE_NAMES[ROUTES.FPL_OC]} errors={[errorInfo]} />
    ) : (
      <FPLMarketingPage />
    )
  }

  // error happens when services not found
  if (!services.length && detail) {
    return <NoServicesErrorAlert message={intl.formatMessage({ id: 'cross_border_profile_no_mmcc_service' })} />
  }

  const saveOCMethod = (method: string) => {
    let currentOcMethod = undefined
    if (method === INPUT_METHODS.FILE) {
      currentOcMethod = OCMethod.UPLOAD
    }
    if (method === INPUT_METHODS.KEYBOARD) {
      currentOcMethod = OCMethod.KEYBOARD
    }
    setState(prevState => ({
      ...prevState,
      ocMethod: currentOcMethod
    }))
  }

  const saveServiceDetailStep = () => {
    const trackingData = {
      serviceType: selectedService.description,
      pickupOption: timeSlot?.readyDatetime ? TIME_SCHEDULED : NO_PICKUP_NEEDED,
      pageName: PAGE_NAMES[ROUTES.FPL_MMCC_ORDER_CREATION],
      customsCurrency: customsCurrency?.toUpperCase(),
      pickupAddress,
      timeSlot: timeSlot?.readyDatetime,
      orderType: SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    }
    mpTrackAddedIntlServiceDetail(trackingData)
    trackAddedIntlServiceDetail(trackingData)
    changeCurrentStep(MMCC_OC_STEP.ORDERS)
  }

  const renderUploadAddtionalStep = () => {
    const stepKey =
      selectedService.type === SERVICE_TYPE.B2B_BUNDLE ? MMCC_OC_STEP.ADDITIONAL_DOCUMENT : MMCC_OC_STEP.DELIVERY_ORDER
    return (
      <VerticalStepper.Step
        key={stepKey}
        title={intl.formatMessage({
          id:
            selectedService.type === SERVICE_TYPE.B2B_BUNDLE
              ? TRANSLATION_KEY.ADDITIONAL_DOCUMENT
              : TRANSLATION_KEY.DELIVERY_ORDER
        })}
        status={getStepStatus(currentStep, stepKey)}
        onClick={() => changeCurrentStep(stepKey)}
        className='w-100'
      >
        {(currentStep == stepKey || currentStep == MMCC_OC_STEP.REVIEW) && <UploadAdditionalFileStep />}
      </VerticalStepper.Step>
    )
  }

  return (
    <MMCCOrderCreationContext.Provider value={{ ocState: state, updateService, updateOCState: updateState }}>
      <OCHeader title='create_int_b2b_order_details' onLeave={handleOnLeaveHeader} />
      {loading || isSelectingService ? (
        <div className='flex justify-center items-center h-36 w-100'>
          <Spin />{' '}
        </div>
      ) : (
        <BCWrapper>
          <VerticalStepperContainer>
            <VerticalStepper.Step
              key={MMCC_OC_STEP.SERVICE_DETAILS}
              title={intl.formatMessage({ id: TRANSLATION_KEY.SERVICE_DETAILS })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.SERVICE_DETAILS)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.SERVICE_DETAILS)}
              className='w-100'
            >
              {(currentStep == MMCC_OC_STEP.SERVICE_DETAILS || currentStep == MMCC_OC_STEP.REVIEW) && (
                <ServiceDetailsStep />
              )}
              {currentStep == MMCC_OC_STEP.SERVICE_DETAILS && <SaveAndContinue onSave={saveServiceDetailStep} />}
            </VerticalStepper.Step>
            <VerticalStepper.Step
              key={MMCC_OC_STEP.ORDERS}
              title={intl.formatMessage({
                id: selectedService.type === SERVICE_TYPE.B2B_BUNDLE ? TRANSLATION_KEY.BUNDLES : TRANSLATION_KEY.ORDERS
              })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.ORDERS)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.ORDERS)}
              className='w-100'
            >
              {(currentStep == MMCC_OC_STEP.ORDERS || currentStep == MMCC_OC_STEP.REVIEW) && (
                <OrdersStep saveOCMethod={saveOCMethod} selectedService={selectedService} partnerId={detail?.id} />
              )}
            </VerticalStepper.Step>
            <VerticalStepper.Step
              key={MMCC_OC_STEP.COMMERCIAL_INVOICE}
              title={intl.formatMessage({ id: TRANSLATION_KEY.COMMERCIAL_INVOICE })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.COMMERCIAL_INVOICE)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.COMMERCIAL_INVOICE)}
              className='w-100'
            >
              {(currentStep == MMCC_OC_STEP.COMMERCIAL_INVOICE || currentStep == MMCC_OC_STEP.REVIEW) && (
                <CommercialInvoiceStep />
              )}
            </VerticalStepper.Step>
            {renderUploadAddtionalStep()}
            <VerticalStepper.Step
              key={MMCC_OC_STEP.REVIEW}
              title={intl.formatMessage({ id: TRANSLATION_KEY.REVIEW })}
              status={getStepStatus(currentStep, MMCC_OC_STEP.REVIEW)}
              onClick={() => changeCurrentStep(MMCC_OC_STEP.REVIEW)}
              className='w-100'
            >
              {currentStep == MMCC_OC_STEP.REVIEW && <ReviewStep />}
            </VerticalStepper.Step>
          </VerticalStepperContainer>
        </BCWrapper>
      )}
    </MMCCOrderCreationContext.Provider>
  )
}

const getStepStatus = (currentStep: number, step: number) => {
  if (step === currentStep) {
    return INTERNAL_STEP_STATUS.current as StepStatus
  }
  if (currentStep > step) {
    return INTERNAL_STEP_STATUS.completed as StepStatus
  }
  return INTERNAL_STEP_STATUS.unvisited as StepStatus
}

export default MMCCOrderCreation
