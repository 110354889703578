/**
 *
 * 4PL Custom Currency
 *
 */

import React, { useMemo } from 'react'
import { Icon } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import { faBoxUsd } from '@fortawesome/pro-light-svg-icons'
import { InfoTooltip } from 'containers/FPLOrderRequestList/Fields'
import { Select } from 'components/Select'
import { toUpper } from 'lodash'
import { StyledFlexAlignCenter } from './styles'
import { getCountryInfo } from 'containers/FPLCommon/dataUtils'
import { GLOBAL_COUNTRY_CODE, GLOBAL_CURRENCY } from 'containers/FPLCommon/constants'

interface CustomsCurrencyProps {
  currency: string
  country: string
  onChange: (newCurrency: string) => void
}

const renderTooltip = () => {
  return <InfoTooltip tooltipTextId='international_currency_tooltip' />
}

export const CustomsCurrency = ({ currency, country, onChange }: CustomsCurrencyProps) => {
  const currencies = useMemo(() => {
    if (country === GLOBAL_COUNTRY_CODE) return [GLOBAL_CURRENCY]
    return [...(getCountryInfo(country)?.currencies || []), GLOBAL_CURRENCY]
  }, [country])

  const renderActive = () => {
    return (
      <StyledFlexAlignCenter>
        <div style={{ width: 41 }}>
          <Icon icon={faBoxUsd} style={{ color: '#aaaeb3', fontSize: 24 }} />
        </div>
        <div style={{ width: 120 }}>
          <Select value={toUpper(currency)} onChange={onChange} style={{ width: '100%' }}>
            {currencies.map(item => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>{renderTooltip()}</div>
      </StyledFlexAlignCenter>
    )
  }

  return <OCSubStep title='custom_currency' status={OCSubStep.OPEN} renderActive={renderActive} hideSave />
}
