import React, { useMemo } from 'react'
import { T, Row, Col } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { Vspace } from 'components/Vspace'
import { PackingAlert } from 'components/PackingAlert'
import { faCloudUpload } from '@fa-pro-light/faCloudUpload'
import { faKeyboard } from '@fa-pro-light/faKeyboard'
import { StyledCard } from 'containers/FPLOrderCreate/styles'
import { LargeOptionButton } from './LargeOptionButton'
import { INPUT_METHODS } from 'containers/FPLCommon/constants'

export const OCMethodSelection = ({ onMethodChange }) => {
  return (
    <>
      <StyledCard data-testid={'oc-method-selection'} hoverable={false}>
        <Row type='flex' justify='center'>
          <Text size={18} type='bold'>
            <T id='choose_input' />
          </Text>
        </Row>
        <Vspace height={16} />
        <PackingAlert country='sg' intl={{ locale: 'en' }} />
        <Vspace height={16} />
        <Row gutter={16}>
          <Col span={12}>
            <LargeOptionButton
              icon={faKeyboard}
              onClick={() => onMethodChange(INPUT_METHODS.KEYBOARD)}
              data-analyticsid='createMMCCOrdersManually'
            >
              <T id='type_orders' />
            </LargeOptionButton>
          </Col>
          <Col span={12}>
            <LargeOptionButton
              icon={faCloudUpload}
              onClick={() => onMethodChange(INPUT_METHODS.FILE)}
              data-analyticsid='createMMCCOrdersManually'
            >
              <T id='upload_file' />
            </LargeOptionButton>
          </Col>
        </Row>
      </StyledCard>
    </>
  )
}
