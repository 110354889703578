import { datadogRum } from '@datadog/browser-rum'
import {
  TRACK_ABANDONED_ORDER_CREATION,
  TRACK_CONFIRMED_THE_ORDER,
  TRACK_FIXED_ORDER,
  TRACK_PRINTED_AIRWAY_BILL,
  TRACK_STARTED_INTL_ORDER_CREATION,
  TRACK_VIEWED_ORDER,
  TRACK_VIEWED_ALL_ORDER,
  TRACK_VISITED_INTERNATIONAL_HOME,
  TRACK_DOWLOADED_ALL_ORDERS,
  TRACK_ADVANCED_SEARCH_ORDER,
  TRACK_FILTERED_INTERNATIONAL_ORDER,
  TRACK_ADDED_INTL_SERVICE_DETAIL,
  TRACK_CLICKED_TO_REPLACE_FILE,
  TRACK_CLICKED_TO_REUPLOAD_FILE,
  TRACK_CLICKED_TO_VIEW_DETAIL_TO_CORRECT_ERROR,
  TRACK_CLICKED_ADD_ANOTHER,
  TRACK_ADDED_INTL_ORDER_DETAIL,
  TRACK_CLICKED_FIX_ORDER,
  TRUTHY_FALSY,
  TRACK_PERFORMANCE_OC,
  TRACK_CLICKED_XB_RETURN_PARCEL,
  TRACK_DISPOSE_XB_RETURN_PARCEL,
  TRACK_RETURNED_TO_ME_XB_RETURN_PARCEL,
  TRACK_RELABELED_XB_RETURN_PARCEL,
  TRACK_CLICKED_SUBMITTED_FOR_FULFILLMENT,
  TRACK_CLICKED_AUTO_DISPOSED,
  RETURN_OPTIONS,
  TRACK_LOADED_ERROR_PAGE,
  TRACK_BULK_UPLOAD_FILE,
  SHOW_ONBOARDING_RATE_CARD,
  DOWNLOAD_RESTRICTION_ONBOARDING,
  SELECT_LANES_ONBOARDING,
  EXIT_INTERNATIONAL_ONBOARDING,
  OPENED_ONBOARDING,
  TRACK_ADDED_BULK_RELABELED_ORDER_DETAIL,
  ADDED_CI_FILE,
  SKIP_CI_FILE,
  ADDED_DO_FILE,
  SKIP_DO_FILE
} from './constants'
import { SEARCH_METHODS_OPTIONS } from 'containers/FPLAllOrders/constants'

export const trackVisitedInternationalHome = data => {
  const { pageName } = data
  datadogRum.addAction(TRACK_VISITED_INTERNATIONAL_HOME.NAME, {
    [TRACK_VISITED_INTERNATIONAL_HOME.PROPS.PAGE_NAME]: pageName
  })
}

export const trackCreatedOrder = ({ pageName, source, orderType }) => {
  datadogRum.addAction(TRACK_STARTED_INTL_ORDER_CREATION.NAME, {
    Source: source,
    [TRACK_STARTED_INTL_ORDER_CREATION.PROPS.PAGE_NAME]: pageName,
    [TRACK_STARTED_INTL_ORDER_CREATION.PROPS.ORDER_TYPE]: orderType
  })
}

export const trackViewedOrder = ({ pageName }) => {
  datadogRum.addAction(TRACK_VIEWED_ORDER.NAME, {
    [TRACK_VIEWED_ORDER.PROPS.PAGE_NAME]: pageName
  })
}

export const trackViewedAllOrder = ({ pageName }) => {
  datadogRum.addAction(TRACK_VIEWED_ALL_ORDER.NAME, {
    [TRACK_VIEWED_ALL_ORDER.PROPS.PAGE_NAME]: pageName
  })
}

export const trackFixedOrder = data => {
  datadogRum.addAction(TRACK_FIXED_ORDER.NAME, { ...data })
}

export const trackPrintedAirwayBill = ({ status, source, printedOrder, pageName }) => {
  datadogRum.addAction(TRACK_PRINTED_AIRWAY_BILL.NAME, {
    [TRACK_PRINTED_AIRWAY_BILL.PROPS.PRINTED_AIRWAY_BILL_STATUS]: status,
    [TRACK_PRINTED_AIRWAY_BILL.PROPS.SOURCE]: source,
    [TRACK_PRINTED_AIRWAY_BILL.PROPS.NUMBER_OF_PRINTED_ORDER]: printedOrder,
    [TRACK_PRINTED_AIRWAY_BILL.PROPS.PAGE_NAME]: pageName
  })
}

export const trackConfirmTheOrder = data => {
  const { NAME, PROPS } = TRACK_CONFIRMED_THE_ORDER
  const {
    inputMethod,
    totalCreated,
    totalNeededActions,
    pageName,
    totalOrders,
    totalCod,
    responseTime,
    orderType
  } = data

  datadogRum.addAction(NAME, {
    [PROPS.NUMBER_OF_VALID_ORDER]: totalCreated,
    [PROPS.NUMBER_OF_INVALID_ORDER]: totalNeededActions,
    [PROPS.TOTAL_ORDERS]: totalOrders,
    [PROPS.ODER_COD_AMOUNT]: totalCod,
    [PROPS.INPUT_METHOD]: inputMethod,
    [PROPS.PAGE_NAME]: pageName,
    [PROPS.FINISH_LOADING_THE_SUCCESS_FAILED_PAGE]: responseTime,
    [PROPS.ORDER_TYPE]: orderType
  })
}

export const trackConfirmRelabelRequest = data => {
  const { NAME, PROPS } = TRACK_RELABELED_XB_RETURN_PARCEL
  const { numberOfTids, method, uiRenderingTimeInSecond } = data
  datadogRum.addAction(NAME, {
    [PROPS.NUMBER_OF_SELECTED_PARCELS]: numberOfTids,
    [PROPS.METHOD]: method,
    [PROPS.UI_RENDERING_TIME]: uiRenderingTimeInSecond
  })
}

export const trackAddedBulkRelabeledOrderDetail = data => {
  const { NAME, PROPS } = TRACK_ADDED_BULK_RELABELED_ORDER_DETAIL
  const { numberOfValidOrders, numberOfInvalidOrders } = data
  datadogRum.addAction(NAME, {
    [PROPS.NUMBER_OF_VALID_ORDER]: numberOfValidOrders,
    [PROPS.NUMBER_OF_INVALID_ORDER]: numberOfInvalidOrders
  })
}

export const trackAddedIntlServiceDetail = data => {
  const {
    serviceType,
    pickupOption,
    pageName,
    defaultReturnAddress,
    customsCurrency,
    pickupAddress,
    timeSlot,
    numberOfErrors,
    elementShowError,
    errorMessages,
    orderType
  } = data
  const { NAME, PROPS } = TRACK_ADDED_INTL_SERVICE_DETAIL
  datadogRum.addAction(NAME, {
    [PROPS.SERVICE_TYPE]: serviceType,
    [PROPS.PICKUP_OPTION]: pickupOption,
    [PROPS.PAGE_NAME]: pageName,
    [PROPS.DEFAULT_RETURN_ADDRESS]: defaultReturnAddress ? TRUTHY_FALSY.YES : TRUTHY_FALSY.NO,
    [PROPS.CUSTOMS_CURRENCY]: customsCurrency,
    [PROPS.PICKUP_ADDRESS]: pickupAddress ? TRUTHY_FALSY.YES : TRUTHY_FALSY.NO,
    [PROPS.TIME_SLOT]: timeSlot ? TRUTHY_FALSY.YES : TRUTHY_FALSY.NO,
    [PROPS.ORDER_TYPE]: orderType,
    ...(numberOfErrors && {
      [PROPS.NUMBER_OF_ERRORS]: numberOfErrors
    }),
    ...(elementShowError && {
      [PROPS.ELEMENT_SHOW_ERROR]: elementShowError
    }),
    ...(errorMessages && {
      [PROPS.ERROR_MESSAGE]: errorMessages
    })
  })
}

export const trackAbandonedOrderCreation = ({ numberOfOrders, pageName, orderType }) => {
  datadogRum.addAction(TRACK_ABANDONED_ORDER_CREATION.NAME, {
    [TRACK_ABANDONED_ORDER_CREATION.PROPS.NUMBER_OF_ORDER_ABANDONED]: numberOfOrders,
    [TRACK_ABANDONED_ORDER_CREATION.PROPS.PAGE_NAME]: pageName,
    [TRACK_ABANDONED_ORDER_CREATION.PROPS.ORDER_TYPE]: orderType
  })
}

export const trackDownloadedAllOrders = ({ numberDownloadedOrders }) => {
  datadogRum.addAction(TRACK_DOWLOADED_ALL_ORDERS.NAME, {
    [TRACK_DOWLOADED_ALL_ORDERS.PROPS.NUMBER_DOWNLOADED_ORDERS]: numberDownloadedOrders
  })
}

export const trackFilteredInternationalOrder = ({ friendlyStatuses, dateRange }) => {
  datadogRum.addAction(TRACK_FILTERED_INTERNATIONAL_ORDER.NAME, {
    [TRACK_FILTERED_INTERNATIONAL_ORDER.PROPS.STATUS_GROUP]: friendlyStatuses,
    [TRACK_FILTERED_INTERNATIONAL_ORDER.PROPS.DATE_RANGE]: dateRange
  })
}

export const trackAdvancedSearchOrder = ({ searchMethod, numberOfSearches }) => {
  datadogRum.addAction(TRACK_ADVANCED_SEARCH_ORDER.NAME, {
    [TRACK_ADVANCED_SEARCH_ORDER.PROPS.SEARCH_METHOD]: SEARCH_METHODS_OPTIONS[searchMethod],
    [TRACK_ADVANCED_SEARCH_ORDER.PROPS.NUMBER_OF_SEARCHES]: numberOfSearches
  })
}

export const trackClickedToReplaceFile = () => {
  datadogRum.addAction(TRACK_CLICKED_TO_REPLACE_FILE.NAME)
}

export const trackClickedToReUploadFile = () => {
  datadogRum.addAction(TRACK_CLICKED_TO_REUPLOAD_FILE.NAME)
}

export const trackClickedToViewDetailToCorrectError = () => {
  datadogRum.addAction(TRACK_CLICKED_TO_VIEW_DETAIL_TO_CORRECT_ERROR.NAME)
}

export const trackClickedAddAnother = () => {
  datadogRum.addAction(TRACK_CLICKED_ADD_ANOTHER.NAME)
}

export const trackAddedIntlOrderDetail = data => {
  datadogRum.addAction(TRACK_ADDED_INTL_ORDER_DETAIL.NAME, { ...data })
}

export const trackClickedFixOrder = ({ pageName, ltInvalidOrderCreate }) => {
  datadogRum.addAction(TRACK_CLICKED_FIX_ORDER.NAME, { [TRACK_CLICKED_FIX_ORDER.PROPS.PAGE_NAME]: pageName })
}

export const trackPerformanceOC = ({ responseTime, uiRenderingTime, orderType }) => {
  datadogRum.addAction(TRACK_PERFORMANCE_OC.NAME, {
    [TRACK_PERFORMANCE_OC.PROPS.API_LOADING_TIME]: responseTime,
    [TRACK_PERFORMANCE_OC.PROPS.UI_RENDERING_TIME]: uiRenderingTime,
    [TRACK_PERFORMANCE_OC.PROPS.ORDER_TYPE]: orderType
  })
}

export const trackClickedReturnParcel = ({ instance }) => {
  const { PROPS, NAME } = TRACK_CLICKED_XB_RETURN_PARCEL
  datadogRum.addAction(NAME, {
    [PROPS.INSTANCE]: instance
  })
}

export const trackDisposeReturnParcel = ({ numberOfParcels }) => {
  const { PROPS, NAME, PEOPLE } = TRACK_DISPOSE_XB_RETURN_PARCEL
  datadogRum.addAction(NAME, {
    [PROPS.NUMBER_OF_SELECTED_PARCELS]: numberOfParcels
  })
}

export const trackReturnedToMeReturnParcel = ({ numberOfParcels, returnOption }) => {
  const { PROPS, NAME, PEOPLE } = TRACK_RETURNED_TO_ME_XB_RETURN_PARCEL
  datadogRum.addAction(NAME, {
    [PROPS.NUMBER_OF_SELECTED_PARCELS]: numberOfParcels,
    [PROPS.RETURN_OPTIONS]: RETURN_OPTIONS[returnOption]
  })
}

export const trackRelabeledReturnParcel = ({
  numberOfParcels,
  method,
  results,
  numberOfValidOrders,
  numberOfInvalidOrders,
  uiRenderingTime
}) => {
  const { PROPS, NAME, PEOPLE } = TRACK_RELABELED_XB_RETURN_PARCEL

  datadogRum.addAction(NAME, {
    [PROPS.NUMBER_OF_SELECTED_PARCELS]: numberOfParcels,
    [PROPS.METHOD]: method,
    [PROPS.RELABEL_RESULTS]: results,
    [PROPS.NUMBER_OF_VALID_ORDER]: numberOfValidOrders,
    [PROPS.NUMBER_OF_INVALID_ORDER]: numberOfInvalidOrders,
    [PROPS.UI_RENDERING_TIME]: uiRenderingTime
  })
}

export const trackClickedSubmittedForFulfillment = () => {
  const { NAME } = TRACK_CLICKED_SUBMITTED_FOR_FULFILLMENT
  datadogRum.addAction(NAME)
}

export const trackClickedAutoDisposed = () => {
  const { NAME } = TRACK_CLICKED_AUTO_DISPOSED
  datadogRum.addAction(NAME)
}

export const trackLoadedErrorPage = ({ previousPage, errorApi, errorStatus, errorMessage }) => {
  const { NAME, PROPS } = TRACK_LOADED_ERROR_PAGE
  datadogRum.addAction(NAME, {
    [PROPS.PREVIOUS_PAGE]: previousPage,
    [PROPS.API_CAUSES_ERROR]: errorApi,
    [PROPS.ERROR_STATUS]: errorStatus,
    [PROPS.ERROR_MESSAGE]: errorMessage
  })
}

export const trackUploadFile = data => {
  const { result, numberOfValidOrders, numberOfInvalidOrders, totalOrders, errorMessage, orderType } = data
  const { NAME, PROPS } = TRACK_BULK_UPLOAD_FILE
  datadogRum.addAction(NAME, {
    [PROPS.UPLOAD_RESULT]: result,
    [PROPS.NUMBER_OF_VALID_ORDER]: numberOfValidOrders,
    [PROPS.NUMBER_OF_INVALID_ORDER]: numberOfInvalidOrders,
    [PROPS.TOTAL_ORDERS]: totalOrders,
    [PROPS.ORDER_TYPE]: orderType,
    ...(errorMessage && {
      [PROPS.ERROR_MESSAGE]: errorMessage
    })
  })
}

export const trackDownloadRateCard = () => {
  datadogRum.addAction(SHOW_ONBOARDING_RATE_CARD.NAME)
}

export const trackDownloadRestrictionList = () => {
  datadogRum.addAction(DOWNLOAD_RESTRICTION_ONBOARDING.NAME)
}

export const trackSelectedLanesOnboarding = ({ supportedLanes, unsupportedLanes, weightPerParcel, parcelPerDay }) => {
  const { NAME, PROPS } = SELECT_LANES_ONBOARDING
  datadogRum.addAction(NAME, {
    [PROPS.LANE]: supportedLanes,
    [PROPS.UNSUPPORTED_LANE]: unsupportedLanes,
    [PROPS.WEIGHT_PER_PARCEL]: weightPerParcel,
    [PROPS.PARCEL_PER_DAY]: parcelPerDay
  })
}

export const trackExitOnboarding = () => {
  datadogRum.addAction(EXIT_INTERNATIONAL_ONBOARDING.NAME)
}

export const trackOpenedOnboarding = () => {
  datadogRum.addAction(OPENED_ONBOARDING.NAME)
}

export const trackAddedCIFile = params => {
  const { NAME, PROPS } = ADDED_CI_FILE
  const { fileType, fileSize, numberOfFiles, orderType } = params
  datadogRum.addAction(NAME, {
    [PROPS.FILE_TYPE]: fileType,
    [PROPS.FILE_SIZE]: fileSize,
    [PROPS.NUMBER_OF_FILES]: numberOfFiles,
    [PROPS.ORDER_TYPE]: orderType
  })
}

export const trackSkipCIFile = params => {
  const { NAME, PROPS } = SKIP_CI_FILE
  datadogRum.addAction(NAME, {
    [PROPS.ORDER_TYPE]: params.orderType
  })
}

export const trackAddedDOFile = params => {
  const { NAME, PROPS } = ADDED_DO_FILE
  const { fileType, fileSize, numberOfFiles, orderType } = params
  datadogRum.addAction(NAME, {
    [PROPS.FILE_TYPE]: fileType,
    [PROPS.FILE_SIZE]: fileSize,
    [PROPS.NUMBER_OF_FILES]: numberOfFiles,
    [PROPS.ORDER_TYPE]: orderType
  })
}

export const trackSkipDOFile = params => {
  const { NAME, PROPS } = SKIP_DO_FILE
  datadogRum.addAction(NAME, {
    [PROPS.ORDER_TYPE]: params.orderType
  })
}
