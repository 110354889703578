import { transformOrderRequestParamsForE2E } from 'containers/FPLOrderRequestList/dataUtils'
import {
  EXAMPLE_INTERNATIONAL_DATA_ROW_1,
  EXAMPLE_INTERNATIONAL_DATA_ROW_2,
  VOLUME_VALUE,
  INSTRUCTION_DATA_BY_COUNTRY,
  EXAMPLE_DATA_BY_COUNTRY,
  INSTRUCTION_DATA
} from './constants'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { DELIVERY_TIME_PARAMS } from 'containers/FPLOrderRequestList/constants'

export const buildPickupFields = ({ pickup, returnAddress, senderAddress, pickupAddress = {}, timeSlot, volume }) => {
  const slot = DELIVERY_TIME_PARAMS[+timeSlot?.timeWindow]

  const addressParams = {
    name: senderAddress?.name,
    address_line1: senderAddress.address1,
    contact_number: senderAddress.contact,
    country_code: senderAddress.country,
    ...(senderAddress.address2 && { address_line2: senderAddress.address2 }),
    ...(senderAddress.postcode && { post_code: senderAddress.postcode }),
    ...(senderAddress.email && { contact_email: senderAddress.email })
  }

  const returnAddressParams = {
    name: returnAddress?.name,
    address_line1: returnAddress.address1,
    contact_number: returnAddress.contact,
    country_code: returnAddress.country,
    ...(returnAddress.address2 && { address_line2: returnAddress.address2 }),
    ...(returnAddress.postcode && { post_code: returnAddress.postcode }),
    ...(returnAddress.email && { contact_email: returnAddress.email })
  }

  if (pickup === PICKUP_TYPES.NO) {
    return { from: addressParams, return: returnAddressParams }
  }

  const pickupAddressParams = {
    name: pickupAddress.name,
    address_line1: pickupAddress.address1,
    country_code: pickupAddress.country,
    contact_number: pickupAddress.contact,
    ...(pickupAddress.address2 && { address_line2: pickupAddress.address2 }),
    ...(pickupAddress.postcode && { post_code: pickupAddress.postcode }),
    ...(pickupAddress.email && { contact_email: pickupAddress.email })
  }

  const pickupParams = {
    pickup_date: timeSlot?.pickupDate,
    pickup_timeslot: {
      start_time: slot && slot.split('-')[0],
      end_time: slot && slot.split('-')[1]
    },
    pickup_approx_volume: VOLUME_VALUE[volume],
    ...(timeSlot?.comments && { pickup_instructions: timeSlot?.comments }),
    pickup_address: pickupAddressParams
  }

  return { from: addressParams, return: returnAddressParams, pickup: pickupParams }
}

export const buildItemsRequestData = (items, service) => {
  const SGCode = COUNTRIES.SG.toUpperCase()
  const MYCode = COUNTRIES.MY.toUpperCase()
  const result = compact(items).map(item => {
    return {
      description: item.customsDescription,
      unit_value: +item.goodsValue || 0,
      quantity: +item.quantityValue || 1,
      unit_weight: +item.weight || 0,
      ...(item.color && {
        color: item.color || ''
      }),
      origin_country: COUNTRIES[service.origin_country]?.toUpperCase(),
      ...((service.destination_country === MYCode || service.destination_country === SGCode) && {
        taxes: {
          ...(service.destination_country === MYCode && {
            lvg: {
              number: item.lvgNumber || ''
            }
          }),
          ...(service.destination_country === SGCode && {
            gst: {
              is_included: item.gstIncluded === GST_KEYS.YES,
              number: item.gstNumber
            }
          })
        }
      })
    }
  })
  return result
}

export const buildOrderRequestDataFromKeyboardInput = (service, manualData, customsCurrency) => {
  const result = []
  manualData.forEach(item => {
    const { items } = item
    let itemsObj = {}
    if (items && items.length > 0) {
      const itemsRequest = buildItemsRequestData(items, service)
      itemsObj = { items: itemsRequest }
    }
    const dataSource = {
      request_body: {
        service_code: service.code,
        to: {
          country_code: service.destination_country
        },
        source_reference_id: item.trackingId
      },
      ...itemsObj
    }
    item.goodsCurrency = customsCurrency?.toUpperCase()
    const parsedItem = transformOrderRequestParamsForE2E(item, dataSource, service.origin_country)
    result.push(parsedItem)
  })

  return result
}

export const removeEmpty = obj => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeEmpty(val)) ||
      ((val === null || val === '' || val === undefined) && delete obj[key])
  )
  return obj
}

export const generateTemplate = destinationCountry => {
  const instructionFields = INSTRUCTION_DATA_BY_COUNTRY[destinationCountry] ?? INSTRUCTION_DATA
  const exampleRow1 = EXAMPLE_DATA_BY_COUNTRY[destinationCountry]?.ROW_1 ?? EXAMPLE_INTERNATIONAL_DATA_ROW_1
  const exampleRow2 = EXAMPLE_DATA_BY_COUNTRY[destinationCountry]?.ROW_2 ?? EXAMPLE_INTERNATIONAL_DATA_ROW_2
  const csvHeaderFields = Object.keys(instructionFields)
  const csvDataFields = [Object.values(instructionFields), Object.values(exampleRow1), Object.values(exampleRow2)]
  return {
    csvHeaderFields,
    csvDataFields
  }
}
