import React, { useContext } from 'react'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { MMCCKeyboard } from './MMCCKeyboard'
import { SERVICE_TYPE } from 'containers/FPLCommon/constants'
import { B2BBundleKeyboard } from './B2BBundleKeyboard'

export const ManualKeyboard = () => {
  const { ocState } = useContext(MMCCOrderCreationContext)
  const { selectedService } = ocState

  if (selectedService.type === SERVICE_TYPE.B2B_BUNDLE) {
    return <B2BBundleKeyboard />
  }

  return <MMCCKeyboard />
}
