import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Dropdown, message, Row, T, Tooltip } from '@nv/react-commons/src/Components'
import { faGlobe } from '@fa-pro-light/faGlobe'
import { getPartnerDetail } from 'services/api/fplApi'
import {
  ItemButton,
  OrderTypeLabel,
  StyledGreyText,
  StyledLinkWrapper,
  StyledOptionCard,
  StyledOverlayMenu,
  StyledTag,
  StyledTextBlock,
  StyledVerticalBar
} from './styles'
import CircleIcon from 'components/CircleIcon'
import { mpTrackCreatedOrder } from 'containers/FPLLogging/mixpanel'
import { CREATION_SOURCE, PAGE_NAMES } from 'containers/FPLLogging/constants'
import { ROUTES } from 'containers/Base/constants'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'hooks/common'
import { API_RESPONSE_STATUS } from 'containers/FPLRegistration/constants'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { selectCountry, selectIsSS } from 'containers/Base/selectors'
import { connect } from 'react-redux'
import { Text } from 'components/Text'
import Config from 'configs'
import { ORDER_CREATION_TYPE } from 'containers/FPLHome/HomeCard'
import { Menu } from 'antd'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { NoMarginIcon } from 'containers/FPLMMCCOrderCreation/styles'
import { trackCreatedOrder } from 'containers/FPLLogging/rum'

const CardWithTooltip = ({ isDisable, children, onClickLink }) => {
  return isDisable ? (
    <Tooltip title={<T id='international_unavailable_for_lite_account' />} placement='bottom'>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <StyledLinkWrapper onClick={onClickLink}>{children}</StyledLinkWrapper>
  )
}

export const CreateInternationalOrderOptionV2 = ({ shipperId, isSelfServe, country }) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const intl = useIntl()
  const enableMMCCB2BOrderCreation = Config.FPL_ENABLE_MMCC_B2B_ORDER_CREATION
    ? JSON.parse(Config.FPL_ENABLE_MMCC_B2B_ORDER_CREATION)
    : undefined

  const isSelfServeShipper = useMemo(() => {
    if (!isSelfServe) return false

    const selfServeCountries = Config.FPL_AVAILABLE_SELF_SERVE_COUNTRIES?.split(/,\s*/) || []
    return !selfServeCountries.includes(country) && isSelfServe
  }, [country, isSelfServe])

  const getPartner = async () => {
    setIsLoading(true)
    const response = await getPartnerDetail(shipperId)
    if (!response) return null
    return {
      partnerInfo: response.ok ? response.data.data : null,
      status: response.status
    }
  }

  const trackCreateOrder = (pageName, orderType) => {
    mpTrackCreatedOrder({ pageName, source: CREATION_SOURCE.DASH, orderType })
    trackCreatedOrder({ pageName, source: CREATION_SOURCE.DASH, orderType })
  }

  const navigateToE2EOrder = async () => {
    const partnerRes = await getPartner()
    if (!partnerRes) {
      message.error(intl.formatMessage({ id: 'error' }))
    } else {
      let pagePath = null
      switch (partnerRes.status) {
        case API_RESPONSE_STATUS.NOT_FOUND:
          pagePath = ROUTES.FPL_SERVICE_REGISTRATION
          break
        case API_RESPONSE_STATUS.SUCCESS:
          pagePath = ROUTES.FPL_OC
          break
      }
      if (pagePath) {
        navigate(pagePath)
        trackCreateOrder(PAGE_NAMES[pagePath], ORDER_CREATION_TYPE.E2E)
      } else {
        message.error(intl.formatMessage({ id: 'error' }))
      }
    }
    setIsLoading(false)
  }

  const navigateToMMCCOrder = () => {
    navigate(ROUTES.FPL_MMCC_ORDER_CREATION)
    trackCreateOrder(PAGE_NAMES[ROUTES.FPL_MMCC_ORDER_CREATION], ORDER_CREATION_TYPE.B2B)
  }

  const renderCardOption = () => {
    return (
      <StyledOptionCard title={isLoading ? <StyledVerticalBar /> : null} disabled={isSelfServeShipper}>
        <Row type='flex' align='middle'>
          <CircleIcon icon={faGlobe} size='big' disabled={isSelfServeShipper} />
          <StyledTextBlock>
            <StyledTag disabled={isSelfServeShipper}>
              <T id='international_new_feature_tag' />
            </StyledTag>
            <OrderTypeLabel id='create_international_order' disabled={isSelfServeShipper} />
            <StyledGreyText>
              <T id='create_international_order_description' />
            </StyledGreyText>
          </StyledTextBlock>
        </Row>
      </StyledOptionCard>
    )
  }

  const renderCardContentWithoutMMCCOption = () => {
    return (
      <CardWithTooltip isDisable={isSelfServeShipper} onClickLink={navigateToE2EOrder}>
        {renderCardOption()}
      </CardWithTooltip>
    )
  }

  const renderOptions = () => {
    return (
      <StyledOverlayMenu className='menu-option'>
        <Menu.Item key={ORDER_CREATION_TYPE.E2E} onClick={navigateToE2EOrder}>
          <ItemButton>
            <Text id='create_int_b2c_order' style={{ fontWeight: 'bold' }} />
            <ul>
              <li>
                ◦ <T id='e2e_order' />
              </li>
            </ul>
          </ItemButton>
        </Menu.Item>
        <Menu.Item key={ORDER_CREATION_TYPE.B2B} onClick={navigateToMMCCOrder}>
          <ItemButton>
            <Text id='create_int_b2b_order' style={{ fontWeight: 'bold' }} />
            <ul>
              <li>
                ◦ <T id='mmcc_order_type' />
              </li>
              <li>
                ◦ <T id='b2b_order_type' />
              </li>
            </ul>
          </ItemButton>
        </Menu.Item>
      </StyledOverlayMenu>
    )
  }

  const renderCardContentWithMMCCOption = () => {
    return (
      <Dropdown
        overlay={renderOptions()}
        placement='bottomRight'
        trigger={['hover']}
        getPopupContainer={trigger => {
          const wrapper = trigger.closest('.wrapper-card')
          return wrapper || document.body
        }}
      >
        <StyledOptionCard className='wrapper-card'>
          <Row type='flex' align='middle' justify='space-between'>
            <CircleIcon icon={faGlobe} size='big' />
            <StyledTextBlock>
              <OrderTypeLabel id='create_international_order' />
              <StyledGreyText>
                <T id='create_international_order_description' />
              </StyledGreyText>
            </StyledTextBlock>
            <NoMarginIcon icon={faChevronRight} style={{ fontSize: 10 }} />
          </Row>
        </StyledOptionCard>
      </Dropdown>
    )
  }

  if (enableMMCCB2BOrderCreation && !isSelfServeShipper) {
    return renderCardContentWithMMCCOption()
  }
  return (
    <CardWithTooltip isDisable={isSelfServeShipper} onClickLink={navigateToE2EOrder}>
      {renderCardContentWithoutMMCCOption()}
    </CardWithTooltip>
  )
}

CreateInternationalOrderOptionV2.propTypes = {
  shipperId: PropTypes.number
}

const mapStateToProps = createStructuredSelector({
  isSelfServe: selectIsSS(),
  country: selectCountry()
})

const withConnect = connect(mapStateToProps, null)

const CreateInternationalOrderOption = compose(withConnect)(CreateInternationalOrderOptionV2)

export { CreateInternationalOrderOption as CreateInternationalOrderButtonV2 }
