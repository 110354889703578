import countryInfo from './countries.json'

const calculateResponseTime = () => {
  let startTime

  const startCounting = () => {
    startTime = new Date()
  }

  const getTime = () => {
    return new Date() - startTime
  }

  const reset = () => {
    startTime = undefined
  }

  return { startCounting, getTime, reset }
}

export { calculateResponseTime }
