import React, { useEffect, useState, useCallback } from 'react'
import io from 'socket.io-client'
import { Modal } from 'components/Modal'
import { Row, T } from '@nv/react-commons/src/Components'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'themes'
import { Vspace } from 'components/Vspace'
import { Hspace } from 'components/Hspace'
import { Link } from 'components/Link'
import { CenterText } from 'components/CenterText'
import { handleStartPrinting } from 'containers/FPLThermalPrinter/service'
import Config from 'configs'
import { PRINT_AWB_MODAL } from 'containers/FPLOrderRequestList/constants'
import { faExternalLink } from '@fa-pro-solid/faExternalLink'
import { PRINTED_STATUS } from './constants'
import { mpTrackPrintedAirwayBill } from 'containers/FPLLogging/mixpanel'
import { PAGE_NAMES, TRACK_STARTED_INTL_ORDER_CREATION } from 'containers/FPLLogging/constants'
import { ROUTES } from 'containers/Base/constants'
import { trackPrintedAirwayBill } from 'containers/FPLLogging/rum'

const FormatOption = styled.div`
  border-radius: 5px;
  border: solid 2px ${Colors.alto};
  width: 268px;
  height: 160px;
  cursor: pointer;
  margin-bottom: 12px;
  &:hover div {
    color: ${Colors.nvPriRed};
  }
`
const VFormatOption = styled(FormatOption)`
  ${({ disabled }) => {
    return `
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      opacity: ${disabled ? 0.7 : 1};
      background: ${disabled ? Colors.lightPeriwinkle : 'transparent'};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      &:hover > div {
        color: ${Colors.warmGrey}
      }
    `
  }}
`
const StyledCenterText = styled(CenterText)`
  font-weight: 700;
`
const BillBoxContainer = styled.div`
  padding: 5px 5px;
`
const BillBox = styled.div`
  ${({ half, quarter }) => {
    if (half) {
      return 'width: auto; height: 1.4em;'
    }
    if (quarter) {
      return 'width: 1.1em; height: 1.4em;'
    }
  }};
  border-radius: 3px;
  background-color: ${Colors.whiteFour};
`
const GreyBox = styled.div`
  width: 3.4em;
  height: 4em;
  border-radius: 3px;
  border: 2px solid ${Colors.warmGrey};
  background-color: ${Colors.white};
  margin: auto;
`

const PrimaryLink = styled(Link)`
  color: ${({ linkColor }) => linkColor};
`

const initialState = {
  hasInitialized: false,
  printerSocket: null,
  printerConnected: false
}

export const PrintFormatModal = ({ visible, config, onSelectAwbFormat, onCancel, orders }) => {
  const [state, setState] = useState(initialState)

  const { hasInitialized, printerSocket, printerConnected } = state

  let delayTimeout = null

  useEffect(() => {
    const init = async () => {
      const socket = await io(Config.PRINTER_LOCAL_URL)
      setState(prevState => ({ ...prevState, hasInitialized: true, printerSocket: socket, printerConnected: true }))
    }
    if (!hasInitialized) {
      init()
    }
  }, [])

  const handleConnectPrinter = () => {
    setState(prevState => ({ ...prevState, printerConnected: true }))
  }

  const handleDisconnectPrinter = () => {
    setState(prevState => ({ ...prevState, printerConnected: false }))
  }

  useEffect(() => {
    if (printerSocket) {
      printerSocket.on('connect', handleConnectPrinter)
      printerSocket.on('disconnect', handleDisconnectPrinter)
    }
  }, [orders, printerSocket])

  useEffect(() => {
    return () => {
      if (delayTimeout) {
        clearTimeout(delayTimeout)
      }
    }
  }, [])

  const renderBillIllustration = option => {
    let bills = null
    switch (option) {
      case 1:
        bills = (
          <BillBoxContainer>
            <BillBox half />
          </BillBoxContainer>
        )
        break
      case 2:
        bills = (
          <BillBoxContainer>
            <BillBox half />
            <Vspace height={2} />
            <BillBox half />
          </BillBoxContainer>
        )
        break
      case 4:
        bills = (
          <BillBoxContainer>
            <Row type='flex' justify='center'>
              <BillBox quarter />
              <Hspace width={2} />
              <BillBox quarter />
            </Row>
            <Vspace height={2} />
            <Row type='flex' justify='center'>
              <BillBox quarter />
              <Hspace width={2} />
              <BillBox quarter />
            </Row>
          </BillBoxContainer>
        )
        break
    }
    return <GreyBox>{bills}</GreyBox>
  }

  const renderOptionContent = billsPerPage => {
    return (
      <FormatOption
        data-analyticsid='selectAWBLayout'
        key={billsPerPage}
        onClick={() => {
          onSelectAwbFormat(billsPerPage)
        }}
      >
        <Vspace height={22} />
        {renderBillIllustration(billsPerPage)}
        <Vspace height={10} />
        <CenterText color={Colors.warmGrey} size={16}>
          <T id='x_bills_per_page' values={{ x: billsPerPage }} />
        </CenterText>
        <Vspace height={8} />
        <StyledCenterText color={Colors.pinkishGrey} size={12} type='italic'>
          <T id={(billsPerPage <= 2 && 'a4_a5_recommended') || 'a4_recommended'} />
        </StyledCenterText>
      </FormatOption>
    )
  }

  const handleClickThermalPrint = useCallback(() => {
    handleStartPrinting(orders, printerSocket)
    mpTrackPrintedAirwayBill({
      status: PRINTED_STATUS.SUCCESS,
      source: TRACK_STARTED_INTL_ORDER_CREATION.PROPS.SOURCE,
      printedOrder: orders?.length,
      pageName: PAGE_NAMES[ROUTES.FPL_OC_REQUEST_BY_BATCH]
    })
    trackPrintedAirwayBill({
      status: PRINTED_STATUS.SUCCESS,
      source: TRACK_STARTED_INTL_ORDER_CREATION.PROPS.SOURCE,
      printedOrder: orders?.length,
      pageName: PAGE_NAMES[ROUTES.FPL_OC_REQUEST_BY_BATCH]
    })
    if (printerConnected && printerSocket?.id) {
      clearTimeout(delayTimeout)
      delayTimeout = setTimeout(() => {
        onCancel()
      }, PRINT_AWB_MODAL.DISABLE_DELAY_TIME)
    }
  }, [orders])

  const hanleDownloadPrinterApp = useCallback(
    e => {
      mpTrackPrintedAirwayBill({
        status: PRINTED_STATUS.ERROR,
        source: TRACK_STARTED_INTL_ORDER_CREATION.PROPS.SOURCE,
        printedOrder: orders?.length,
        pageName: PAGE_NAMES[ROUTES.FPL_OC_REQUEST_BY_BATCH]
      })
    },
    [orders]
  )

  const renderThermalPrinter = () => {
    return (
      <VFormatOption
        data-analyticsid='internationalSelectThermalPrinterFormat'
        onClick={handleClickThermalPrint}
        disabled={!printerConnected || !printerSocket?.id}
      >
        <CenterText id='thermal_printer_format' color={Colors.warmGrey} size={16} />
        <Vspace height={8} />
        <StyledCenterText id='a_cm_x_b_cm' values={{ a: 7, b: 5 }} color={Colors.pinkishGrey} size={12} type='italic' />
        <Vspace height={8} />
        {(!printerConnected || !printerSocket?.id) && (
          <>
            <StyledCenterText size={12} color={Colors.pinkishGrey} id='international_offline_printer' />
            <PrimaryLink
              onClick={hanleDownloadPrinterApp}
              href={Config.LABEL_PRINTER_INSTALLER_APP_LOCATION}
              linkColor={Colors.cherryRed}
              rightIcon={faExternalLink}
              target='_blank'
            >
              <T id='download_printer_app' />
            </PrimaryLink>
          </>
        )}
      </VFormatOption>
    )
  }

  return (
    <Modal title={<T id='print_airway_bills' />} visible={visible} width={608} onCancel={onCancel}>
      <Row type='flex' justify='space-between'>
        {config.map(({ billsPerPage }) => renderOptionContent(billsPerPage))}
        {renderThermalPrinter()}
      </Row>
    </Modal>
  )
}

PrintFormatModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSelectAwbFormat: PropTypes.func,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      billsPerPage: PropTypes.number
    })
  ).isRequired,
  orders: PropTypes.array
}

PrintFormatModal.defaultProps = {
  visible: false,
  config: [{ billsPerPage: 1 }, { billsPerPage: 2 }, { billsPerPage: 4 }]
}
