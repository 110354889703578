import { ORDER_REQUEST_STATUS } from '../FPLOrderRequestList/constants'
import moment from 'moment'
import { INTERVAL_LIMIT_TIME } from './constants'

export const countOrdersByStatus = orders => {
  const orderInfo = {
    totalCreated: 0,
    totalProcessing: 0,
    totalNeededActions: 0,
    totalOrders: orders.length,
    totalCodOrders: 0,
    totalCodAmount: 0,
    isReachLimitTime: false,
    customsCurrency: ''
  }
  if (!Array.isArray(orders)) {
    return orderInfo
  }

  orders.forEach(item => {
    const statusOrder = item?.status
    const cashOnDelivery = item?.request_body?.delivery?.cash_on_delivery ?? 0
    const createdAt = item?.created_at

    if (!orderInfo.isReachLimitTime) {
      if (moment(new Date()).diff(moment(createdAt)) >= INTERVAL_LIMIT_TIME) {
        orderInfo.isReachLimitTime = true
      }
    }

    switch (statusOrder) {
      case ORDER_REQUEST_STATUS.CREATED:
      case ORDER_REQUEST_STATUS.DELETED:
        orderInfo.totalCreated += 1
        break
      case ORDER_REQUEST_STATUS.PROCESSING:
        orderInfo.totalProcessing += 1
        break
      case ORDER_REQUEST_STATUS.SERVER_ERROR:
      case ORDER_REQUEST_STATUS.INVALID:
        orderInfo.totalNeededActions += 1
        break
    }

    if (cashOnDelivery > 0) {
      orderInfo.totalCodOrders += 1
    }
    orderInfo.totalCodAmount += cashOnDelivery
    orderInfo.customsCurrency = item?.request_body?.parcel_details?.customs_currency ?? ''
  })

  return orderInfo
}
