import React, { useState, useMemo, useEffect } from 'react'
import { useIntl } from 'hooks/common'
import { Tooltip } from 'antd'
import { NVModal } from '@nv/react-commons/src/Components'
import { OCSubStep } from 'components/OCSubStep'
import { Preview } from 'components/Preview'
import { faShippingTimed } from '@fa-pro-light/faShippingTimed'
import { INTL_KEY_BY_SERVICE_TYPE } from 'containers/FPLCommon/constants'
import { Service, ServiceSelectionProps } from './types'
import {
  StyledGroupSeparator,
  StyledRadio,
  StyledRadioGroup,
  StyledServiceDescription,
  StyledServiceRow,
  StyledCountryFlow,
  StyledArrow,
  StyledCountryCell,
  StyledServiceGroup,
  StyledSeparator,
  StyledServiceType,
  StyledServiceDetails,
  StyleText
} from './styles'
import { getCountryInfo, getSortedServices } from './dataUtils'

interface GroupedServices {
  [key: string]: Service[]
}

export const ServiceSelection = ({
  defaultService,
  services,
  status,
  onCancel,
  onEdit,
  onSave
}: ServiceSelectionProps) => {
  const intl = useIntl()
  const [currentService, setCurrentService] = useState<Service>(defaultService)

  const { groupedServices, maxWidths } = useMemo(() => {
    const sortedServices = getSortedServices(services)
    const maxWidths = {
      origin: 0,
      destination: 0,
      serviceType: 0
    }

    const grouped = sortedServices.reduce<GroupedServices>((acc, service) => {
      const groupKey =
        service.origin_country === 'XX' ? 'XX' : getCountryInfo(service.origin_country).name || service.origin_country

      if (!acc[groupKey]) {
        acc[groupKey] = []
      }

      const originName = service.origin_country ? getCountryInfo(service.origin_country).name || '' : ''
      const destName = getCountryInfo(service.destination_country).name || ''
      const serviceTypeName = intl.formatMessage({ id: INTL_KEY_BY_SERVICE_TYPE[service.type] })

      maxWidths.origin = Math.max(maxWidths.origin, originName.length)
      maxWidths.destination = Math.max(maxWidths.destination, destName.length)
      maxWidths.serviceType = Math.max(maxWidths.serviceType, serviceTypeName.length)

      acc[groupKey].push(service)
      return acc
    }, {})
    return { groupedServices: grouped, maxWidths }
  }, [services])

  const renderServiceGroup = (originCountry: string, groupServices: Service[], isLastGroup: boolean) => {
    return (
      <StyledServiceGroup
        key={originCountry}
        data-testid={`service-group-${originCountry}`}
        style={{ marginBottom: isLastGroup ? 12 : 0 }}
      >
        {groupServices.map((service, index) => renderServiceItem(service, index))}
        {!isLastGroup && <StyledGroupSeparator />}
      </StyledServiceGroup>
    )
  }

  const truncateText = (text: string, maxLength: number = 18) => {
    if (!text) return ''
    const characters = [...text]
    if (characters.length > maxLength) {
      return characters.slice(0, maxLength).join('') + '...'
    }
    return text
  }

  const renderServiceItem = (service: Service, index: number) => {
    const friendlyOriginCountry = service.origin_country ? getCountryInfo(service.origin_country).name : ''
    const friendlyDestinationCountry = getCountryInfo(service.destination_country).name || ''
    let customWidth = Math.min(36, maxWidths.serviceType * 10)
    if (maxWidths.serviceType === 4) {
      customWidth = Math.max(46, maxWidths.serviceType * 8)
    }
    if (maxWidths.serviceType > 5) {
      customWidth = Math.max(80, maxWidths.serviceType * 8)
    }
    if (maxWidths.serviceType > 10) {
      customWidth = Math.max(150, maxWidths.serviceType * 8)
    }
    return (
      <StyledRadio key={service.id} value={service.code} data-testid={index}>
        <StyledServiceRow>
          <StyledCountryFlow $maxOriginWidth={maxWidths.origin} $maxDestWidth={maxWidths.destination}>
            <StyledCountryCell>{friendlyOriginCountry}</StyledCountryCell>
            <StyledArrow>{friendlyOriginCountry ? '→' : ''}</StyledArrow>
            <StyledCountryCell>{friendlyDestinationCountry}</StyledCountryCell>
          </StyledCountryFlow>
          <StyledSeparator>|</StyledSeparator>
          <StyledServiceType $maxWidth={customWidth}>
            {intl.formatMessage({ id: INTL_KEY_BY_SERVICE_TYPE[service.type] })}
          </StyledServiceType>
          <StyledSeparator>|</StyledSeparator>
          <StyledServiceDetails>
            <Tooltip title={service.description} placement='top'>
              {truncateText(service.description)}
            </Tooltip>
            <StyleText>- ({service.code})</StyleText>
          </StyledServiceDetails>
        </StyledServiceRow>
      </StyledRadio>
    )
  }

  const save = () => {
    const modalProps = {
      title: intl.formatMessage({ id: 'warning' }),
      content: intl.formatMessage({ id: 'international_reselect_service_code_content' }),
      okText: intl.formatMessage({ id: 'ok_uppercase' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      onOk () {
        onSave(currentService)
      }
    }

    NVModal.confirm(modalProps)
  }

  const cancelEditService = () => {
    setCurrentService(defaultService)
    onCancel()
  }

  return (
    <OCSubStep
      status={status}
      title={intl.formatMessage({ id: 'service_type_label' })}
      icon={faShippingTimed}
      renderActive={() => {
        return (
          <>
            <Preview
              title={intl.formatMessage({ id: 'choose_service_type' })}
              description={services.length > 0 ? '' : intl.formatMessage({ id: 'no_service' })}
            />
            {services.length > 0 && (
              <StyledRadioGroup
                onChange={e => {
                  setCurrentService(services.find(item => item.code === e.target.value))
                }}
                value={currentService.code}
              >
                {Object.entries(groupedServices)
                  .sort(([keyA], [keyB]) => {
                    if (keyA === 'XX') return 1
                    if (keyB === 'XX') return -1
                    return keyA.localeCompare(keyB)
                  })
                  .map(([originCountry, groupServices], index, array) =>
                    renderServiceGroup(originCountry, groupServices, index === array.length - 1)
                  )}
              </StyledRadioGroup>
            )}
          </>
        )
      }}
      renderPreview={() => {
        return (
          <>
            <StyledServiceDescription>{getServiceDescription(currentService, intl)}</StyledServiceDescription>
          </>
        )
      }}
      onSave={save}
      onEdit={onEdit}
      onCancel={cancelEditService}
    />
  )
}

export const getServiceDescription = (service: Service, intl): React.ReactNode => {
  const originName = service.origin_country ? getCountryInfo(service.origin_country).name || '' : ''
  const destName = getCountryInfo(service.destination_country).name || ''
  const serviceTypeName = intl.formatMessage({ id: INTL_KEY_BY_SERVICE_TYPE[service.type] })

  // Format: Origin → Destination | Service Type | Description (Code)
  if (originName) {
    return (
      <>
        <span>{`${originName} → ${destName} | ${serviceTypeName} | ${service.description} |`}</span>
        <StyleText>({service.code})</StyleText>
      </>
    )
  }
  // Format: Destination | Service Type | Description (Code) in case origin is XX
  return (
    <>
      <span>{`${destName} | ${serviceTypeName} | ${service.description} |`}</span>
      <StyleText>({service.code})</StyleText>
    </>
  )
}
