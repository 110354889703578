import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Row, T, Tooltip } from '@nv/react-commons/src/Components'
import { StyledCustomCard } from 'containers/FPLOrderCreate/styles'
import { StyleConfirmdButton } from './styles'
import { useIntl } from 'hooks/common'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import {
  buildB2BBoxRequests,
  buildB2CBoxRequests,
  buildBoxesForB2BOrders,
  isValidB2BForm,
  isValidBundle,
  KeyboardBundleFormatter,
  UploadBundleFormatter
} from './utils'
import { b2bBundleOC, batchB2COrders, batchOC } from 'services/api/fplApi'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'containers/Base/constants'
import { isEmpty } from 'lodash'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { SUPPORTED_DOCUMENT_STATUS } from './constants'
import { message } from 'antd'
import { mpTrackConfirmTheOrder } from 'containers/FPLLogging/mixpanel'
import { PAGE_NAMES } from 'containers/FPLLogging/constants'
import { calculateResponseTime } from 'containers/FPLOrderCreate/utils'
import { B2BBundleRequestParams, B2BBundleOrderFormGroup, OCMethodHandler, BoxOrderByColumnName } from './types'
import {
  SERVICE_TYPE,
  SERVICE_TYPE_LABEL_IN_MIXPANEL,
  CREATION_METHODS,
  OCMethod,
  GLOBAL_COUNTRY_CODE
} from 'containers/FPLCommon/constants'
import { trackConfirmTheOrder } from 'containers/FPLLogging/rum'

const initialState = {
  batchError: false,
  batchMessage: '',
  isLoading: false
}

const ReviewStep = () => {
  const intl = useIntl()
  const [state, setState] = useState(initialState)
  const { isLoading } = state
  const { ocState } = useContext(MMCCOrderCreationContext)

  const {
    boxOrders,
    senderAddress,
    pickupType,
    pickupAddress,
    selectedService,
    timeSlot,
    commercialFiles,
    additionalDocumentFiles,
    commercialStatus,
    additionalDocumentUploadStatus,
    allOrders,
    invalidOrders,
    validOrders,
    ocMethod,
    mmccB2BFormGroup,
    customsCurrency,
    b2bBundleFormGroup,
    activeGroupId,
    destinationCountryCode
  } = ocState

  const navigate = useNavigate()
  const { startCounting, getTime } = calculateResponseTime()

  const { destination_country: destinationCountry } = selectedService

  const allValidBundles = useMemo(() => {
    if (selectedService.type === SERVICE_TYPE.B2B_BUNDLE) {
      const currentBundleEditing = b2bBundleFormGroup[activeGroupId]
      const inactiveBundles = []
      Object.values(b2bBundleFormGroup).forEach(bundle => {
        if (bundle.id !== activeGroupId) {
          inactiveBundles.push(bundle)
        }
      })
      return isValidBundle(currentBundleEditing, destinationCountry, inactiveBundles)
    }
    return true
  }, [activeGroupId, b2bBundleFormGroup, selectedService])

  const allValidB2BData = useMemo(() => {
    if (selectedService.type === SERVICE_TYPE.MMCCB2B) {
      const editingB2BForm = mmccB2BFormGroup[activeGroupId]
      const destCountry =
        destinationCountryCode === GLOBAL_COUNTRY_CODE ? editingB2BForm.address?.toCountry : destinationCountry
      return isValidB2BForm(editingB2BForm, destCountry)
    }
    return true
  }, [mmccB2BFormGroup, selectedService, activeGroupId])

  const isRequiredFieldsFilled = useMemo(() => {
    const isAddressFilled = !isEmpty(senderAddress)
    const isServiceFilled = !isEmpty(selectedService)

    let isBagsFilled = false
    switch (selectedService.type) {
      case SERVICE_TYPE.MMCCB2B:
        isBagsFilled = ocMethod === OCMethod.KEYBOARD ? allValidB2BData : boxOrders.length > 0
        break
      case SERVICE_TYPE.MMCCB2C:
        isBagsFilled = boxOrders.length > 0
        break
      default:
        // B2B Bundle
        isBagsFilled = ocMethod === OCMethod.KEYBOARD ? allValidBundles : boxOrders.length > 0
        break
    }

    const isPickupInfoFilled = pickupType !== PICKUP_TYPES.SCHEDULED || (!isEmpty(timeSlot) && !isEmpty(pickupAddress))
    const isSupportingDocsFilled =
      commercialStatus !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL &&
      additionalDocumentUploadStatus !== SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL
    return isAddressFilled && isServiceFilled && isPickupInfoFilled && isBagsFilled && isSupportingDocsFilled
  }, [
    senderAddress,
    selectedService,
    pickupType,
    boxOrders.length,
    timeSlot,
    pickupAddress,
    commercialStatus,
    additionalDocumentUploadStatus,
    allValidBundles,
    allValidB2BData
  ])

  const showError = (error, validationErrors) => {
    let msg = error?.message
    if (validationErrors) {
      const errorMessages = Object.values(validationErrors).join(', ')
      msg = `${msg}: ${errorMessages}`
    }
    setState(prevState => ({ ...prevState, batchError: true, batchMessage: msg, isLoading: false }))
    message.error(msg, 10)
  }

  const navigateToBatchOrder = (batchId, responseTime, orderType) => {
    navigate(`${ROUTES.FPL_MMCC_ORDER_BATCH}?id=${batchId}`, {
      state: {
        responseTime,
        orderType
      }
    })
  }

  const trackConfirmOrder = (
    totalCreated: number,
    totalNeededActions: number,
    totalOrders: number,
    orderType: string
  ) => {
    const trackingData = {
      totalCreated,
      totalNeededActions,
      totalOrders,
      inputMethod:
        ocMethod === OCMethod.UPLOAD ? CREATION_METHODS[OCMethod.UPLOAD] : CREATION_METHODS[OCMethod.KEYBOARD],
      pageName: PAGE_NAMES[ROUTES.FPL_MMCC_ORDER_CREATION],
      orderType: orderType
    }
    mpTrackConfirmTheOrder(trackingData)
    trackConfirmTheOrder(trackingData)
  }

  const submitB2BOrders = async () => {
    let boxSources = []
    if (ocMethod === OCMethod.KEYBOARD) {
      Object.values(mmccB2BFormGroup).forEach(group => {
        const destCountry =
          destinationCountryCode === GLOBAL_COUNTRY_CODE ? group.address?.toCountry : destinationCountry
        const boxesInRecipientGroup = buildBoxesForB2BOrders(Object.values(group.b2bBoxes), group.address, destCountry)
        boxSources = [...boxSources, ...boxesInRecipientGroup]
      })
    }
    if (ocMethod === OCMethod.UPLOAD) {
      boxSources = [...boxOrders]
    }
    const totalCreated = boxSources.length
    const totalNeededActions = ocMethod === OCMethod.UPLOAD ? Object.keys(allOrders).length - boxSources.length : 0
    const totalOrders = ocMethod === OCMethod.UPLOAD ? Object.keys(allOrders).length : boxSources.length
    trackConfirmOrder(
      totalCreated,
      totalNeededActions,
      totalOrders,
      SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    )
    const b2bPayload = buildB2BBoxRequests({
      boxOrders: boxSources,
      senderAddress,
      pickupType,
      pickupAddress,
      timeSlot,
      ocMethod: ocMethod === OCMethod.UPLOAD ? OCMethod.UPLOAD : OCMethod.KEYBOARD,
      selectedService,
      commercialFiles,
      additionalDocumentFiles,
      currency: customsCurrency
    })
    const res = await batchOC(b2bPayload)
    const responseTime = getTime()
    if (res.ok) {
      const batchId = res?.data?.data?.batch_id
      navigateToBatchOrder(batchId, responseTime, SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type])
    } else {
      showError(res.data?.error, res.data?.error?.validation_errors)
    }
  }

  const submitB2COrders = async () => {
    trackConfirmOrder(
      boxOrders.length,
      Object.keys(invalidOrders).length,
      Object.keys(invalidOrders).length + boxOrders.length,
      SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    )
    const b2cPayload = buildB2CBoxRequests({
      boxOrders,
      senderAddress,
      pickupType,
      pickupAddress,
      timeSlot,
      ocMethod: OCMethod.UPLOAD, // Create B2C use Bulk Upload only
      selectedService,
      commercialFiles,
      additionalDocumentFiles,
      currency: customsCurrency
    })
    const res = await batchB2COrders(b2cPayload)
    const responseTime = getTime()
    if (res.ok) {
      const batchId = res?.data?.data?.batch_id
      navigateToBatchOrder(batchId, responseTime, SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type])
    } else {
      showError(res.data?.error, res.data?.error?.validation_errors)
    }
  }

  const submitB2BBundleOrders = async () => {
    const ocMethodHandlers: Record<OCMethod, OCMethodHandler> = {
      [OCMethod.KEYBOARD]: {
        type: OCMethod.KEYBOARD,
        formatter: KeyboardBundleFormatter
      },
      [OCMethod.UPLOAD]: {
        type: OCMethod.UPLOAD,
        formatter: UploadBundleFormatter
      }
    }

    const handler = ocMethodHandlers[ocMethod]
    const bundles =
      ocMethod === OCMethod.KEYBOARD
        ? (Object.values(b2bBundleFormGroup) as B2BBundleOrderFormGroup[])
        : (Object.values(validOrders) as BoxOrderByColumnName[])

    trackConfirmOrder(
      bundles.length,
      ocMethod === OCMethod.KEYBOARD ? 0 : Object.keys(invalidOrders).length,
      ocMethod === OCMethod.KEYBOARD ? bundles.length : Object.keys(allOrders).length,
      SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    )

    const requestParams: B2BBundleRequestParams = {
      bundles,
      senderAddress,
      pickupType,
      pickupAddress,
      timeSlot,
      selectedService,
      commercialFiles,
      additionalDocumentFiles,
      currency: customsCurrency
    }

    try {
      const b2bBundlePayload = handler.formatter.formatBundleData(requestParams)
      const res = await b2bBundleOC(b2bBundlePayload)
      const responseTime = getTime()

      if (res.ok) {
        const batchId = res?.data?.data?.batch_id
        navigateToBatchOrder(batchId, responseTime, SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type])
      } else {
        showError(res.data?.error, res.data?.error?.validation_errors)
      }
    } catch (error) {
      showError(error, null)
    }
  }

  const handleClickConfirm = () => {
    setState(prevState => ({ ...prevState, isLoading: true }))
    startCounting()
    switch (selectedService.type) {
      case SERVICE_TYPE.MMCCB2B:
        submitB2BOrders()
        break
      case SERVICE_TYPE.MMCCB2C:
        submitB2COrders()
        break
      default:
        // B2B Bundle
        submitB2BBundleOrders()
        break
    }
  }

  const renderConfirmButton = useCallback(() => {
    if (!isRequiredFieldsFilled) {
      return (
        <Tooltip
          title={intl.formatMessage({
            id: 'international_check_and_fill_required_fields'
          })}
        >
          <StyleConfirmdButton
            disabled
            type='primary'
            onClick={handleClickConfirm}
            loading={isLoading}
            data-analyticsid='create4PLOrders'
          >
            <T id='confirm' />
          </StyleConfirmdButton>
        </Tooltip>
      )
    }

    return (
      <StyleConfirmdButton
        type='primary'
        onClick={handleClickConfirm}
        loading={isLoading}
        data-analyticsid='createMMCCOrders'
      >
        <T id='confirm' />
      </StyleConfirmdButton>
    )
  }, [handleClickConfirm, intl, isRequiredFieldsFilled, isLoading])

  return (
    <>
      <StyledCustomCard hoverable={false}>
        <Row type='flex' justify='space-between' align='middle'>
          <T id='confirm_order' />
          {renderConfirmButton()}
        </Row>
      </StyledCustomCard>
    </>
  )
}

export { ReviewStep }
