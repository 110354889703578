import React, { useEffect, useMemo, useState } from 'react'
import { memoize, uniq } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CenterText } from 'components/CenterText'
import { MiscDropdown } from 'components/MiscDropdown'
import { Hspace } from 'components/Hspace'
import { Text } from 'components/Text'
import { Tag } from 'components/Tag'
import { DashTable } from 'components/DashTable'
import { Button } from 'components/Button'
import { Vspace } from 'components/Vspace'
import { faList } from '@fa-pro-regular/faList'
import { faArrowLeft } from '@fa-pro-regular/faArrowLeft'
import { faPrint } from '@fa-pro-regular/faPrint'
import { faArrowDown } from '@fa-pro-light/faArrowDown'
import { faSpinner } from '@fa-pro-light/faSpinner'
import { ActionsButton } from 'containers/FPLBatchHistory/ActionButton'
import { Dropdown, Menu, message, Row, T } from '@nv/react-commons/src/Components'
import { Colors, Fonts } from 'themes'
import { saveAs } from 'file-saver'
import {
  errorMessage,
  fetchFile,
  getResponseError,
  mappingOrderPrinters,
  retrieveOrderRequest
} from 'containers/FPLOrderRequestList/dataUtils'
import {
  ACTION_MENU_LABEL,
  NAVIGATION_BUTTON_TYPES,
  ORDER_REQUEST_HEADER_FIELDS,
  ORDER_REQUEST_STATUS,
  ORDER_REQUEST_STATUS_COLOR,
  ORDER_REQUEST_STATUS_LABEL,
  ORDER_REQUEST_STATUS_MENU,
  PRINT_AWB_MODAL,
  SUPPORTING_DOC_TYPE,
  SUPPORTING_DOC_TYPE_NAME
} from './constants'
import { PrintAwbModal } from 'containers/FPLOrderRequestList/PrintAwbModal'
import { StyledButton, StyledToolTip, TableHeaderText } from 'containers/FPLOrderRequestList/styles'
import { useIntl } from 'hooks/common'
import { TRANSLATION_KEY_BY_TYPE, TYPE, VENDOR_STAGE } from '../FPLOrderDetails/constants'
import JSZip from 'jszip'
import { getDocsByBatchOrderRequestId } from '../../services/api/fplApi'
import { FileUtils } from '@nv/react-commons/src/Utils'
import { ROUTES } from '../Base/constants'
import { useNavigate } from 'react-router-dom'

const TableWrapper = styled.div`
  flex: 1;

  && {
    .ReactVirtualized__Table__headerColumn {
      &:nth-child(2) {
        min-width: 210px;
      }
    }

    .ReactVirtualized__Table__rowColumn {
      &:nth-child(2) {
        min-width: 210px;
      }
    }
  }
`
const CellText = styled(Text).attrs({
  showTooltip: true,
  placement: 'topLeft'
})`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const StyledFilter = styled(Row)`
  && {
    background: white;
    padding-left: 16px;
    padding-right: 16px;
    z-index: 100;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    border-top: solid 1px ${Colors.whiteTree};
    flex-wrap: nowrap;
    flex-shrink: 0;
    flex-basis: 48px;
  }
`
const StyleTitle = styled(Text)`
  font-size: 16px;
  ${Fonts.style.bold};
  display: inline;
  padding: 16px 24px;
`
const StyledTag = styled(Tag)`
  color: white;
  text-align: center;
  min-width: 94px;
`
export const StyledDropdownButton = styled(Button)`
  && {
    min-width: 182px;
  }
`
const StyledRow = styled(Row)`
  padding-right: 24px;
`
const renderActionMenu = (menus, rowData) => (
  <Menu onClick={({ key }) => menus.find(menu => menu.key === key).action(rowData)}>
    {menus.map(({ key, label }) => (
      <Menu.Item key={key}>
        <T id={label} />
      </Menu.Item>
    ))}
  </Menu>
)

const TooltipContent = ({ dataKey, rowData }) => {
  let value = ''
  switch (dataKey) {
    case 'request_body.parcel_details.customs_description':
      value = rowData.request_body?.parcel_details?.customs_description
      break
    case 'request_body.delivery.cash_on_delivery':
      value = rowData.request_body?.delivery?.cash_on_delivery
      break
    case 'request_body.to.name':
      value = rowData.request_body?.to?.name
      break
    case 'request_body.source_order_id':
      value = rowData.request_body?.source_order_id
      break
    default:
      value = rowData[dataKey]
  }
  return <CellText placement='topLeft'>{value}</CellText>
}

const TrackingIdTooltip = ({ rowData }) => {
  const intl = useIntl()
  const reqTrackingId = rowData?.request_body?.requested_tracking_id ?? ''
  const resTrackingId = rowData?.response_body?.data?.tracking_id ?? ''
  const trackingId = `${resTrackingId}${reqTrackingId && resTrackingId ? ' - ' : ''}${reqTrackingId}`
  const indent = reqTrackingId && resTrackingId ? '\n' : ''
  const reqTIDContent = reqTrackingId ? `${intl.formatMessage({ id: 'requested_tracking_id' })}: ${reqTrackingId}` : ''
  const actualTrackingID = resTrackingId ? `${intl.formatMessage({ id: 'actual_tracking_id' })}: ${resTrackingId}` : ''
  const trackingIdHover = `${actualTrackingID}${indent}${reqTIDContent}`

  if (!trackingId.length) return null

  return (
    <StyledToolTip overlayStyle={{ whiteSpace: 'pre-line' }} title={trackingIdHover}>
      {trackingId}
    </StyledToolTip>
  )
}

const RemarkContent = ({ dataKey, rowData }) => {
  const intl = useIntl()
  const value = rowData?.[dataKey]
  const { validationErrors } = getResponseError(value)
  let errMessage = errorMessage(validationErrors, intl)
  if (errMessage.includes('\n')) {
    errMessage = uniq(errMessage.split('\n')).join('\n')
  }
  return (
    <StyledToolTip overlayStyle={{ whiteSpace: 'pre-line' }} title={errMessage}>
      {errMessage}
    </StyledToolTip>
  )
}
const AddressContent = ({ dataKey, rowData }) => {
  const addressData = rowData.request_body.to
  if (!addressData) return null
  const { address_line1, address_line2, address_line3, address_line4 } = addressData
  return (
    <CellText>
      {address_line1}
      {address_line2 && ` | ${address_line2}`}
      {address_line3 && ` | ${address_line3}`}
      {address_line4 && ` | ${address_line4}`}
    </CellText>
  )
}
const GranularStatus = ({ dataKey, rowData }) => {
  const value = rowData?.[dataKey]
  const label = ORDER_REQUEST_STATUS_LABEL[rowData?.[dataKey]]
  return <StyledTag color={ORDER_REQUEST_STATUS_COLOR[value]}>{label}</StyledTag>
}

const ActionsContent = ({ cellData, rowData, ...otherProps }) => {
  let menus = []
  if (rowData.locked_at) {
    return menus
  }
  let disabledActionMenu = false
  switch (cellData) {
    case ORDER_REQUEST_STATUS.INVALID:
      menus = [
        {
          label: ACTION_MENU_LABEL.FIX,
          key: ACTION_MENU_LABEL.FIX,
          action: otherProps[ACTION_MENU_LABEL.FIX]
        },
        {
          label: ACTION_MENU_LABEL.ROLLBACK,
          key: ACTION_MENU_LABEL.ROLLBACK,
          action: otherProps[ACTION_MENU_LABEL.ROLLBACK]
        }
      ]
      break
    case ORDER_REQUEST_STATUS.SERVER_ERROR:
      menus = [
        {
          label: ACTION_MENU_LABEL.ROLLBACK,
          key: ACTION_MENU_LABEL.ROLLBACK,
          action: otherProps[ACTION_MENU_LABEL.ROLLBACK]
        }
      ]
      break
    case ORDER_REQUEST_STATUS.CREATED:
      menus = [
        {
          label: ACTION_MENU_LABEL.VIEW,
          key: ACTION_MENU_LABEL.VIEW,
          action: otherProps[ACTION_MENU_LABEL.VIEW]
        }
      ]
      disabledActionMenu = true
      break
    case ORDER_REQUEST_STATUS.DELETED:
      menus = []
      break
  }
  return <ActionsButton overlay={renderActionMenu(menus, rowData)} {...otherProps} disabled={disabledActionMenu} />
}

const getColumns = memoize(
  ({ [ACTION_MENU_LABEL.FIX]: onFix, [ACTION_MENU_LABEL.VIEW]: onView, [ACTION_MENU_LABEL.ROLLBACK]: onRollback }) => [
    {
      dataIndex: 'trackingId',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.TRACKING_ID} />
        </TableHeaderText>
      ),
      width: 520,
      render: TrackingIdTooltip
    },
    {
      dataIndex: 'type',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.TYPE} />
        </TableHeaderText>
      ),
      width: 150,
      render: ({ rowData }) => {
        return (
          <CellText placement='topLeft'>
            <T id={TRANSLATION_KEY_BY_TYPE[rowData?.parcel?.type]} />
          </CellText>
        )
      }
    },
    {
      dataIndex: 'request_body.source_order_id',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.SHIPPER_ORDER_NUMBER} />
        </TableHeaderText>
      ),
      width: 300,
      render: TooltipContent
    },
    {
      dataIndex: 'request_body.to.name',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.RECIPIENT_NAME} />
        </TableHeaderText>
      ),
      width: 200,
      render: TooltipContent
    },
    {
      dataIndex: 'request_body.to',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.ADDRESS} />
        </TableHeaderText>
      ),
      width: 200,
      render: AddressContent
    },
    {
      dataIndex: 'request_body.delivery.cash_on_delivery',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.COD} />
        </TableHeaderText>
      ),
      width: 150,
      render: TooltipContent
    },
    {
      dataIndex: 'request_body.parcel_details.customs_description',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.CUSTOMS_DESCRIPTION} />
        </TableHeaderText>
      ),
      width: 180,
      render: TooltipContent
    },
    {
      dataIndex: 'response_body',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.REMARK} />
        </TableHeaderText>
      ),
      width: 200,
      render: RemarkContent
    },
    {
      dataIndex: 'status',
      title: (
        <TableHeaderText>
          <T id={ORDER_REQUEST_HEADER_FIELDS.STATUS} />
        </TableHeaderText>
      ),
      width: 150,
      render: GranularStatus
    },
    {
      dataIndex: 'status',
      title: <TableHeaderText />,
      width: 200,
      render: data =>
        ActionsContent({
          ...data,
          [ACTION_MENU_LABEL.FIX]: onFix,
          [ACTION_MENU_LABEL.VIEW]: onView,
          [ACTION_MENU_LABEL.ROLLBACK]: onRollback
        })
    }
  ]
)

const renderNoRows = () => {
  return (
    <div style={{ position: 'relative' }}>
      <Vspace height={32} />
      <CenterText id='zero_results' size={24} type='bold' />
    </div>
  )
}

const OrderRequestTable = ({
  data,
  onFilterStatus,
  batchId,
  filterStatus,
  getNext,
  fetchingMore,
  onFix,
  onRollback,
  onClickNavigateButton
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [state, setState] = useState({
    printAwbVisible: false,
    selectedKeys: [],
    selectedRows: [],
    disabledPrintAwbBill: false,
    downloading: {},
    supportingDocs: [],
    existDocs: {},
    downloadingOrders: false
  })
  const { printAwbVisible, selectedRows, disabledPrintAwbBill, downloading, supportingDocs, existDocs } = state
  let delayTimeout = null
  const miscFilter = useMemo(() => {
    return { bulkUploadId: batchId }
  }, [batchId])

  const parcelDetail = useMemo(() => {
    const parcelPresentation = selectedRows[0]?.parcel
    let countryVendor = null
    if (parcelPresentation?.vendors?.length) {
      const fmVendor = parcelPresentation.vendors.find(
        vendor => vendor.handler === 'NVCore' && vendor.stage === VENDOR_STAGE.FM
      )
      const lmVendor = parcelPresentation.vendors.find(
        vendor => vendor.handler === 'NVCore' && vendor.stage === VENDOR_STAGE.LM
      )
      countryVendor = fmVendor ? fmVendor.country : lmVendor?.country
    }
    return {
      countryVendor,
      parcelType: parcelPresentation?.type ? parcelPresentation.type : TYPE.PARCEL
    }
  }, [selectedRows])

  useEffect(() => {
    return () => {
      if (delayTimeout) {
        clearTimeout(delayTimeout)
      }
    }
  }, [])

  useEffect(() => {
    const fetchDocs = async () => {
      if (!batchId) {
        return
      }
      const res = await getDocsByBatchOrderRequestId(batchId)
      if (res.ok) {
        const exist = {}
        const docs = res.data.data
        for (const doc of docs) {
          exist[doc.type] = true
        }
        setState(prevState => ({ ...prevState, supportingDocs: docs, existDocs: exist }))
      } else {
        const msg = res.data?.error?.message
        message.error(msg)
      }
    }
    fetchDocs()
  }, [batchId])

  const rowSelection = {
    onChange: (selectedKeys, newSelectedRows) => {
      setState(prevState => ({ ...prevState, selectedKeys, selectedRows: newSelectedRows }))
    }
  }

  const handleClickStatusMenu = ({ key }) => {
    onFilterStatus(ORDER_REQUEST_STATUS_MENU.find(item => item.key === +key))
  }

  const renderOrderStatusMenu = () => {
    return (
      <Menu onClick={handleClickStatusMenu}>
        {ORDER_REQUEST_STATUS_MENU.map(item => (
          <Menu.Item key={item.key}>{intl.formatMessage({ id: item.localizeKey })}</Menu.Item>
        ))}
      </Menu>
    )
  }

  const handleClickNavigationButton = type => event => {
    onClickNavigateButton(type)
  }

  const validatePrintAwb = rows => {
    if (rows.length === 0) {
      const msg = intl.formatMessage({ id: PRINT_AWB_MODAL.NO_ITEMS_MESSAGE })
      message.error(msg)
      return false
    }
    if (rows.length > PRINT_AWB_MODAL.LIMIT_ROWS) {
      const msg = intl.formatMessage({ id: PRINT_AWB_MODAL.LIMIT_ROWS_MESSAGE })
      message.error(msg)
      return false
    }
    const invalidRow = rows.find(row => row.status !== ORDER_REQUEST_STATUS.CREATED)
    if (invalidRow) {
      const msg = intl.formatMessage({ id: PRINT_AWB_MODAL.INVALID_STATUS_MESSAGE })
      message.error(msg)
      return false
    }
    return true
  }

  const handleClickPrintAwb = () => {
    setState(prevState => ({ ...prevState, disabledPrintAwbBill: true }))

    if (validatePrintAwb(selectedRows)) {
      setState(prevState => ({ ...prevState, printAwbVisible: true }))
    }

    clearTimeout(delayTimeout)

    delayTimeout = setTimeout(() => {
      setState(prevState => ({ ...prevState, disabledPrintAwbBill: false }))
    }, PRINT_AWB_MODAL.DISABLE_DELAY_TIME)
  }

  const handleCloseAwbModal = () => {
    setState(prevState => ({
      ...prevState,
      printAwbVisible: false
    }))
  }

  const downloadSupportingDocs = type => {
    setState(prevState => ({
      ...prevState,
      downloading: {
        ...prevState.downloading,
        [type]: true
      }
    }))
    const jsZip = new JSZip()
    const docs = supportingDocs.filter(doc => doc.type === type)
    Promise.all(docs.map(doc => fetchFile(doc.file_url)))
      .then(blobs => {
        blobs.forEach((blob, i) => {
          jsZip.file(docs[i].file_name, blob)
        })
        jsZip.generateAsync({ type: 'blob' }).then(function (content) {
          const now = new Date()
          saveAs(content, `${SUPPORTING_DOC_TYPE_NAME[type]}_${now.toISOString()}.zip`)
        })
      })
      .catch(error => {
        message.error(error.message)
      })
      .finally(() =>
        setState(prevState => ({
          ...prevState,
          downloading: {
            ...prevState.downloading,
            [type]: false
          }
        }))
      )
  }

  const downloadLabel = useMemo(
    () => (selectedRows?.length !== data?.length ? 'international_download_x' : 'international_download_all_x'),
    [selectedRows]
  )

  const downloadOrders = () => {
    const { fields, data } = retrieveOrderRequest(selectedRows, intl)
    FileUtils.download(FileUtils.buildCSVURI({ fields, data }), { filename: `${batchId}_batch_orders.csv` })
  }

  const viewOrderRequest = rowData => {
    navigate(`${ROUTES.FPL_PARCEL_DETAIL.split(':')[0]}${rowData.parcel_id}`)
  }

  return (
    <>
      <StyledFilter align='middle' type='flex'>
        <Text size={13} type='bold' id='search_filters' />
        <Hspace width={16} />
        <Dropdown overlay={renderOrderStatusMenu()} trigger={['click']} placement='bottomLeft'>
          <StyledDropdownButton icon={faList} rightIcon='dropdown'>
            <T
              id='order_status'
              values={{ x: `(${intl.formatMessage({ id: ORDER_REQUEST_STATUS_LABEL[filterStatus] })})` }}
            />
          </StyledDropdownButton>
        </Dropdown>
        <MiscDropdown {...miscFilter} />
      </StyledFilter>

      <StyledRow align='middle' type='flex'>
        <StyleTitle>
          <T id='order_request_list' />
        </StyleTitle>
        <Hspace width={16} />
        <div style={{ flex: 1 }} />
        <StyledButton
          icon={faArrowLeft}
          onClick={handleClickNavigationButton(NAVIGATION_BUTTON_TYPES.BACK_TO_BATCH_LIST)}
          data-testid='4pl-navigate-button'
        >
          <T id='back_to_batch_list' />
        </StyledButton>
        {!!selectedRows?.length && (
          <StyledButton icon={faArrowDown} onClick={downloadOrders} data-testid='4pl-download-button'>
            <T id={downloadLabel} values={{ x: selectedRows.length }} />
          </StyledButton>
        )}
        {existDocs[SUPPORTING_DOC_TYPE.DELIVERY_ORDERS] && (
          <StyledButton
            icon={downloading[SUPPORTING_DOC_TYPE.DELIVERY_ORDERS] ? faSpinner : faArrowDown}
            onClick={() => downloadSupportingDocs(SUPPORTING_DOC_TYPE.DELIVERY_ORDERS)}
            disabled={downloading[SUPPORTING_DOC_TYPE.DELIVERY_ORDERS]}
          >
            <T id='download_delivery_orders' />
          </StyledButton>
        )}
        {existDocs[SUPPORTING_DOC_TYPE.COMMERCIAL_INVOICES] && (
          <StyledButton
            icon={downloading[SUPPORTING_DOC_TYPE.COMMERCIAL_INVOICES] ? faSpinner : faArrowDown}
            onClick={() => downloadSupportingDocs(SUPPORTING_DOC_TYPE.COMMERCIAL_INVOICES)}
            disabled={downloading[SUPPORTING_DOC_TYPE.COMMERCIAL_INVOICES]}
          >
            <T id='download_commercial_invoices' />
          </StyledButton>
        )}
        <StyledButton
          data-analyticsid='4plPrintAWBsByTrackingIDs'
          icon={faPrint}
          onClick={handleClickPrintAwb}
          disabled={disabledPrintAwbBill}
        >
          <T id='print_airway_bills' />
        </StyledButton>
      </StyledRow>

      <TableWrapper>
        <DashTable
          columns={getColumns({
            [ACTION_MENU_LABEL.FIX]: onFix,
            [ACTION_MENU_LABEL.VIEW]: viewOrderRequest,
            [ACTION_MENU_LABEL.ROLLBACK]: onRollback
          })}
          dataSource={data}
          fetching={false}
          fetchingMore={fetchingMore}
          noRowsRenderer={renderNoRows}
          getNext={getNext}
          rowSelection={rowSelection}
          allowSelectAll
          onRowClick={row => {
            const { event, rowData } = row
            // only allow viewing successfully created order
            if (rowData.status !== ORDER_REQUEST_STATUS.CREATED) {
              return
            }
            event.preventDefault()
            if (event.target.className && !event.target.className.includes('ant-dropdown')) {
              viewOrderRequest(rowData)
            }
          }}
        />
      </TableWrapper>
      <PrintAwbModal
        trackingIds={
          parcelDetail.parcelType === TYPE.B2B_BUNDLE
            ? selectedRows.flatMap(row => row?.response_body?.data?.piece_tracking_ids)
            : selectedRows.map(row => row?.response_body?.data?.tracking_id)
        }
        parcelIds={selectedRows.map(row => row.parcel_id)}
        parcelType={parcelDetail.parcelType}
        countryVendor={parcelDetail.countryVendor}
        batchId={batchId}
        onCancel={handleCloseAwbModal}
        totalOrderCount={selectedRows.length}
        visible={printAwbVisible}
        billsPerPage={PRINT_AWB_MODAL.PAGE_LAYOUT}
        orders={mappingOrderPrinters(selectedRows)}
      />
    </>
  )
}

OrderRequestTable.propTypes = {
  batchId: PropTypes.number,
  data: PropTypes.array,
  filterStatus: PropTypes.number,
  onFilterStatus: PropTypes.func,
  getNext: PropTypes.func,
  fetchingMore: PropTypes.bool,
  onFix: PropTypes.func,
  onView: PropTypes.func,
  onRollback: PropTypes.func,
  onUpdateQuery: PropTypes.func,
  onClickNavigateButton: PropTypes.func
}

export {
  OrderRequestTable,
  CellText,
  TableWrapper,
  TrackingIdTooltip,
  TooltipContent,
  AddressContent,
  RemarkContent,
  GranularStatus,
  ActionsContent,
  renderNoRows
}

TooltipContent.propTypes = {
  dataKey: PropTypes.string,
  rowData: PropTypes.object
}

TrackingIdTooltip.propTypes = {
  dataKey: PropTypes.string,
  rowData: PropTypes.object
}

RemarkContent.propTypes = {
  dataKey: PropTypes.string,
  rowData: PropTypes.object
}

GranularStatus.propTypes = {
  dataKey: PropTypes.string,
  rowData: PropTypes.object
}

ActionsContent.propTypes = {
  cellData: PropTypes.string,
  rowData: PropTypes.object,
  onFix: PropTypes.func,
  onView: PropTypes.func,
  onRollback: PropTypes.func,
  onRetry: PropTypes.func
}

AddressContent.propTypes = {
  dataKey: PropTypes.string,
  rowData: PropTypes.object
}
