import React from 'react'
import { Checkbox, NVInput, T } from '@nv/react-commons/src/Components'
import { StyledFormItem, StyledTextArea } from 'components/OCForm/styles'
import { RedAsterisk } from 'containers/FPLRegistration/styles'
import { ValidatorUtils } from '@nv/react-commons/src/Utils'
import { EMAIL_REGEX, FLOAT_NUMBER_REGEX, NUMBER_REGEX, PHONE_REGEX } from 'containers/FPLOrderCreate/dataUtils'
import {
  STANDARD_MMCC_FIELDS,
  DEFAULT_DIMENSION_UNIT,
  FLOAT_NUMBER_AND_GREATER_THAN_0,
  FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
  FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
  INTEGER_NUMBER_AND_GREATER_THAN_0
} from './constants'
import {
  NUMBER_1000,
  NUMBER_255,
  NUMBER_999,
  requestedCartonsTrackingIDsValidator,
  requestedTrackingIdB2BBundleValidator
} from './validatorUtils'
import { upperCase } from 'lodash'
import { mapBool } from 'utils/csvMappingFields'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { Text } from 'components/Text'
import { Vspace } from 'components/Vspace'
import { StyledFormItemWithCustomLabel } from './styles'

export const RecipientName = ({ form: { getFieldDecorator }, intl, disabled = false }) => (
  <StyledFormItem
    label={
      <span>
        <T id='recipient_name' data-testid='recipient_name' />
        <RedAsterisk />
      </span>
    }
  >
    {getFieldDecorator(STANDARD_MMCC_FIELDS.NAME, {
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: 'international_recipient_name_is_required'
          })
        },
        {
          max: NUMBER_255,
          message: intl.formatMessage({
            id: 'international_recipient_name_restriction'
          })
        }
      ]
    })(<NVInput disabled={disabled} />)}
  </StyledFormItem>
)

export const RecipientEmail = ({ form: { getFieldDecorator }, intl, disabled = false }) => (
  <StyledFormItem label={<T id='recipient_email' />}>
    {getFieldDecorator(STANDARD_MMCC_FIELDS.EMAIL, {
      rules: [
        {
          pattern: EMAIL_REGEX,
          message: intl.formatMessage({ id: 'invalid_email' })
        }
      ]
    })(<NVInput disabled={disabled} placeholder={intl.formatMessage({ id: 'optional' })} />)}
  </StyledFormItem>
)

export const CartonsNumber = ({ form: { getFieldDecorator, getFieldError }, intl, disabled = false }) => {
  const errorMessages = getFieldError('numberOfCartons') || []
  return (
    <StyledFormItem
      label={
        <span>
          <T id='no_of_cartons' />
          <RedAsterisk />
        </span>
      }
      help={errorMessages.map(item => (
        <div key={item}>{item}</div>
      ))}
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.NUMBER_OF_CARTONS, {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'number_of_carton_required' })
          },
          {
            validator: ValidatorUtils.number(
              intl.formatMessage({ id: 'invalid_number_of_carton' }, { x: NUMBER_999 }),
              value => value > NUMBER_999
            )
          },
          {
            validator: (_, value, callback) => {
              if (value && value < 1) {
                callback(intl.formatMessage({ id: 'the_value_must_be_number_greate_than_1' }))
              } else {
                callback()
              }
            }
          },
          {
            pattern: NUMBER_REGEX,
            message: intl.formatMessage({ id: 'positive_number_of_cartons' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const TotalCartonValue = ({ form: { getFieldDecorator }, intl, customsCurrency, disabled = false }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='total_carton_value_with_unit' values={{ x: upperCase(customsCurrency) }} />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.TOTAL_CARTON_VALUE, {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'total_carton_value_required' })
          },
          {
            validator: (_, value, callback) => {
              if (value && value == 0) {
                callback(intl.formatMessage({ id: 'the_value_must_be_number_greate_than_0' }))
              } else {
                callback()
              }
            }
          },
          {
            pattern: FLOAT_NUMBER_REGEX,
            message: intl.formatMessage({ id: 'the_value_must_be_number_greate_than_0' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const ShipperOrderNumber = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label={<T id='order_ref_no' />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.SHIPPER_ORDER_NUMBER, {
        rules: [
          {
            max: NUMBER_255,
            message: intl.formatMessage({ id: 'order_ref_no_invalid' }, { x: NUMBER_255 })
          }
        ]
      })(<NVInput disabled={disabled} placeholder={intl.formatMessage({ id: 'optional' })} />)}
    </StyledFormItem>
  )
}

export const Weight = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label={<T id='weight_kg' />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.WEIGHT, {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'international_bag_weight_is_invalid' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const Length = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label={<T id='length_in_unit' values={{ x: DEFAULT_DIMENSION_UNIT }} />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.LENGTH, {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const Width = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label={<T id='width_in_unit' values={{ x: DEFAULT_DIMENSION_UNIT }} />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.WIDTH, {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const Height = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label={<T id='height_in_unit' values={{ x: DEFAULT_DIMENSION_UNIT }} />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.HEIGHT, {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const CartonDescription = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='carton_description' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.DESCRIPTION, {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'carton_description_required' })
          },
          {
            max: NUMBER_1000,
            message: intl.formatMessage({
              id: 'the_description_must_be_maximum_1000_characters'
            })
          }
        ]
      })(
        <StyledTextArea
          disabled={disabled}
          placeholder={intl.formatMessage({
            id: 'carton_description_placeholder'
          })}
          autoSize={{ minRows: 3 }}
          style={{ maxHeight: 200, height: 81 }}
        />
      )}
    </StyledFormItem>
  )
}

export const HsCode = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label={<T id='hs_codes' />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.HSCODE, {
        rules: [
          {
            max: NUMBER_1000,
            message: intl.formatMessage(
              {
                id: 'hs_code_must_be_maximum_characters'
              },
              { x: NUMBER_1000 }
            )
          }
        ]
      })(
        <StyledTextArea
          disabled={disabled}
          placeholder={intl.formatMessage({
            id: 'hs_codes_placeholder'
          })}
          autoSize={{ minRows: 3 }}
        />
      )}
      <div style={{ fontSize: 10, lineHeight: 'normal', textAlign: 'end' }}>
        <T id='seperate_hs_code_note_in_shortly' />
      </div>
    </StyledFormItem>
  )
}

export const AddressLine1 = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='address_line_1' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.ADDRESS_LINE_1, {
        rules: [
          {
            required: true,
            message: `${intl.formatMessage({ id: 'address_line_1_required' })}`
          },
          {
            max: NUMBER_255,
            message: intl.formatMessage({ id: 'address_restriction' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const AddressLine2 = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label={<T id='address_line_2' />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.ADDRESS_LINE_2, {
        rules: [
          {
            max: NUMBER_255,
            message: intl.formatMessage({ id: 'address_restriction' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const RequestedCartonTIDs = ({
  form: { getFieldDecorator, getFieldValue },
  intl,
  bundles,
  disabled = false,
  retrievalErrors
}) => {
  return (
    <StyledFormItem label={<T id='requested_carton_tracking_id' />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.REQUESTED_CARTON_TRACKING_NUMBERS, {
        rules: [
          {
            validator: (_, value, callback) => {
              const numberOfCartons = getFieldValue(STANDARD_MMCC_FIELDS.NUMBER_OF_CARTONS)
              const error = requestedCartonsTrackingIDsValidator(
                value,
                numberOfCartons,
                bundles,
                retrievalErrors.requested_piece_tracking_ids
              )
              if (error !== null) {
                callback(intl.formatMessage({ id: error.id }, { ...error.values }))
              } else {
                callback()
              }
            }
          }
        ]
      })(
        <StyledTextArea
          disabled={disabled}
          placeholder={intl.formatMessage({
            id: 'requested_piece_tracking_ids_placeholder'
          })}
          autoSize={{ minRows: 3 }}
        />
      )}
    </StyledFormItem>
  )
}

export const RequestedTrackingID = ({
  form: { getFieldDecorator },
  intl,
  bundles,
  disabled = false,
  retrievalErrors
}) => {
  return (
    <StyledFormItem label={<T id='international_requested_tracking_id' />}>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.REQUESTED_TRACKING_ID, {
        rules: [
          {
            validator: (_, value, callback) => {
              const error = requestedTrackingIdB2BBundleValidator(value, bundles, retrievalErrors.requested_tracking_id)
              if (error !== null) {
                callback(intl.formatMessage({ id: error.id }, { ...error.values }))
              } else {
                callback()
              }
            }
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const RDOorGRN = ({ form: { getFieldDecorator }, intl, rdoDisabled, grnDisabled }) => {
  const getTitle = () => {
    return (
      <div style={{ width: 500 }}>
        <Text id='release_delivery_order' color='white' size={12} />
        <Text id='rdo_delivery_note_v2' color='white' size={12} />
        <Text id='rdo_driver_note_v2' color='white' size={12} />
        <Vspace height={12} />
        <Text id='goods_received_note' color='white' size={12} />
        <Text id='grn_delivery_note_v2' color='white' size={12} />
        <Text id='grn_driver_note_v2' color='white' size={12} />
        <Text id='grn_number_of_orders_note_v2' color='white' size={12} />
      </div>
    )
  }
  return (
    <StyledFormItemWithCustomLabel
      label={
        <div>
          <T id='rdo_or_grn_required' />
          <Tooltip
            title={getTitle()}
            placement='top'
            getPopupContainer={triggerNode => triggerNode.closest('.ant-form-item')}
            overlayClassName='custom-tooltip'
            overlayStyle={{ maxWidth: 500 }}
          >
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 10 }} />
          </Tooltip>
        </div>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.RDO_REQUIRED, {
        getValueProps: value => ({ checked: mapBool(value) })
      })(<Checkbox disabled={rdoDisabled}>{intl.formatMessage({ id: 'rdo_required' })}</Checkbox>)}
      {getFieldDecorator(STANDARD_MMCC_FIELDS.GRN_REQUIRED, {
        getValueProps: value => ({ checked: mapBool(value) })
      })(<Checkbox disabled={grnDisabled}>{intl.formatMessage({ id: 'grn_required' })}</Checkbox>)}
    </StyledFormItemWithCustomLabel>
  )
}

export const ShipperBoxId = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  const rules = [
    {
      required: true,
      message: intl.formatMessage({ id: 'international_shipper_box_id_is_required' })
    },
    {
      max: NUMBER_255,
      message: intl.formatMessage({ id: 'international_invalid_shipper_box_id' })
    }
  ]
  return (
    <StyledFormItem
      label={
        <span>
          <T id='shipper_box_id' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.SHIPPER_BOX_ID, { rules })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const NoOfParcel = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='no_of_parcels' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.NO_OF_PARCELS, {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'international_no_of_parcels_is_required' })
          },
          {
            pattern: INTEGER_NUMBER_AND_GREATER_THAN_0,
            message: intl.formatMessage({ id: 'international_no_of_parcels_is_invalid' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const GoodsValue = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='goods_value' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.GOODS_VALUE, {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'international_goods_value_is_required' })
          },
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_0,
            message: intl.formatMessage({ id: 'international_goods_value_is_invalid' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const InlineDescription = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='goods_description' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.DESCRIPTION, {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'international_goods_description_is_required' })
          },
          {
            min: 1,
            message: intl.formatMessage({ id: 'international_goods_description_is_invalid' })
          },
          {
            max: 255,
            message: intl.formatMessage({ id: 'international_goods_description_is_invalid' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const RecipientContact = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  const rules = [
    {
      required: true,
      message: intl.formatMessage({
        id: 'recipient_phone_number_required'
      })
    },
    {
      pattern: PHONE_REGEX,
      message: intl.formatMessage({ id: 'international_conact_number_must_be_valid_format' })
    }
  ]
  return (
    <StyledFormItem
      label={
        <span>
          <T id='recipient_phone_number' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.CONTACT, { rules })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const RecipientAddress = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='recipient_address' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator(STANDARD_MMCC_FIELDS.ADDRESS_LINE_1, {
        rules: [
          {
            required: true,
            message: `${intl.formatMessage({ id: 'international_recipient_address_is_required' })}`
          },
          {
            max: NUMBER_255,
            message: intl.formatMessage({ id: 'international_address_less_than_255_characters' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const LengthInShort = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label='L'>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.LENGTH, {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const WidthInShort = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label='W'>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.WIDTH, {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}

export const HeightInShort = ({ form: { getFieldDecorator }, intl, disabled = false }) => {
  return (
    <StyledFormItem label='H'>
      {getFieldDecorator(STANDARD_MMCC_FIELDS.HEIGHT, {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput disabled={disabled} />)}
    </StyledFormItem>
  )
}
