/* eslint-disable max-len */
import { endsWith, toNumber, compact, isEmpty, upperCase } from 'lodash'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { CsvUtils, ExcelUtils } from '@nv/react-commons/src/Utils'
import {
  ERROR_CODE_MAPPING,
  ERRORS_TRANSLATION_KEYS,
  ERRORS_TRANSLATION_KEYS_FOR_RELABEL,
  FIELD_KEYS,
  INSTRUCTION_DATA,
  ITEMISED_COUNTRY_CODES,
  MAX_CONTENT_LENGTH,
  MAX_UPLOAD_ORDER_REQUEST,
  NUMBER_OF_USELESS_LINE,
  NUMBER_OF_VALID_ROW_ORDERS,
  RULE_TYPES,
  START_GETTING_DATA_INDEX,
  VALIDATION_FORM,
  BATCH_ORDER_CREATION_TYPE
} from './constants'
import { parseJSONString } from 'containers/FPLOrderRequestList/dataUtils'
import { TRUTHY_FALSY } from 'containers/FPLLogging/constants'
import { RELABEL_ORDER_FIELD } from 'containers/FPLRelabelOrder/constants'
import { cutPrefix } from 'components/TrackingNumberInput/utils'
import {
  RecipientName,
  ContactNumber,
  ToEmail,
  ToAddress1,
  City,
  StateProvince,
  PostCode,
  CashOnDelivery,
  RequestedTrackingID,
  TaxNumber,
  CustomsDescription,
  GoodsValue,
  DeliveryInstructions,
  InsuredValue,
  ParcelWeight,
  SourceOrderID,
  OriginalTrackingID,
  RelabelRequestedTrackingID,
  CountryCode
} from 'containers/FPLOrderRequestList/Fields'
import { GST_KEYS } from 'containers/FPLOrderRequestList/constants'
import { buildPickupFields } from './csvMapping'
import {
  MAX_TID_LENGTH,
  MIN_TID_LENGTH,
  GLOBAL_COUNTRY_CODE,
  SPECIAL_COUNTRY_CODES,
  ALLOWED_COUNTRY,
  ORDER_CREATE_SOURCE,
  OCMethod,
  COUNTRIES_ALLOWANCE_REGEX
} from 'containers/FPLCommon/constants'

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
export const PHONE_REGEX = /^[+]*\d{6,32}$/
export const NUMBER_REGEX = /^\d+$/
export const FLOAT_NUMBER_REGEX = /^(?!0\d)\d*(\.\d+)?$/
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]*$/

export const DELIVERY_ADDRESS_BY_COUNTRY = {
  SG: [
    [ToAddress1, 16],
    [PostCode, 8],
    [DeliveryInstructions, 24]
  ],
  MY: [
    [ToAddress1, 24],
    [City, 8],
    [StateProvince, 8],
    [PostCode, 8],
    [DeliveryInstructions, 24]
  ],
  ID: [
    [ToAddress1, 24],
    [City, 8],
    [StateProvince, 8],
    [PostCode, 8],
    [DeliveryInstructions, 24]
  ],
  VN: [
    [ToAddress1, 24],
    [City, 8],
    [StateProvince, 8],
    [PostCode, 8],
    [DeliveryInstructions, 24]
  ],
  PH: [
    [ToAddress1, 24],
    [City, 8],
    [StateProvince, 8],
    [PostCode, 8],
    [DeliveryInstructions, 24]
  ],
  TH: [
    [ToAddress1, 24],
    [StateProvince, 12],
    [PostCode, 12],
    [DeliveryInstructions, 24]
  ],
  GLOBAL: [
    [ToAddress1, 24],
    [CountryCode, 12],
    [City, 12],
    [StateProvince, 12],
    [PostCode, 12],
    [DeliveryInstructions, 24]
  ]
}

export const RECEIPIENT_INFO_BY_COUNTRY = {
  SG: [
    [RecipientName, 8],
    [ContactNumber, 8],
    [ToEmail, 8]
  ],
  MY: [
    [RecipientName, 8],
    [ContactNumber, 8],
    [ToEmail, 8]
  ],
  ID: [
    [RecipientName, 12],
    [ContactNumber, 12],
    [TaxNumber, 12],
    [ToEmail, 12]
  ],
  VN: [
    [RecipientName, 12],
    [ContactNumber, 12],
    [TaxNumber, 12],
    [ToEmail, 12]
  ],
  PH: [
    [RecipientName, 8],
    [ContactNumber, 8],
    [ToEmail, 8]
  ],
  TH: [
    [RecipientName, 8],
    [ContactNumber, 8],
    [ToEmail, 8]
  ],
  GLOBAL: [
    [RecipientName, 12],
    [ContactNumber, 12],
    [TaxNumber, 12],
    [ToEmail, 12]
  ]
}

export const PARCEL_INFO_BY_COUNTRY = {
  SG: [
    [SourceOrderID, 12],
    [RequestedTrackingID, 12],
    [CashOnDelivery, 8],
    [InsuredValue, 8],
    [ParcelWeight, 8]
  ],
  MY: [
    [SourceOrderID, 12],
    [RequestedTrackingID, 12],
    [CashOnDelivery, 8],
    [InsuredValue, 8],
    [ParcelWeight, 8]
  ],
  VN: [
    [SourceOrderID, 12],
    [RequestedTrackingID, 12],
    [CashOnDelivery, 8],
    [InsuredValue, 8],
    [ParcelWeight, 8]
  ],
  ID: [
    [SourceOrderID, 12],
    [RequestedTrackingID, 12],
    [CashOnDelivery, 8],
    [InsuredValue, 8],
    [ParcelWeight, 8]
  ],
  PH: [
    [GoodsValue, 8],
    [CashOnDelivery, 8],
    [InsuredValue, 8],
    [ParcelWeight, 8],
    [CustomsDescription, 16],
    [SourceOrderID, 12],
    [RequestedTrackingID, 12]
  ],
  TH: [
    [SourceOrderID, 12],
    [RequestedTrackingID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12]
  ],
  GLOBAL: [
    [SourceOrderID, 12],
    [RequestedTrackingID, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12]
  ]
}

export const PARCEL_INFO_BY_COUNTRY_FOR_RELABEL = {
  SG: [
    [SourceOrderID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12]
  ],
  MY: [
    [SourceOrderID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12]
  ],
  VN: [
    [SourceOrderID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12]
  ],
  ID: [
    [SourceOrderID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12]
  ],
  PH: [
    [GoodsValue, 8],
    [CashOnDelivery, 8],
    [InsuredValue, 8],
    [ParcelWeight, 8],
    [SourceOrderID, 16],
    [CustomsDescription, 24]
  ],
  TH: [
    [SourceOrderID, 8],
    [CashOnDelivery, 8],
    [InsuredValue, 8]
  ]
}

export const PARCEL_INFO_BY_COUNTRY_FOR_RELABEL_FIXED_FORM = {
  SG: [
    [SourceOrderID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12],
    [OriginalTrackingID, 12],
    [RelabelRequestedTrackingID, 12]
  ],
  MY: [
    [SourceOrderID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12],
    [OriginalTrackingID, 12],
    [RelabelRequestedTrackingID, 12]
  ],
  VN: [
    [SourceOrderID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12],
    [OriginalTrackingID, 12],
    [RelabelRequestedTrackingID, 12]
  ],
  ID: [
    [SourceOrderID, 12],
    [CashOnDelivery, 12],
    [InsuredValue, 12],
    [ParcelWeight, 12],
    [OriginalTrackingID, 12],
    [RelabelRequestedTrackingID, 12]
  ],
  PH: [
    [GoodsValue, 8],
    [CashOnDelivery, 8],
    [InsuredValue, 8],
    [ParcelWeight, 8],
    [CustomsDescription, 16],
    [SourceOrderID, 12],
    [OriginalTrackingID, 12],
    [RelabelRequestedTrackingID, 12]
  ],
  TH: [
    [GoodsValue, 8],
    [CashOnDelivery, 8],
    [InsuredValue, 8],
    [ParcelWeight, 8],
    [CustomsDescription, 16],
    [SourceOrderID, 12],
    [OriginalTrackingID, 12],
    [RelabelRequestedTrackingID, 12]
  ]
}

const getPostcodeInfo = identifier => {
  const id = identifier && identifier.toLowerCase()
  switch (id) {
    case COUNTRIES.SG:
    case COUNTRIES.VN:
      return 6
    case COUNTRIES.ID:
    case COUNTRIES.MA:
    case COUNTRIES.MY:
    case COUNTRIES.TH:
    case COUNTRIES.MM:
      return 5
    case COUNTRIES.PH:
      return 4
    default:
      return 6
  }
}

const validationRulesByKey = (fieldKey, row, columnIndex, country, isPrefixRequired, fields) => {
  switch (fieldKey) {
    case FIELD_KEYS.REQUESTED_TRACKING_ID:
      return {
        required: isPrefixRequired,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: MIN_TID_LENGTH },
              { type: RULE_TYPES.MAX_LENGTH, validator: MAX_TID_LENGTH }
            ]
          },
          {
            form: VALIDATION_FORM.DUPLICATE,
            group: [{ type: RULE_TYPES.DUPLICATE, validator: 1 }]
          }
        ]
      }
    case FIELD_KEYS.SOURCE_ORDER_ID:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.ITEM_COLOR:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 20 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_NAME:
    case FIELD_KEYS.TO_ADDRESS:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_CONTACT_NUMBER:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [{ type: RULE_TYPES.REGEX, validator: PHONE_REGEX }]
          }
        ]
      }
    case FIELD_KEYS.TO_CITY:
      return {
        required: true,
        exceptRequiredCountry: [COUNTRIES.SG, COUNTRIES.TH],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_STATE_PROVINCE:
      return {
        required: true,
        exceptRequiredCountry: [COUNTRIES.SG, COUNTRIES.VN],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 }
            ]
          }
        ]
      }
    case FIELD_KEYS.TO_POSTCODE:
      let group = []
      if (country !== GLOBAL_COUNTRY_CODE) {
        group = [
          { type: RULE_TYPES.MIN_LENGTH, validator: getPostcodeInfo(country) },
          { type: RULE_TYPES.MAX_LENGTH, validator: getPostcodeInfo(country) },
          { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
        ]
      }
      let toCountryValue = ''
      if (country === GLOBAL_COUNTRY_CODE) {
        const toCountryIndex = fields.findIndex(field => field === FIELD_KEYS.TO_COUNTRY)
        toCountryValue = row[toCountryIndex]
      }
      const lowerCaseCountry = country?.toLowerCase()
      let isRequired = true
      if (
        lowerCaseCountry === COUNTRIES.VN ||
        lowerCaseCountry === COUNTRIES.ID ||
        lowerCaseCountry === COUNTRIES.PH ||
        toCountryValue === SPECIAL_COUNTRY_CODES.HK
      ) {
        isRequired = false
      }
      return {
        required: isRequired,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: group
          }
        ]
      }
    case FIELD_KEYS.TO_EMAIL:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [{ type: RULE_TYPES.REGEX, validator: EMAIL_REGEX }]
          }
        ]
      }
    case FIELD_KEYS.ITEM_DESCRIPTION:
    case FIELD_KEYS.GOOD_DESCRIPTION:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: MAX_CONTENT_LENGTH }
            ]
          }
        ]
      }
    case FIELD_KEYS.ITEM_VALUE:
    case FIELD_KEYS.GOOD_VALUE:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN, validator: 1 },
              { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.COD:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN, validator: 0 },
              { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.CONSIGNEE_TAX:
      return {
        required: true,
        exceptRequiredCountry: ['global'],
        rules: []
      }
    case FIELD_KEYS.QUANTITY:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN, validator: 1 },
              { type: RULE_TYPES.REGEX, validator: NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.GST_INCLUDED:
      return {
        required: false,
        exceptRequiredCountry: [
          COUNTRIES.ID,
          COUNTRIES.MA,
          COUNTRIES.MM,
          COUNTRIES.MY,
          COUNTRIES.PH,
          COUNTRIES.TH,
          COUNTRIES.VN,
          'global'
        ],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [{ type: RULE_TYPES.TRUTHY_FALSY }]
          }
        ]
      }
    case FIELD_KEYS.GST_NUMBER:
      return {
        required: row[columnIndex - 1] === TRUTHY_FALSY.YES,
        exceptRequiredCountry: [
          COUNTRIES.ID,
          COUNTRIES.MA,
          COUNTRIES.MM,
          COUNTRIES.MY,
          COUNTRIES.PH,
          COUNTRIES.TH,
          COUNTRIES.VN,
          'global'
        ],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 },
              { type: RULE_TYPES.REGEX, validator: ALPHANUMERIC_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.ITEM_WEIGHT:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.EQUAL_MIN, validator: 0 },
              { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.PARCEL_WEIGHT:
    case FIELD_KEYS.INSURED_VALUE:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.EQUAL_MIN, validator: 0 },
              { type: RULE_TYPES.REGEX, validator: FLOAT_NUMBER_REGEX }
            ]
          }
        ]
      }
    case FIELD_KEYS.DELIVERY_INSTRUCTION:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: MAX_CONTENT_LENGTH }
            ]
          }
        ]
      }
    case FIELD_KEYS.LVG_NUMBER:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              { type: RULE_TYPES.MIN_LENGTH, validator: 1 },
              { type: RULE_TYPES.MAX_LENGTH, validator: 255 },
              { type: RULE_TYPES.REGEX, validator: ALPHANUMERIC_REGEX }
            ]
          }
        ]
      }
    // Relabel fields cases
    case RELABEL_ORDER_FIELD.ORIGIN_TRACKING_ID:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.DUPLICATE,
            group: [{ type: RULE_TYPES.DUPLICATE, validator: 1 }]
          },
          {
            form: VALIDATION_FORM.DIFFERENT_ORIGIN,
            group: [{ type: RULE_TYPES.IN }]
          }
        ]
      }
    case RELABEL_ORDER_FIELD.NEW_REQUESTED_TRACKING_ID:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [
              {
                type: RULE_TYPES.MIN_LENGTH,
                validator: MIN_TID_LENGTH
              },
              {
                type: RULE_TYPES.MAX_LENGTH,
                validator: MAX_TID_LENGTH
              }
            ]
          },
          {
            form: VALIDATION_FORM.DUPLICATE,
            group: [{ type: RULE_TYPES.DUPLICATE, validator: 1 }]
          }
        ]
      }
    case FIELD_KEYS.TO_COUNTRY:
      return {
        required: true,
        exceptRequiredCountry: [],
        rules: [
          {
            form: VALIDATION_FORM.INVALID,
            group: [{ type: RULE_TYPES.REGEX, validator: COUNTRIES_ALLOWANCE_REGEX }]
          }
        ]
      }
    default:
      return {
        required: false,
        exceptRequiredCountry: [],
        rules: []
      }
  }
}

export const handleValidationByType = ({ value, rules, ruleIdx, allValues, presets }) => {
  let invalidValue = null
  const { type, validator } = rules[ruleIdx]
  switch (type) {
    case RULE_TYPES.MIN_LENGTH:
      if (value?.length < validator) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.MAX_LENGTH:
      if (value?.length > validator) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.REGEX:
      if (!validator.test(value)) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.MIN:
      if (value === '' || toNumber(value) < validator) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.EQUAL_MIN:
      if (value === '' || toNumber(value) <= validator) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.DUPLICATE:
      if (allValues.filter(item => item?.trim() === value).length > validator) {
        invalidValue = ERROR_CODE_MAPPING.DUPLICATE
      }
      break
    case RULE_TYPES.TRUTHY_FALSY:
      if (!Object.values(TRUTHY_FALSY).includes(value)) {
        invalidValue = ERROR_CODE_MAPPING.INVALID
      }
      break
    case RULE_TYPES.IN:
      if (!presets?.includes(value)) {
        invalidValue = ERROR_CODE_MAPPING.DIFFERENT_ORIGIN
      }
  }
  return invalidValue
}

export const getTranslationFromKey = (key, errorCode, intl) => {
  const messageId = Object.keys(ERRORS_TRANSLATION_KEYS_FOR_RELABEL).includes(key)
    ? ERRORS_TRANSLATION_KEYS_FOR_RELABEL[key][errorCode]
    : ERRORS_TRANSLATION_KEYS[key][errorCode]
  switch (messageId) {
    case 'international_new_requested_tid_invalid_message_new':
    case 'new_requested_tracking_id_invalid_new':
      return intl.formatMessage(
        { id: messageId },
        {
          min: MIN_TID_LENGTH,
          max: MAX_TID_LENGTH
        }
      )
    default:
      return intl.formatMessage({ id: messageId })
  }
}

const getValueByColumn = (allRows, key, allHeaderFields) => {
  const columnIndex = allHeaderFields.findIndex(templateKey => templateKey === key)
  const allValues = []
  if (columnIndex > -1) {
    allRows.forEach((row, rowIndex) => {
      if (rowIndex > START_GETTING_DATA_INDEX) {
        allValues.push(row[columnIndex])
      }
    })
  }
  return allValues
}

const checkRequiredRule = ({ required, key, value, countryCode, exceptRequiredCountry, intl }) => {
  let errors = []
  const isExcept = !exceptRequiredCountry.includes(countryCode.toLowerCase())
  const isEmptyValue = !value && value !== 0
  if (required && isEmptyValue && isExcept) {
    errors = [...errors, getTranslationFromKey(key, ERROR_CODE_MAPPING.REQUIRED, intl)]
  }
  return { errors, isExcept, isEmptyValue }
}

const checkInvalidRule = ({ rules, allRows, key, value, countryCode, isItemised, intl, originTids, isRelabel }) => {
  const allHeaderFields = isRelabel ? Object.values(RELABEL_ORDER_FIELD) : Object.keys(INSTRUCTION_DATA)
  const errorsByRule = []
  rules.map(rule => {
    let ruleIdx = 0
    let isErrorInGroup = false
    while (ruleIdx <= rule.group.length - 1 && !isErrorInGroup) {
      const allValues = rule.form !== VALIDATION_FORM.INVALID ? getValueByColumn(allRows, key, allHeaderFields) : []
      const presets = rule.form === VALIDATION_FORM.DIFFERENT_ORIGIN ? originTids : []
      const invalidRule = handleValidationByType({ value, rules: rule.group, ruleIdx, key, allValues, presets })
      if (invalidRule && !errorsByRule.includes(invalidRule)) {
        switch (invalidRule) {
          case ERROR_CODE_MAPPING.INVALID:
          case ERROR_CODE_MAPPING.DIFFERENT_ORIGIN:
            errorsByRule.push(getTranslationFromKey(key, invalidRule, intl))
            isErrorInGroup = true
            break
          case ERROR_CODE_MAPPING.DUPLICATE:
            if (!isItemised) {
              const messageKeyByFieldKey =
                key === RELABEL_ORDER_FIELD.ORIGIN_TRACKING_ID
                  ? 'international_unique_origin_tracking_id'
                  : 'international_unique_tracking_id'
              errorsByRule.push(intl.formatMessage({ id: messageKeyByFieldKey }))
            }
            break
          default:
            break
        }
      }
      ruleIdx++
    }
  })
  return errorsByRule
}

export const validate = ({
  key,
  value,
  countryCode,
  intl,
  allRows = [],
  row,
  columnIndex,
  isItemised,
  originTids,
  isRelabel,
  isPrefixRequired,
  fields
}) => {
  const trimedValue = value ? `${value}`.trim() : value
  const { required, exceptRequiredCountry, rules } = validationRulesByKey(
    key,
    row,
    columnIndex,
    countryCode,
    isPrefixRequired,
    fields
  )

  // 1. Check required values by rule
  const { errors: errs, isExcept, isEmptyValue } = checkRequiredRule({
    required,
    key,
    value: trimedValue,
    countryCode,
    exceptRequiredCountry,
    intl
  })
  let errors = errs
  // 2. Check invalid values by rule
  if ((required && isExcept) || !isEmptyValue) {
    const errorsByRule = checkInvalidRule({
      rules,
      allRows,
      key,
      value: trimedValue,
      countryCode,
      isItemised,
      intl,
      originTids,
      isRelabel
    })
    errors = [...errors, ...errorsByRule]
  }
  return errors
}

export const convertRawObjectFromFields = ({
  row,
  fields,
  countryCode,
  intl,
  allRows,
  isItemised,
  isRelabel,
  originTids,
  isPrefixRequired
}) => {
  const result = {}
  fields.forEach((field, index) => {
    result[field] = {
      value: row[index],
      errors: validate({
        key: field,
        value: row[index],
        countryCode,
        intl,
        allRows,
        row,
        columnIndex: index,
        isItemised,
        originTids,
        isRelabel,
        isPrefixRequired,
        fields
      })
    }
  })
  return result
}

const groupOrderByTid = (allOrdersByKey, groupByField) => {
  return Object.keys(allOrdersByKey).reduce((results, indexKey) => {
    const fieldValue = allOrdersByKey[indexKey][groupByField].value || indexKey
    results[fieldValue] = results[fieldValue] || []
    results[fieldValue].push(indexKey)
    return results
  }, {})
}

const processItemisedOrders = (allOrdersByKey, isRelabel) => {
  const groupByField = isRelabel ? RELABEL_ORDER_FIELD.ORIGIN_TRACKING_ID : FIELD_KEYS.REQUESTED_TRACKING_ID
  const groupsByRequestedTrackingId = groupOrderByTid(allOrdersByKey, groupByField)
  let dataInGroup = []
  const validItemisedOrders = {}
  const invalidItemisedOrders = {}
  let numberValidItemOfValidOrder = 0
  let numberValidItemOfInvalidOrder = 0
  let numberInvalidItemOfInvalidOrder = 0

  Object.keys(groupsByRequestedTrackingId).forEach(tid => {
    groupsByRequestedTrackingId[tid].forEach(rowIndex => {
      dataInGroup.push(Object.values(allOrdersByKey[rowIndex]).every(fieldVal => fieldVal.errors.length === 0))
    })
    const isValid = dataInGroup.every(valid => valid)
    const oneGroup = groupsByRequestedTrackingId[tid].reduce((prevRow, currentRowIdx) => {
      return {
        ...prevRow,
        [currentRowIdx]: allOrdersByKey[currentRowIdx]
      }
    }, {})
    if (isValid) {
      validItemisedOrders[tid] = oneGroup
      numberValidItemOfValidOrder += Object.keys(oneGroup).length
    } else {
      Object.keys(oneGroup).forEach(id => {
        if (Object.values(allOrdersByKey[id]).every(fieldVal => fieldVal.errors.length === 0)) {
          numberValidItemOfInvalidOrder += 1
        } else {
          numberInvalidItemOfInvalidOrder += 1
        }
      })

      invalidItemisedOrders[tid] = oneGroup
    }
    dataInGroup = []
  })
  return {
    validItemisedOrders,
    invalidItemisedOrders,
    numberValidItemOfValidOrder,
    numberValidItemOfInvalidOrder,
    numberInvalidItemOfInvalidOrder
  }
}

export const processUploadedData = ({ file, countryCode, intl, isRelabel, originTids, isPrefixRequired }) => {
  const { data } = file
  if (data.length < NUMBER_OF_VALID_ROW_ORDERS) {
    return { validOrders: {}, invalidOrders: {} }
  }
  const fields = data[0]
  const result = {
    allOrders: {},
    invalidOrders: {},
    validOrders: {},
    validItemisedOrders: {},
    invalidItemisedOrders: {},
    numberValidItemOfValidOrder: 0,
    numberValidItemOfInvalidOrder: 0,
    numberInvalidItemOfInvalidOrder: 0
  }

  // Relabel flow always follow the parcel level
  const isItemised = !isRelabel && Object.keys(ITEMISED_COUNTRY_CODES).includes(countryCode)

  data.forEach((row, rowIndex) => {
    if (rowIndex > START_GETTING_DATA_INDEX) {
      const injectedRow = convertRawObjectFromFields({
        row,
        fields,
        countryCode,
        intl,
        allRows: data,
        isItemised,
        isRelabel,
        originTids,
        isPrefixRequired
      })
      const isIncludeError = Object.values(injectedRow).find(rowItem => rowItem.errors.length > 0)

      result.allOrders[rowIndex] = injectedRow
      result[isIncludeError ? 'invalidOrders' : 'validOrders'][rowIndex] = injectedRow
    }
  })

  if (isItemised) {
    const {
      validItemisedOrders: valItemisedOrder,
      invalidItemisedOrders: invalItemisedOrder,
      numberValidItemOfValidOrder,
      numberValidItemOfInvalidOrder,
      numberInvalidItemOfInvalidOrder
    } = processItemisedOrders(result.allOrders, isRelabel)

    result.validItemisedOrders = valItemisedOrder
    result.invalidItemisedOrders = invalItemisedOrder
    result.numberValidItemOfValidOrder = numberValidItemOfValidOrder
    result.numberValidItemOfInvalidOrder = numberValidItemOfInvalidOrder
    result.numberInvalidItemOfInvalidOrder = numberInvalidItemOfInvalidOrder
  }
  return result
}

const checkWrongTemplate = (fileData, destinationCountry, targetFields) => {
  const headerFields = fileData.data[0] || []
  const instructionData = targetFields[destinationCountry]
  const parcelHeaderKeys = Object.keys(instructionData)

  const isValidHeaderLength = headerFields.length === parcelHeaderKeys.length
  const isValidHeaderName = isValidHeader(headerFields, parcelHeaderKeys)
  return !isValidHeaderLength || !isValidHeaderName
}

export const isValidHeader = (targetArray, originalArray) =>
  originalArray.every((v, index) => targetArray.indexOf(v) === index)

export const getFileDataAndErrorMessages = async (file, destinationCountry, targetFields) => {
  const fileName = file?.name
  let results = { data: [] }
  const errorMsg = []
  if (endsWith(fileName, '.csv')) {
    results = await CsvUtils.parse(file)
  }
  if (endsWith(fileName, '.xlsx') || endsWith(fileName, '.xls')) {
    results = {
      data: await ExcelUtils.parseFile(file)
    }
  }
  if (results.errors && results.errors.length !== 0) {
    errorMsg.push({ id: 'international_advanced_search_broken_file' })
  }
  if (results.data) {
    const maxinumMsg = {
      id: 'international_maximum_request',
      values: { x: results.data.length - NUMBER_OF_USELESS_LINE, y: MAX_UPLOAD_ORDER_REQUEST }
    }
    const emptyMsg = { id: 'international_empty_order' }
    const isWrongTemplate = checkWrongTemplate(results, destinationCountry, targetFields)
    if (isWrongTemplate) {
      errorMsg.push({ id: 'international_order_creation_wrong_template' })
    }

    if (results.data.length <= NUMBER_OF_USELESS_LINE) {
      errorMsg.push(emptyMsg)
    }

    if (results.data.length - NUMBER_OF_USELESS_LINE > MAX_UPLOAD_ORDER_REQUEST) {
      errorMsg.push(maxinumMsg)
    }
  }
  return {
    fileData: results,
    errorMsg,
    fileName
  }
}

export const convertObjectTo2DArray = dataSource => {
  if (!dataSource) return []

  const listKeys = Object.keys(dataSource)
  const listVals = Object.values(dataSource)
  const result = listKeys.map((key, index) => {
    return [{ value: +key + 1, errors: [] }, ...Object.values(listVals[index])]
  })

  return result
}

export const buildOrderRequestPayloadForKeyboard = ({
  service,
  orders,
  senderAddress,
  pickupAddress,
  returnAddress,
  isReturnOrder,
  customsCurrency,
  pickup,
  originalTrackingId,
  timeSlot,
  volume,
  isItemised
}) => {
  const { origin_country: originCountry, destination_country: destinationCountry, code } = service
  const addressParams = buildPickupFields({
    pickup,
    returnAddress,
    senderAddress,
    pickupAddress,
    timeSlot,
    volume
  })
  const ordersPayload = orders.map(order => {
    const {
      requestedTrackingID,
      sourceOrderID,
      cashOnDelivery,
      deliveryInstructions,
      insuredValue,
      goodsValue,
      customsDescription,
      weight,
      taxNumber,
      name,
      addressLine1,
      contactNumber,
      city,
      postCode,
      email,
      stateProvince
    } = order
    const items = isItemised
      ? buildItemsRequestData(Object.values(order.items || {}), originCountry, destinationCountry)
      : []
    const delivery = {}
    if (cashOnDelivery) {
      delivery['cash_on_delivery'] = +cashOnDelivery
    }
    if (deliveryInstructions) {
      delivery['delivery_instructions'] = deliveryInstructions
    }
    if (insuredValue) {
      delivery['insured_value'] = +insuredValue
    }
    const orderBuilder = {
      service_code: code,
      source: ORDER_CREATE_SOURCE.KEYBOARD,
      ...(sourceOrderID && { source_order_id: sourceOrderID }),
      ...(requestedTrackingID && { requested_tracking_id: requestedTrackingID }),
      ...(isReturnOrder && originalTrackingId && { source_reference_id: originalTrackingId }),
      ...addressParams,
      ...(items.length && { items: items }),
      ...(!isEmpty(delivery) && { delivery }),
      to: {
        country_code: order.toCountry || destinationCountry,
        name: name,
        address_line1: addressLine1,
        contact_number: contactNumber,
        ...(city && { city: city }),
        ...(postCode && { post_code: postCode }),
        ...(email && { contact_email: email }),
        ...(stateProvince && { state_province: stateProvince })
      },
      ...(items.length && { items: items }),
      parcel_details: {
        ...(taxNumber && { tax_id: taxNumber }),
        ...(customsDescription && { customs_description: customsDescription }),
        customs_currency: customsCurrency,
        ...(goodsValue && { value: +goodsValue }),
        origin_country: getOriginCountry(originCountry, destinationCountry),
        ...(weight && { weight: +weight || 0 })
      }
    }
    return orderBuilder
  })
  return {
    order_requests: ordersPayload,
    ...(isReturnOrder && { is_relabel: true, type: BATCH_ORDER_CREATION_TYPE.RELABEL })
  }
}

const getOriginCountry = (originCountry, destinationCountry) => {
  return originCountry !== 'XX' ? originCountry : destinationCountry
}

const buildParcelInfo = ({
  order,
  originCountry,
  destinationCountry,
  serviceCode,
  prefix,
  isPrefixRequired,
  customsCurrency
}) => {
  const delivery = {}
  if (order[FIELD_KEYS.COD]?.value) {
    delivery['cash_on_delivery'] = +order[FIELD_KEYS.COD].value
  }
  if (order[FIELD_KEYS.DELIVERY_INSTRUCTION]?.value) {
    delivery['delivery_instructions'] = order[FIELD_KEYS.DELIVERY_INSTRUCTION].value
  }
  if (order[FIELD_KEYS.INSURED_VALUE]?.value) {
    delivery['insured_value'] = +order[FIELD_KEYS.INSURED_VALUE].value
  }

  const parcelDetails = {
    ...(order[FIELD_KEYS.PARCEL_WEIGHT]?.value && { weight: +order[FIELD_KEYS.PARCEL_WEIGHT].value }),
    ...(order[FIELD_KEYS.GOOD_DESCRIPTION]?.value && { customs_description: order[FIELD_KEYS.GOOD_DESCRIPTION].value }),
    ...(order[FIELD_KEYS.GOOD_VALUE]?.value && { value: +order[FIELD_KEYS.GOOD_VALUE].value }),
    customs_currency: customsCurrency,
    origin_country: getOriginCountry(originCountry, destinationCountry)
  }
  const combinedRequestedTrackingId = isPrefixRequired
    ? prefix + cutPrefix(prefix, order[FIELD_KEYS.REQUESTED_TRACKING_ID]?.value)
    : order[FIELD_KEYS.REQUESTED_TRACKING_ID]?.value
  return {
    parcel_details: parcelDetails,
    ...(!isEmpty(delivery) && { delivery }),
    to: {
      country_code: order[FIELD_KEYS.TO_COUNTRY]?.value || destinationCountry,
      name: order[FIELD_KEYS.TO_NAME].value,
      address_line1: order[FIELD_KEYS.TO_ADDRESS].value,
      contact_number: order[FIELD_KEYS.TO_CONTACT_NUMBER].value,
      ...(order[FIELD_KEYS.TO_CITY]?.value && { city: order[FIELD_KEYS.TO_CITY].value }),
      ...(order[FIELD_KEYS.TO_POSTCODE]?.value && { post_code: order[FIELD_KEYS.TO_POSTCODE].value }),
      ...(order[FIELD_KEYS.TO_EMAIL]?.value && { contact_email: order[FIELD_KEYS.TO_EMAIL].value }),
      ...(order[FIELD_KEYS.TO_STATE_PROVINCE]?.value && { state_province: order[FIELD_KEYS.TO_STATE_PROVINCE].value })
    },
    service_code: serviceCode,
    source: OCMethod.UPLOAD,
    ...(combinedRequestedTrackingId && { requested_tracking_id: combinedRequestedTrackingId }),
    ...(order[FIELD_KEYS.SOURCE_ORDER_ID]?.value && { source_order_id: order[FIELD_KEYS.SOURCE_ORDER_ID].value })
  }
}

export const buildOrderRequestDataFromBulkUpload = ({
  orders,
  service,
  prefix,
  isPrefixRequired,
  senderAddress,
  pickupAddress,
  returnAddress,
  pickup,
  timeSlot,
  volume,
  isItemised,
  customsCurrency
}) => {
  const { origin_country: originCountry, destination_country: destinationCountry, code } = service
  const addressParams = buildPickupFields({
    pickup,
    returnAddress,
    senderAddress,
    pickupAddress,
    timeSlot,
    volume
  })

  if (isItemised) {
    const orderRequests = []
    Object.keys(orders).forEach(key => {
      const rawItems = Object.values(orders[key]).map(item => {
        return {
          customsDescription: item[FIELD_KEYS.ITEM_DESCRIPTION]?.value,
          goodsValue: item[FIELD_KEYS.ITEM_VALUE]?.value,
          quantityValue: item[FIELD_KEYS.QUANTITY]?.value,
          weight: item[FIELD_KEYS.ITEM_WEIGHT]?.value,
          color: item[FIELD_KEYS.ITEM_COLOR]?.value,
          lvgNumber: item[FIELD_KEYS.LVG_NUMBER]?.value,
          gstNumber: item[FIELD_KEYS.GST_NUMBER]?.value,
          gstIncluded: item[FIELD_KEYS.GST_INCLUDED]?.value
        }
      })

      const firstOrder = Object.values(orders[key])[0]
      const orderRequestInfo = buildParcelInfo({
        order: firstOrder,
        originCountry,
        destinationCountry,
        isItemised,
        prefix,
        isPrefixRequired,
        customsCurrency,
        serviceCode: code
      })
      orderRequests.push({
        ...addressParams,
        ...orderRequestInfo,
        items: buildItemsRequestData(rawItems, originCountry, destinationCountry)
      })
    })
    return {
      order_requests: orderRequests
    }
  }
  const orderRequests = []
  Object.values(orders).forEach(order => {
    const orderRequestInfo = buildParcelInfo({
      order,
      originCountry,
      destinationCountry,
      isItemised,
      prefix,
      isPrefixRequired,
      customsCurrency,
      serviceCode: code
    })
    orderRequests.push({
      ...addressParams,
      ...orderRequestInfo
    })
  })
  return {
    order_requests: orderRequests
  }
}

export const parseReturnDataToManualData = returnData => {
  return returnData.map(data => {
    const isItemised = data.items?.length
    const metaData = parseJSONString(data.metadata || '{}')
    const deliveryInfo = parseJSONString(data.delivery_info || '{}')
    let manualData = {
      name: '',
      contactNumber: '',
      city: '',
      stateProvince: '',
      postCode: '',
      addressLine1: '',
      email: '',
      customsDescription: isItemised ? null : metaData?.customs_description,
      goodsValue: isItemised ? null : metaData?.value,
      cashOnDelivery: '',
      trackingId: data.tracking_id,
      weight: metaData.shipper_submitted_weight,
      customsCurrency: metaData?.customs_currency,
      sourceOrderID: '',
      deliveryInstructions: deliveryInfo?.delivery_instructions,
      insuredValue: deliveryInfo?.insured_value || '',
      requestedTrackingID: data.requested_tracking_id
    }

    if (isItemised) {
      const items = data.items.map(item => {
        const itemMetaData = parseJSONString(item.metadata)
        return {
          goodsValue: itemMetaData.unit_value,
          quantityValue: itemMetaData.quantity,
          customsDescription: itemMetaData.description,
          lvgNumber: itemMetaData.taxes?.lvg?.number,
          gstNumber: itemMetaData.taxes?.gst?.number,
          gstIncluded: itemMetaData.taxes?.gst?.is_included,
          color: itemMetaData.color,
          weight: itemMetaData.unit_weight
        }
      })
      manualData = {
        ...manualData,
        items
      }
    }

    return manualData
  })
}

export const transformServiceDetailData = relabelOrder => {
  if (!relabelOrder) return null
  const {
    from_country_code,
    from_address_line1,
    from_address_line2,
    from_name,
    from_postcode,
    from_contact_number,
    from_contact_email,
    to_country_code
  } = relabelOrder
  return {
    toCountry: to_country_code,
    senderAddress: {
      address1: from_address_line1,
      address2: from_address_line2,
      name: from_name,
      postcode: from_postcode,
      country: from_country_code,
      contact: from_contact_number,
      email: from_contact_email
    }
  }
}

export const getFormFields = (destinationCountry, isReturnOrder, isFixedForm) => {
  let destinationCountryCode = destinationCountry
  if (!ALLOWED_COUNTRY[destinationCountry]) {
    destinationCountryCode = GLOBAL_COUNTRY_CODE
  }
  let parcelFields = PARCEL_INFO_BY_COUNTRY[destinationCountryCode]
  if (isReturnOrder) {
    if (isFixedForm) {
      parcelFields = PARCEL_INFO_BY_COUNTRY_FOR_RELABEL_FIXED_FORM[destinationCountryCode]
    } else {
      parcelFields = PARCEL_INFO_BY_COUNTRY_FOR_RELABEL[destinationCountryCode]
    }
  }
  return {
    recipient: {
      title: 'recipient_information',
      fields: RECEIPIENT_INFO_BY_COUNTRY[destinationCountryCode]
    },
    delivery: {
      title: 'address_delivery_instruction',
      fields: DELIVERY_ADDRESS_BY_COUNTRY[destinationCountryCode]
    },
    parcel: {
      title: 'parcel_information',
      fields: parcelFields
    }
  }
}

export const friendlyErrMessage = (intl, messages) => {
  return messages.map(mes => {
    if (mes.values) {
      return intl.formatMessage(
        {
          id: mes.id
        },
        { x: mes.values.x, y: mes.values.y }
      )
    }
    return intl.formatMessage({ id: mes.id })
  })
}

export const identifyHighValueParcels = ({ service, highValues, orders, isItemised }) => {
  const limitationOfVariableParcel = highValues.find(d => d.country === service.destination_country)
  if (!limitationOfVariableParcel) {
    return {
      needToContinueWithHighValue: false
    }
  }
  const valuableRequestByIndex = {}
  orders.forEach((order, index) => {
    const totalUnitValue = isItemised
      ? Object.values(order.items).reduce((preValue, item) => preValue + +item.goodsValue * (+item.quantity || 1), 0)
      : +order.goodsValue
    const isHigherThanMinimiseValue = totalUnitValue >= limitationOfVariableParcel?.value
    if (isHigherThanMinimiseValue) {
      valuableRequestByIndex[index] = {
        totalUnitValue,
        order,
        isHigherThanMinimiseValue
      }
    }
  })
  return {
    standardHighValue: limitationOfVariableParcel?.value,
    valuableRequestByIndex,
    currency: limitationOfVariableParcel?.currency,
    needToContinueWithHighValue: true
  }
}

export const buildItemsRequestData = (items, originCountry, destinationCountry) => {
  const SGCode = COUNTRIES.SG.toUpperCase()
  const MYCode = COUNTRIES.MY.toUpperCase()
  const result = compact(items).map(item => {
    return {
      description: item.customsDescription,
      unit_value: +item.goodsValue || 0,
      quantity: +item.quantityValue || 1,
      unit_weight: +item.weight || 0,
      ...(item.color && {
        color: item.color || ''
      }),
      origin_country: getOriginCountry(originCountry, destinationCountry),
      ...((destinationCountry === MYCode || destinationCountry === SGCode) && {
        taxes: {
          ...(destinationCountry === MYCode && {
            lvg: {
              ...(item.lvgNumber && { number: item.lvgNumber })
            }
          }),
          ...(destinationCountry === SGCode && {
            gst: {
              is_included: upperCase(item.gstIncluded) === GST_KEYS.YES,
              number: item.gstNumber
            }
          })
        }
      })
    }
  })
  return result
}

export const transformRelabeledParcelsToOrdersByKeyboard = (relabeledParcels, destinationCountry) => {
  if (!destinationCountry) return {}
  return relabeledParcels.reduce((acc, parcel, index) => {
    let itemByIndex = {}

    const isPH = destinationCountry === COUNTRIES.PH.toUpperCase()
    const hasItems = !isEmpty(parcel.items)

    if (!hasItems && !isPH) {
      itemByIndex[0] = {
        id: 0,
        goodsValue: parcel.goodsValue,
        quantityValue: 1,
        customsDescription: parcel.customsDescription,
        unitWeight: parcel.weight || ''
      }
    } else {
      itemByIndex = (parcel.items || []).reduce((acc, item, index) => {
        acc[index] = {
          id: index,
          goodsValue: item.goodsValue,
          quantityValue: item.quantityValue || 1,
          customsDescription: item.customsDescription,
          weight: item.weight || '',
          color: item.color,
          lvgNumber: item.lvgNumber,
          gstNumber: item.gstNumber,
          gstIncluded: item.gstIncluded ? GST_KEYS.YES : GST_KEYS.NO
        }
        return acc
      }, {})
    }

    acc[index] = {
      id: index,
      goodsValue: parcel.goodsValue,
      customsDescription: parcel.customsDescription,
      name: parcel.name,
      contactNumber: parcel.contactNumber,
      addressLine1: parcel.addressLine1,
      city: parcel.city,
      stateProvince: parcel.stateProvince,
      postCode: parcel.postCode,
      items: itemByIndex,
      requestedTrackingID: parcel.requestedTrackingID,
      sourceOrderID: parcel.sourceOrderID,
      weight: parcel.weight,
      cashOnDelivery: parcel.cashOnDelivery,
      insuredValue: parcel.insuredValue,
      email: parcel.email,
      deliveryInstructions: parcel.deliveryInstructions
    }
    return acc
  }, {})
}
