import React, { useContext, useMemo } from 'react'
import { MMCC_OC_STEP, SUPPORTED_DOCUMENT_STATUS } from './constants'
import { SupportedDocument } from './SupportedDocument'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { mpTrackAddedDOFile, mpTrackSkipDOFile } from 'containers/FPLLogging/mixpanel'
import { SERVICE_TYPE, SERVICE_TYPE_LABEL_IN_MIXPANEL } from 'containers/FPLCommon/constants'
import { trackAddedDOFile, trackSkipDOFile } from 'containers/FPLLogging/rum'

const UploadAdditionalFileStep = () => {
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const {
    additionalDocumentUploadStatus,
    additionalDocumentFiles,
    additionalDocumentErrors,
    additionalDocumentFileNames,
    selectedService
  } = ocState

  const mpCustomsClearanceType = useMemo(() => SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type], [selectedService])

  const nameInKey = useMemo(() => {
    if (selectedService.type === SERVICE_TYPE.B2B_BUNDLE) {
      return {
        headerName: 'upload_addtional_document',
        skipButtonName: 'skip_upload_addtional_document'
      }
    }
    return {
      headerName: 'delivery_order',
      skipButtonName: 'skip_upload_delivery_order'
    }
  }, [selectedService])

  const handleSkip = () => {
    updateOCState({
      additionalDocumentUploadStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW,
      currentStep: MMCC_OC_STEP.REVIEW,
      additionalDocumentFiles: [],
      additionalDocumentErrors: [],
      additionalDocumentFileNames: []
    })
    mpTrackSkipDOFile({ orderType: mpCustomsClearanceType })
    trackSkipDOFile({ orderType: mpCustomsClearanceType })
  }

  const editStep = () => {
    updateOCState({
      additionalDocumentUploadStatus: additionalDocumentFiles.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
        : SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD
    })
  }

  const trackAddedDeliveryUploadedFiles = (fileNames: string[], fileInfo) => {
    const extensions = fileNames.map(name => {
      const ext = name.split('.').pop()
      return ext ? ext.toUpperCase() : ''
    })
    const trackingData = {
      fileType: extensions.join(', '),
      fileSize: `${fileInfo.size}${fileInfo.unit}`,
      numberOfFiles: fileInfo.totalFiles,
      orderType: mpCustomsClearanceType
    }
    mpTrackAddedDOFile(trackingData)
    trackAddedDOFile(trackingData)
  }

  const saveFiles = (uploadedResult, fileErrors, fileNames) => {
    updateOCState({
      additionalDocumentFiles: uploadedResult,
      additionalDocumentErrors: fileErrors,
      additionalDocumentFileNames: fileNames,
      additionalDocumentUploadStatus: fileErrors.length
        ? SUPPORTED_DOCUMENT_STATUS.UPLOADED_FAIL
        : SUPPORTED_DOCUMENT_STATUS.UPLOADED_SUCCESS
    })
  }

  const goToNextStep = fileInfo => {
    updateOCState({
      currentStep: MMCC_OC_STEP.REVIEW,
      additionalDocumentUploadStatus: SUPPORTED_DOCUMENT_STATUS.IN_PREVIEW
    })
    trackAddedDeliveryUploadedFiles(additionalDocumentFileNames, fileInfo)
  }

  const handleReset = () => {
    updateOCState({
      additionalDocumentFiles: [],
      additionalDocumentUploadStatus: SUPPORTED_DOCUMENT_STATUS.READY_TO_UPLOAD,
      additionalDocumentErrors: [],
      additionalDocumentFileNames: []
    })
  }

  return (
    <SupportedDocument
      skipButtonIntlKey={nameInKey.skipButtonName}
      headerIntlKey={nameInKey.headerName}
      status={additionalDocumentUploadStatus}
      errors={additionalDocumentErrors}
      fileNames={additionalDocumentFileNames}
      uploadedFiles={additionalDocumentFiles}
      onEditStep={editStep}
      onSave={saveFiles}
      onSkip={handleSkip}
      onNext={goToNextStep}
      onReset={handleReset}
    />
  )
}

export { UploadAdditionalFileStep }
