import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, T, Tooltip } from '@nv/react-commons/src/Components'
import { faGlobe } from '@fa-pro-light/faGlobe'
import { getPartnerDetail } from 'services/api/fplApi'
import { ROUTES } from 'containers/Base/constants'
import { StyledOptionCard, StyledGreyText, StyledTextBlock, OrderTypeLabel, StyledVerticalBar } from './styles'
import CircleIcon from 'components/CircleIcon'
import { mpTrackCreatedOrder } from 'containers/FPLLogging/mixpanel'
import { CREATION_SOURCE, PAGE_NAMES } from 'containers/FPLLogging/constants'
import { trackCreatedOrder } from 'containers/FPLLogging/rum'

const CardWithTooltip = ({ isLoading, isDisable, children }) => {
  const handleClickLink = () => {
    mpTrackCreatedOrder({ pageName: PAGE_NAMES[ROUTES.FPL_OC], source: CREATION_SOURCE.DASH })
    trackCreatedOrder({ pageName: PAGE_NAMES[ROUTES.FPL_OC], source: CREATION_SOURCE.DASH })
  }
  const tooltipId = isLoading
    ? 'international_checking_partner_configuration'
    : 'international_checking_partner_unavailable'
  return isDisable ? (
    <Tooltip title={<T id={tooltipId} />} placement='bottom'>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <Link to={ROUTES.FPL_OC} onClick={handleClickLink}>
      {children}
    </Link>
  )
}

const INITITAL_STATE = {
  isLoading: false,
  partner: {}
}
export const CreateInternationalOrderButtonV1 = ({ shipperId }) => {
  const [state, setState] = useState(INITITAL_STATE)
  const { isLoading, partner } = state
  const isEmptyPartner = !Object.keys(partner).length
  const getPartner = useCallback(async () => {
    const response = await getPartnerDetail(shipperId)

    let stateData = { isLoading: false }
    if (response.ok) {
      stateData = { ...stateData, partner: response.data.data }
    }

    setState(prevState => ({ ...prevState, ...stateData }))
  }, [shipperId])

  const isDisable = useMemo(() => isEmptyPartner || isLoading, [isEmptyPartner, isLoading])

  useEffect(() => {
    if (shipperId && isEmptyPartner) {
      setState(prevState => ({ ...prevState, isLoading: true }))
      getPartner()
    }
  }, [getPartner, shipperId, isEmptyPartner])

  return (
    <CardWithTooltip isDisable={isDisable} isLoading={isLoading}>
      <StyledOptionCard title={isLoading ? <StyledVerticalBar /> : null} disabled={isEmptyPartner}>
        <Row type='flex' align='middle'>
          <CircleIcon icon={faGlobe} size='big' disabled={isEmptyPartner} />
          <StyledTextBlock>
            <OrderTypeLabel id='create_international_order' disabled={isEmptyPartner} />
            <StyledGreyText>
              <T id='create_international_order_description' />
            </StyledGreyText>
          </StyledTextBlock>
        </Row>
      </StyledOptionCard>
    </CardWithTooltip>
  )
}

CardWithTooltip.propTypes = {
  isLoading: PropTypes.bool,
  isDisable: PropTypes.bool,
  children: PropTypes.node
}
CreateInternationalOrderButtonV1.propTypes = {
  shipperId: PropTypes.number
}

CreateInternationalOrderButtonV1.defaultProps = {
  partner: {}
}
