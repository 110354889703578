import React, { useContext, useMemo, useState, useRef } from 'react'
import Button from 'components/Button'
import { Col, Row, T } from '@nv/react-commons/src/Components'
import { faArrowLeft } from '@fa-pro-light/faArrowLeft'
import { faBoxes } from '@fa-pro-light/faBoxes'
import { ButtonFullWidth, EndedDivBlock, HorizontalSeperateLine, InputZone, StyledMinCard } from './styles'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { RecipientInSummary } from './RecipientInSummary'
import {
  KEYBOARD_INPUT_STATUS,
  MMCC_OC_STEP,
  DEFAULT_MMCC_B2B_FORM_FIELD,
  MMCC_B2B_BOX_FORM_DEFAULT_VALUE
} from './constants'
import { PreviewStepInfo } from './PreviewStepInfo'
import { TRACK_ADDED_INTL_ORDER_DETAIL } from 'containers/FPLLogging/constants'
import { mpTrackAddedIntlOrderDetail } from 'containers/FPLLogging/mixpanel'
import { BoxSection } from './BoxSection'
import { max, toNumber } from 'lodash'
import { Text } from 'components/Text'
import { RECIPIENT_MMCC_B2B_FORM_FIELDS, RECIPIENT_MMCC_B2B_FORM_FIELDS_FOR_GLOBAL_COUNTRY } from './FormFields'
import { CreationForm } from './CreationForm'
import {
  SERVICE_TYPE_LABEL_IN_MIXPANEL,
  ORDER_CREATE_SOURCE,
  CREATION_METHODS,
  INPUT_METHODS,
  GLOBAL_COUNTRY_CODE
} from 'containers/FPLCommon/constants'
import { trackAddedIntlOrderDetail } from 'containers/FPLLogging/rum'

export const MMCCKeyboard = () => {
  const recipientFormRef = useRef(null)
  const boxFormRef = useRef(null)
  const [activeBoxNo, setActiveBoxNo] = useState(0)
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const {
    keyboardInputStatus,
    selectedService,
    mmccB2BFormGroup,
    activeGroupId,
    customsCurrency,
    visibleAddBoxForm,
    destinationCountryCode
  } = ocState
  const totalValuesInPreviewOrder = useMemo(() => {
    let totalBox = 0
    let totalGoodsValue = 0
    const totalValidRecipient = Object.keys(mmccB2BFormGroup).length
    Object.values(mmccB2BFormGroup).forEach(group => {
      const validBoxLength = Object.values(group.b2bBoxes).filter(box => box.isConfirmed).length
      totalBox += validBoxLength
      const goodValuesInGroup = Object.values(group.b2bBoxes).reduce(
        (total, order) => total + toNumber(order.goodsValue),
        0
      )
      totalGoodsValue += goodValuesInGroup
    })
    return {
      totalBox,
      totalGoodsValue,
      totalValidRecipient
    }
  }, [mmccB2BFormGroup])

  // Only use for MMCC B2B
  const formFields = useMemo(() => {
    if (destinationCountryCode === GLOBAL_COUNTRY_CODE) {
      return RECIPIENT_MMCC_B2B_FORM_FIELDS_FOR_GLOBAL_COUNTRY
    }
    return RECIPIENT_MMCC_B2B_FORM_FIELDS
  }, [selectedService])

  const handleChangeCreationMethod = method => {
    updateOCState({ ocMethod: method })
  }

  const saveOrders = () => {
    const validateAndUpdateState = (recipientError, boxError = null) => {
      if (!recipientError && !boxError) {
        // Update state with confirmed box information
        updateOCState({
          keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_PREVIEW,
          currentStep: MMCC_OC_STEP.COMMERCIAL_INVOICE,
          mmccB2BFormGroup: {
            ...mmccB2BFormGroup,
            [activeGroupId]: {
              ...mmccB2BFormGroup[activeGroupId],
              b2bBoxes: {
                ...mmccB2BFormGroup[activeGroupId].b2bBoxes,
                [activeBoxNo]: {
                  ...mmccB2BFormGroup[activeGroupId].b2bBoxes[activeBoxNo],
                  isConfirmed: true
                }
              }
            }
          }
        })

        // Track the valid boxes
        const totalValidBoxes = Object.values(mmccB2BFormGroup).reduce((acc, group) => {
          return acc + Object.values(group.b2bBoxes).filter(box => box.isConfirmed).length
        }, 0)

        const { PROPS } = TRACK_ADDED_INTL_ORDER_DETAIL

        const trackingData = {
          [PROPS.METHOD]: CREATION_METHODS[ORDER_CREATE_SOURCE.KEYBOARD],
          [PROPS.NUMBER_OF_VALID_ORDER]: totalValidBoxes,
          [PROPS.NUMBER_OF_INVALID_ORDER]: 0,
          [PROPS.TOTAL_ORDERS]: totalValidBoxes,
          [PROPS.ORDER_TYPE]: SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
        }
        mpTrackAddedIntlOrderDetail(trackingData)
        trackAddedIntlOrderDetail(trackingData)
      }
    }
    recipientFormRef?.current?.validateFields(recipientError => {
      if (visibleAddBoxForm) {
        boxFormRef?.current?.validateFields(boxError => {
          validateAndUpdateState(recipientError, boxError)
        })
      } else {
        validateAndUpdateState(recipientError)
      }
    })
  }

  const handleEditStep = () => {
    updateOCState({ keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING, visibleAddBoxForm: false })
    const lastedBoxNo = max(Object.keys(mmccB2BFormGroup[activeGroupId].b2bBoxes))
    setActiveBoxNo(+lastedBoxNo)
  }

  const appendNewRecipient = () => {
    const newmmccB2BFormGroup = { ...mmccB2BFormGroup }
    const nextActiveGroupId = max(Object.values(newmmccB2BFormGroup).map(g => g.id)) + 1
    updateOCState({
      activeGroupId: nextActiveGroupId,
      visibleAddBoxForm: true,
      mmccB2BFormGroup: {
        ...mmccB2BFormGroup,
        [activeGroupId]: {
          ...mmccB2BFormGroup[activeGroupId],
          b2bBoxes: {
            ...mmccB2BFormGroup[activeGroupId].b2bBoxes,
            [activeBoxNo]: {
              ...mmccB2BFormGroup[activeGroupId].b2bBoxes[activeBoxNo],
              isConfirmed: true
            }
          }
        },
        [nextActiveGroupId]: {
          ...DEFAULT_MMCC_B2B_FORM_FIELD,
          id: nextActiveGroupId
        }
      }
    })
    setActiveBoxNo(0)
  }

  const addNewRecipient = () => {
    recipientFormRef?.current?.validateFields(recipientError => {
      if (visibleAddBoxForm) {
        boxFormRef.current?.validateFields(boxError => {
          if (!recipientError && !boxError) {
            appendNewRecipient()
          }
        })
      } else {
        if (!recipientError) {
          appendNewRecipient()
        }
      }
    })
  }

  const selectFormGroup = groupId => {
    recipientFormRef?.current?.validateFields(recipientError => {
      if (visibleAddBoxForm) {
        boxFormRef.current?.validateFields(boxError => {
          if (!recipientError && !boxError) {
            const updatedMMCCB2BFormGroup = {
              ...mmccB2BFormGroup,
              [activeGroupId]: {
                ...mmccB2BFormGroup[activeGroupId],
                b2bBoxes: {
                  ...mmccB2BFormGroup[activeGroupId].b2bBoxes,
                  [activeBoxNo]: {
                    ...mmccB2BFormGroup[activeGroupId].b2bBoxes[activeBoxNo],
                    isConfirmed: true
                  }
                }
              }
            }
            updateOCState({
              activeGroupId: groupId,
              visibleAddBoxForm: !Object.values(mmccB2BFormGroup[groupId].b2bBoxes).length,
              mmccB2BFormGroup: updatedMMCCB2BFormGroup
            })
            const lastedBoxNo = max(Object.keys(mmccB2BFormGroup[groupId].b2bBoxes))
            setActiveBoxNo(+lastedBoxNo)
          }
        })
      } else {
        if (!recipientError) {
          const updatedMMCCB2BFormGroup = {
            ...mmccB2BFormGroup,
            [activeGroupId]: {
              ...mmccB2BFormGroup[activeGroupId],
              b2bBoxes: {
                ...mmccB2BFormGroup[activeGroupId].b2bBoxes,
                [activeBoxNo]: {
                  ...mmccB2BFormGroup[activeGroupId].b2bBoxes[activeBoxNo],
                  isConfirmed: true
                }
              }
            }
          }
          updateOCState({
            activeGroupId: groupId,
            visibleAddBoxForm: !Object.values(mmccB2BFormGroup[groupId].b2bBoxes).length,
            mmccB2BFormGroup: updatedMMCCB2BFormGroup
          })
          const lastedBoxNo = max(Object.keys(mmccB2BFormGroup[groupId].b2bBoxes))
          setActiveBoxNo(+lastedBoxNo)
        }
      }
    })
  }

  const deleteFormGroup = groupId => {
    const findGroupIndex = groupId => Object.values(mmccB2BFormGroup).findIndex(g => g.id === groupId)

    const deletedGroupIndex = findGroupIndex(groupId)
    const newmmccB2BFormGroup = { ...mmccB2BFormGroup }
    delete newmmccB2BFormGroup[groupId]

    const previousGroup = Object.values(mmccB2BFormGroup)[deletedGroupIndex - 1]
    const nextGroup = Object.values(mmccB2BFormGroup)[deletedGroupIndex + 1]

    if (previousGroup) {
      updateOCState({
        activeGroupId: groupId === activeGroupId ? previousGroup.id : activeGroupId,
        mmccB2BFormGroup: newmmccB2BFormGroup,
        visibleAddBoxForm: groupId === activeGroupId ? false : visibleAddBoxForm
      })

      const lastedBoxNo = max(Object.keys(newmmccB2BFormGroup[previousGroup.id].b2bBoxes))
      setActiveBoxNo(+lastedBoxNo)
      return
    }

    if (nextGroup) {
      updateOCState({
        activeGroupId: groupId === activeGroupId ? nextGroup.id : activeGroupId,
        mmccB2BFormGroup: newmmccB2BFormGroup,
        visibleAddBoxForm: groupId === activeGroupId ? false : visibleAddBoxForm
      })

      const lastedBoxNo = max(Object.keys(newmmccB2BFormGroup[nextGroup.id].b2bBoxes))
      setActiveBoxNo(+lastedBoxNo)
      return
    }

    newmmccB2BFormGroup[activeGroupId] = {
      id: activeGroupId,
      address: null,
      b2bBoxes: {
        0: MMCC_B2B_BOX_FORM_DEFAULT_VALUE
      }
    }

    updateOCState({
      mmccB2BFormGroup: newmmccB2BFormGroup,
      visibleAddBoxForm: true
    })
  }

  const changeRecipientValues = values => {
    updateOCState({
      mmccB2BFormGroup: {
        ...mmccB2BFormGroup,
        [activeGroupId]: {
          ...mmccB2BFormGroup[activeGroupId],
          address: {
            ...mmccB2BFormGroup[activeGroupId].address,
            ...values
          }
        }
      }
    })
  }

  return (
    <>
      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_SHOWING && (
        <>
          <Row gutter={14}>
            <Col span={18}>
              <StyledMinCard hoverable={false}>
                <Button size='small' icon={faArrowLeft} onClick={() => handleChangeCreationMethod(undefined)}>
                  <T id='choose_input' allCaps />
                </Button>
                <HorizontalSeperateLine className='mt-2 mb-2' />

                <InputZone>
                  <Text id='recipient_information' type='bold' />

                  <CreationForm
                    ref={recipientFormRef}
                    formFields={formFields}
                    dataSource={mmccB2BFormGroup[activeGroupId].address}
                    country={destinationCountryCode}
                    onChange={changeRecipientValues}
                    customsCurrency={customsCurrency}
                  />

                  <BoxSection
                    formRef={boxFormRef}
                    activeBoxNo={activeBoxNo}
                    setActiveBoxNo={newBoxNo => setActiveBoxNo(newBoxNo)}
                  />
                </InputZone>
                <HorizontalSeperateLine className='mt-2 mb-2' />
                <EndedDivBlock>
                  <Button onClick={addNewRecipient} type='primary' size='medium'>
                    <T id='add_new_recipient' allCaps />
                  </Button>
                </EndedDivBlock>
              </StyledMinCard>
            </Col>
            <Col span={6}>
              <RecipientInSummary
                totalRecipient={Object.keys(mmccB2BFormGroup).length}
                orderFormGroup={mmccB2BFormGroup}
                activeGroupId={activeGroupId}
                onSelectFormGroup={selectFormGroup}
                onDeleteFormGroup={deleteFormGroup}
              />
              <ButtonFullWidth type='primary' size='medium' onClick={saveOrders}>
                <T id='save_and_continue' allCaps />
              </ButtonFullWidth>
            </Col>
          </Row>
        </>
      )}

      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_PREVIEW && (
        <PreviewStepInfo
          quantity={totalValuesInPreviewOrder.totalBox}
          unit={customsCurrency}
          creationMethod={INPUT_METHODS.KEYBOARD}
          totalGoodsValue={totalValuesInPreviewOrder.totalGoodsValue}
          totalRecipient={totalValuesInPreviewOrder.totalValidRecipient}
          titleIntlKey={'total_recipient'}
          icon={faBoxes}
          onEditStep={handleEditStep}
        />
      )}
    </>
  )
}
