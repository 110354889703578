import _ from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import { NvApi, nvCall } from '@nv/react-commons/src/Services'

import dashApi from 'services/api/dashApi'
import { shipperCreators, userCreators, userTypes } from '../Base/redux'
import { updateLocale } from '../Base/saga'
import { mixpanelTrackLogIn } from '../../components/Mixpanel/helpers'
import { mpTrackFirstLoginDate } from 'containers/FPLLogging/mixpanel'
import { trackEvent } from '../../utils/GoogleAnalytics/GAEventTracker'
import { INVALID_LOGIN, LOGIN } from '../../utils/GoogleAnalytics/events'

const { loginSuccess, loginFailure } = userCreators
const { getShipperSuccess, selectShipper } = shipperCreators

export function mapShippers (user) {
  const shippers = user?.shippers
  return _.mapValues(_.groupBy(shippers, 'id'), x => x[0])
}

export function mapSubShippers (getSubShippersResponse, isCorporate) {
  const dataPath = isCorporate ? 'data' : 'details'
  const subShippers = getSubShippersResponse?.data?.[dataPath] ?? {}
  return _.mapValues(_.groupBy(subShippers, 'id'), x => x[0])
}

export function * login (action) {
  const { data, options } = action

  const loginResponse = yield nvCall(dashApi.login, data, options)
  const user = loginResponse.data
  let shippers = mapShippers(user)
  let allowFulfillmentService = false

  if (!loginResponse.ok) {
    yield call(trackEvent, INVALID_LOGIN)
    let { error } = loginResponse.data
    if (_.isEmpty(error)) {
      error = { title: 'Timeout' }
    }
    yield put(loginFailure(error))
    return
  }

  if (_.isEmpty(shippers)) {
    yield put(loginFailure({ title: 'No shipper is found' }))
    return
  }
  yield call(NvApi.setAuthToken, user.accessToken)
  const ocSettings = yield nvCall(dashApi.getSettings, 'order_create', user.defaultShipperId)
  const fulfillmentSettings = yield nvCall(dashApi.getSettings, 'cross_border', user.defaultShipperId)

  let getSubShippersResponse
  let isCorporateHQ
  if (ocSettings.ok) {
    isCorporateHQ = _.get(ocSettings, 'data.orderCreate.isCorporateHq')
    const isMarketplace = _.get(ocSettings, 'data.orderCreate.isMarketplace')
    if (isCorporateHQ || isMarketplace) {
      getSubShippersResponse = yield nvCall(dashApi.getSubShippers, user.defaultShipperId)
    }
  }

  if (user.masterShipper) {
    const corporateBranchResponse = yield nvCall(dashApi.getShipperBranch, user.masterShipper.id, user.defaultShipperId)
    if (corporateBranchResponse?.ok) {
      shippers[user.defaultShipperId].externalRef = corporateBranchResponse.data.externalRef
    }
    const countResp = yield nvCall(dashApi.getShippersCount, user.masterShipper.id)
    if (countResp?.ok) {
      const count = countResp?.data?.data ?? {}
      yield put(shipperCreators.getSubShippersCount(count))
    }
  }
  if (getSubShippersResponse?.ok) {
    const subShippers = mapSubShippers(getSubShippersResponse, isCorporateHQ)
    shippers = _.merge(shippers, subShippers)
  }

  if (fulfillmentSettings?.ok) {
    allowFulfillmentService = fulfillmentSettings.data?.crossBorder?.allowFulfillmentService
  }

  yield put(getShipperSuccess(shippers))
  yield call(updateLocale, user)
  yield put(selectShipper(user.defaultShipperId))

  let method = 'Email'
  if (options?.params?.grant_type === 'otp') {
    method = 'Phone Number'
  }
  yield put(loginSuccess(_.omit(user, 'shippers')))
  yield call(mixpanelTrackLogIn, method, user)
  yield call(mpTrackFirstLoginDate, allowFulfillmentService)
  yield call(trackEvent, LOGIN)
}

export function * loginWithToken (action) {
  const {
    data: { token, shipperId }
  } = action

  yield call(NvApi.setAuthToken, token)
  yield call(NvApi.setShipper, shipperId)
  const getUserResponse = yield nvCall(dashApi.getUser)

  if (getUserResponse.ok) {
    const user = getUserResponse.data

    yield call(updateLocale, user)
    const shippers = mapShippers(user)
    if (user.masterShipper) {
      const corporateBranchResponse = yield nvCall(dashApi.getShipperBranch, user.masterShipper.id, shipperId)
      if (corporateBranchResponse.ok) {
        shippers[shipperId].externalRef = corporateBranchResponse.data.externalRef
      }
      const countResp = yield nvCall(dashApi.getShippersCount, user.masterShipper.id)
      if (countResp.ok) {
        const count = countResp?.data?.data ?? {}
        yield put(shipperCreators.getSubShippersCount(count))
      }
    }
    yield put(getShipperSuccess(shippers))
    yield put(selectShipper(shipperId))
    yield put(loginSuccess({ ..._.omit(user, 'shippers'), accessToken: token }))
  } else {
    yield put(loginFailure(getUserResponse.data.error))
  }
}

export default function * defaultSaga () {
  yield takeLatest(userTypes.LOGIN_REQUEST, login)
  yield takeLatest(userTypes.LOGIN_WITH_TOKEN, loginWithToken)
}
