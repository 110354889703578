export enum SERVICE_TYPE {
  LM = 1,
  CC = 2,
  CCLM = 3,
  MMCCLM = 4,
  MMCCB2C = 5,
  E2E = 6,
  DM = 7,
  MMCCB2B = 8,
  B2B_BUNDLE = 9
}

export const GLOBAL_COUNTRY_CODE = 'GLOBAL'

export const GLOBAL_CURRENCY = 'USD'

export enum OCMethod {
  KEYBOARD = 7,
  UPLOAD = 8
}

export const INTL_KEY_BY_SERVICE_TYPE = {
  [SERVICE_TYPE.LM]: 'international.service_type.lm',
  [SERVICE_TYPE.CC]: 'international.service_type.cc',
  [SERVICE_TYPE.CCLM]: 'international.service_type.cclm',
  [SERVICE_TYPE.MMCCLM]: 'international.service_type.mmcclm',
  [SERVICE_TYPE.MMCCB2C]: 'international.service_type.mmccb2c',
  [SERVICE_TYPE.E2E]: 'international.service_type.e2e',
  [SERVICE_TYPE.DM]: 'international.service_type.dm',
  [SERVICE_TYPE.MMCCB2B]: 'international.service_type.mmccb2b',
  [SERVICE_TYPE.B2B_BUNDLE]: 'international.service_type.b2b_bundle'
}

export const SERVICE_TYPE_LABEL_IN_MIXPANEL = {
  [SERVICE_TYPE.LM]: 'LM',
  [SERVICE_TYPE.CC]: 'CC',
  [SERVICE_TYPE.CCLM]: 'CCLM',
  [SERVICE_TYPE.MMCCLM]: 'MMCCLM',
  [SERVICE_TYPE.MMCCB2C]: 'MMCC B2C',
  [SERVICE_TYPE.E2E]: 'E2E',
  [SERVICE_TYPE.DM]: 'DM',
  [SERVICE_TYPE.MMCCB2B]: 'MMCC B2B',
  [SERVICE_TYPE.B2B_BUNDLE]: 'B2B Bundle'
}

export const MIN_TID_LENGTH = 1
export const MAX_TID_LENGTH = 29
export const SPECIAL_COUNTRY_CODES = {
  HK: 'HK'
}

export const ALLOWED_COUNTRY = {
  SG: 'SG',
  MY: 'MY',
  TH: 'TH',
  VN: 'VN',
  ID: 'ID',
  PH: 'PH'
}

export const ORDER_CREATE_SOURCE = {
  KEYBOARD: 7,
  CSV: 8
}

export const CREATION_METHODS = {
  [ORDER_CREATE_SOURCE.CSV]: 'Bulk',
  [ORDER_CREATE_SOURCE.KEYBOARD]: 'Manual'
}

export const INPUT_METHODS = {
  KEYBOARD: 'KEYBOARD',
  FILE: 'FILE',
  NONE: 'NONE'
}

export const CURRENY_COUNTRY_LABELS = {
  SG: 'SGD',
  MY: 'MYR',
  TH: 'THB',
  VN: 'VND',
  ID: 'IDR',
  PH: 'PHP'
}

export const COUNTRIES_ALLOWANCE_REGEX = /^(US|GB|FR|DE|IT|ES|NL|BE|LU|IE|PT|DK|SE|NO|FI|CH|CA|AU|NZ|GR|IL|AT|BG|HR|HU|PL|CZ|RO|EE|LV|SK|SI|LT|CY|TR|MT|BR|CL|MX|CO|HK|TW|SA)$/
