import { isEmpty, trim, uniq, upperCase } from 'lodash'
import { COUNTRY_LABEL, TRANSLATION_KEY_BY_TYPE } from 'containers/FPLOrderDetails/constants'
import {
  DELIVERY_TIME_VALUE,
  DEFAULT_DELIVERY_TIME_VALUE,
  FRIENDLY_FIELD_NAME,
  FRIENDLY_ERROR_MESSAGE,
  ORDER_REQUEST_STATUS_LABEL,
  ORDER_REQUEST_HEADER_FIELDS
} from './constants'
import { ORDER_CREATE_SOURCE } from 'containers/FPLCommon/constants'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import { STANDARD_MMCC_FIELDS, BUNDLE_REQUIRED_OPTIONS } from 'containers/FPLMMCCOrderCreation/constants'

export const parseJSONString = str => {
  try {
    const json = JSON.parse(str)
    return json
  } catch (e) {
    return {}
  }
}

export const friendlyItemsDescription = items => {
  const results = []
  items.forEach(reqItem => {
    if (reqItem.description && !results.includes(reqItem.description)) {
      results.push(reqItem.description)
    }
  })
  return results.length > 1 ? results.join(', ') : results[0]
}

export const parseOrderRequestList = list => {
  return list.map(item => {
    const jsonRequest = parseJSONString(item.request_body)
    if (jsonRequest.items && jsonRequest.items.length) {
      jsonRequest.parcel_details.customs_description = friendlyItemsDescription(jsonRequest.items)
    }
    return {
      ...item,
      request_body: jsonRequest,
      response_body: parseJSONString(item.response_body)
    }
  })
}

export const transformE2EOrderRequest = data => {
  if (!data) return {}
  return {
    name: data.request_body?.to?.name,
    contactNumber: data.request_body?.to?.contact_number,
    email: data.request_body?.to?.contact_email,
    addressLine1: data.request_body?.to?.address_line1,
    city: data.request_body?.to?.city,
    stateProvince: data?.request_body?.to?.state_province,
    postCode: data.request_body?.to?.post_code,
    countryCode: data.request_body?.to?.country_code,
    cashOnDelivery: data.request_body?.delivery?.cash_on_delivery,
    requestedTrackingID: data.request_body?.requested_tracking_id,
    taxNumber: data.request_body?.parcel_details?.tax_id,
    customsDescription: data.request_body?.parcel_details?.customs_description,
    goodsValue: data.request_body?.parcel_details?.value,
    sourceOrderID: data.request_body?.source_order_id,
    deliveryInstructions: data.request_body?.delivery?.delivery_instructions,
    weight: data.request_body?.parcel_details?.weight,
    insuredValue: data.request_body?.delivery?.insured_value,
    sourceReferenceId: data.request_body?.source_reference_id,
    toCountry: data.request_body?.to?.country_code
  }
}

export const transformB2BBundleOrderRequest = data => {
  if (!data) return {}
  const { request_body } = data
  const { to, parcel_details, requested_tracking_id, source_order_id } = request_body || {}
  const { weight, dimensions, customs_description, customs_currency, quantity, value, b2b_bundle } =
    parcel_details || {}
  const { hs_code, requested_piece_tracking_ids, documents_required } = b2b_bundle || {}
  const {
    name,
    address_line1,
    address_line2,
    city,
    contact_email,
    contact_number,
    post_code,
    state_province,
    country_code
  } = to || {}
  const { length, width, height } = dimensions || {}
  return {
    [STANDARD_MMCC_FIELDS.NAME]: name,
    [STANDARD_MMCC_FIELDS.CONTACT]: contact_number,
    [STANDARD_MMCC_FIELDS.EMAIL]: contact_email,
    [STANDARD_MMCC_FIELDS.POSTCODE]: post_code,
    [STANDARD_MMCC_FIELDS.STATE_PROVINCE]: state_province,
    [STANDARD_MMCC_FIELDS.CITY]: city,
    [STANDARD_MMCC_FIELDS.RDO_REQUIRED]: (documents_required || []).includes(BUNDLE_REQUIRED_OPTIONS.RDO),
    [STANDARD_MMCC_FIELDS.GRN_REQUIRED]: (documents_required || []).includes(BUNDLE_REQUIRED_OPTIONS.GRN),
    [STANDARD_MMCC_FIELDS.NUMBER_OF_CARTONS]: quantity,
    [STANDARD_MMCC_FIELDS.TOTAL_CARTON_VALUE]: value,
    [STANDARD_MMCC_FIELDS.SHIPPER_ORDER_NUMBER]: source_order_id,
    [STANDARD_MMCC_FIELDS.WEIGHT]: weight,
    [STANDARD_MMCC_FIELDS.LENGTH]: length,
    [STANDARD_MMCC_FIELDS.WIDTH]: width,
    [STANDARD_MMCC_FIELDS.HEIGHT]: height,
    [STANDARD_MMCC_FIELDS.DESCRIPTION]: customs_description,
    [STANDARD_MMCC_FIELDS.HSCODE]: hs_code,
    [STANDARD_MMCC_FIELDS.ADDRESS_LINE_1]: address_line1,
    [STANDARD_MMCC_FIELDS.ADDRESS_LINE_2]: address_line2,
    [STANDARD_MMCC_FIELDS.REQUESTED_CARTON_TRACKING_NUMBERS]: (requested_piece_tracking_ids || []).join(', '),
    [STANDARD_MMCC_FIELDS.REQUESTED_TRACKING_ID]: requested_tracking_id,
    // other fields
    customsCurrency: customs_currency,
    destinationCountry: country_code
  }
}

export const transformOrderRequestParamsForE2E = (orderValues, dataSource, originCountry) => {
  const countryCode = orderValues?.toCountry || dataSource?.request_body?.to.country_code
  const itemsOrder = dataSource?.request_body?.items || dataSource?.items
  const items = itemsOrder && itemsOrder.length > 0 ? { items: itemsOrder } : {}
  const pickup = dataSource?.request_body?.pickup
  const returnAddress = dataSource?.request_body?.return
  const customCurrency = dataSource?.id
    ? dataSource?.request_body?.parcel_details?.customs_currency
    : orderValues?.goodsCurrency
  const isReturnOrder = dataSource?.request_body?.parcel_details?.is_relabel
  const originalParcelCustomsDescription = dataSource?.request_body?.parcel_details?.customs_description
  const originParcelValue = dataSource?.request_body?.parcel_details?.value
    ? +dataSource.request_body.parcel_details.value
    : null
  return {
    id: dataSource?.id,
    service_code: dataSource?.request_body?.service_code,
    ...(dataSource?.request_body?.type && {
      type: dataSource?.request_body?.type
    }),
    ...(dataSource?.request_body?.source_reference_id && {
      source_reference_id: dataSource?.request_body?.source_reference_id
    }),
    ...(orderValues?.requestedTrackingID && { requested_tracking_id: orderValues.requestedTrackingID }),
    ...(orderValues?.sourceOrderID && { source_order_id: orderValues.sourceOrderID }),
    from: dataSource?.request_body?.from,
    ...(pickup && { pickup }),
    ...(returnAddress && { return: returnAddress }),
    source: dataSource?.id ? dataSource?.request_body?.source : ORDER_CREATE_SOURCE.KEYBOARD,
    to: {
      name: orderValues?.name,
      address_line1: orderValues?.addressLine1,
      ...(orderValues?.city && { city: orderValues?.city }),
      ...(orderValues?.postCode && { post_code: orderValues.postCode }),
      country_code: upperCase(countryCode),
      contact_number: orderValues?.contactNumber,
      ...(orderValues?.email && { contact_email: orderValues.email }),
      ...(orderValues?.stateProvince && { state_province: orderValues.stateProvince })
    },
    parcel_details: {
      ...(orderValues?.taxNumber && { tax_id: orderValues?.taxNumber }),
      customs_description: orderValues?.customsDescription || originalParcelCustomsDescription,
      customs_currency: customCurrency,
      value: orderValues?.goodsValue ? +orderValues.goodsValue : originParcelValue,
      origin_country: COUNTRIES[originCountry]?.toUpperCase(),
      ...(orderValues?.weight && { weight: +orderValues.weight || 0 }),
      ...(isReturnOrder && { is_relabel: isReturnOrder })
    },
    delivery: {
      cash_on_delivery: +orderValues?.cashOnDelivery || 0,
      delivery_instructions: orderValues?.deliveryInstructions,
      insured_value: +orderValues?.insuredValue || 0
    },
    ...items
  }
}

export const transformOrderRequestParamsForB2BBundle = (newRequestValue, previousRequest, originCountry) => {
  const { request_body, id } = previousRequest || {}
  const {
    from,
    to,
    return: returnAddress,
    pickup,
    parcel_details,
    service_code,
    source_reference_id,
    type,
    source,
    source_order_id
  } = request_body || {}
  const { b2b_bundle, customs_currency, customs_description, value, dimensions, weight } = parcel_details || {}
  const {
    country_code,
    address_line1,
    address_line2,
    city,
    contact_email,
    contact_number,
    name,
    post_code,
    state_province
  } = to
  // New values from the form
  const { requestedCartonTrackingNumbers, requestedTrackingID, numberOfCartons } = newRequestValue
  return {
    id: id,
    service_code: service_code,
    ...(type && {
      type: type
    }),
    ...(source_reference_id && {
      source_reference_id: source_reference_id
    }),
    ...(requestedTrackingID && { requested_tracking_id: requestedTrackingID }),
    ...(source_order_id && { source_order_id }),
    from: from,
    ...(pickup && { pickup }),
    ...(returnAddress && { return: returnAddress }),
    source: id ? source : ORDER_CREATE_SOURCE.KEYBOARD,
    to: {
      name,
      address_line1: address_line1,
      address_line2: address_line2,
      city: city,
      post_code,
      country_code: upperCase(country_code),
      contact_number: contact_number,
      contact_email: contact_email,
      state_province: state_province
    },
    parcel_details: {
      customs_description,
      customs_currency,
      value,
      origin_country: COUNTRIES[originCountry]?.toUpperCase(),
      quantity: +numberOfCartons,
      ...(weight && { weight }),
      ...(dimensions && { dimensions }),
      b2b_bundle: {
        documents_required: b2b_bundle.documents_required,
        hs_code: b2b_bundle.hs_code,
        requested_piece_tracking_ids: requestedCartonTrackingNumbers
          ?.split(',')
          .filter(tid => !isEmpty(tid))
          .map(cartonTid => trim(cartonTid?.toUpperCase()))
      }
    }
  }
}

export const getResponseError = responseBody => {
  const validationErrors = responseBody?.error?.validation_errors || responseBody?.error
  const validationErrs = { ...validationErrors }
  delete validationErrs['details']
  const detailErrors = responseBody?.error?.details || []
  const otherErrors = {}
  detailErrors.forEach(detailError => {
    otherErrors[detailError.field] = detailError.message
  })
  return {
    validationErrors: validationErrs,
    otherErrors
  }
}

export const getDeliveryIndexByRange = timeSlot => {
  return DELIVERY_TIME_VALUE[timeSlot] !== undefined ? DELIVERY_TIME_VALUE[timeSlot] : DEFAULT_DELIVERY_TIME_VALUE
}

export const errorMessage = (error, intl) => {
  if (!error || (Object.values(error) && Object.values(error).length === 0)) return ''
  const errorKeys = Object.keys(error)
  const errorValues = Object.values(error)

  const str = errorValues.reduce((acc, next, index) => {
    let key = errorKeys[index]
    if (key.includes('items')) {
      key = `items.${key.split('.')[1]}`
    }
    next = next + ''
    const errorContent = next.substr(next.indexOf(' ') + 1)
    const fieldName = FRIENDLY_FIELD_NAME[key] ? intl.formatMessage({ id: FRIENDLY_FIELD_NAME[key] }) : ''
    const errMessage = FRIENDLY_ERROR_MESSAGE[errorContent]
      ? intl.formatMessage({ id: FRIENDLY_ERROR_MESSAGE[errorContent] })
      : errorContent
    const message = FRIENDLY_FIELD_NAME[key] ? `${fieldName} ${errMessage}` : next
    return `${acc ? `${acc}\n` : ''}${message}`
  }, '')
  return str.trim()
}

export const getColumnValue = (keyValues, orderRequest, intl) => {
  const rowData = keyValues.map(key => {
    const value = orderRequest?.key || ''
    switch (key) {
      case 'response_body':
        return errorMessage(value.error?.validation_errors || value.error, intl)
      case 'status':
        return intl.formatMessage({ id: ORDER_REQUEST_STATUS_LABEL[value] })
      case 'request_body.delivery.delivery_timeslot':
        return value ? `${value.start_time}-${value.end_time}` : ''
      default:
        return value
    }
  })
  return rowData
}

export const mappingOrderPrinters = orders => {
  return orders.map(order => {
    const parcel = {
      id: order.id,
      requestedTrackingId: order.response_body?.data?.tracking_id,
      cod: order.request_body?.delivery?.cash_on_delivery,
      parcelDescription: order.request_body?.items
        ? friendlyItemsDescription(order.request_body?.items)
        : order.request_body?.parcel_details?.customs_description
    }
    const receiver = {
      toName: order.request_body?.to?.name,
      toContact: order.request_body?.to?.contact_number,
      toAddress: {
        toAddress1: order.request_body?.to?.address_line1,
        toAddress2: order.request_body?.to?.address_line2,
        toCountry: COUNTRY_LABEL[order.request_body?.to?.country_code?.toUpperCase()],
        toPostCode: order.request_body?.to?.post_code
      }
    }
    const shipper = {
      fromName: order.request_body?.from?.name
    }
    return {
      parcel,
      receiver,
      shipper
    }
  })
}

export const fetchFile = url => {
  return fetch(url).then(response => {
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}`)
    }
    return response.blob()
  })
}

export const retrieveOrderRequest = (requests, intl) => {
  const contentData = requests.map(request => {
    const { response_body, request_body, parcel, status } = request
    const { to, delivery, parcel_details, source_order_id } = request_body
    const { customs_description } = parcel_details
    const { name, address_line1 } = to
    const trackingId = parcel ? response_body?.data?.tracking_id : ''
    const parcelType = parcel?.type ? TRANSLATION_KEY_BY_TYPE[parcel.type] : ''
    let remark = ''
    if (!parcel) {
      const err = response_body?.error?.validation_errors || response_body?.error
      remark = errorMessage(err, intl)
      if (remark.includes('\n')) {
        remark = uniq(remark.split('\n')).join('\n')
      }
    }
    return [
      trackingId,
      parcelType,
      source_order_id,
      name,
      address_line1,
      delivery?.cash_on_delivery || '',
      customs_description,
      remark,
      ORDER_REQUEST_STATUS_LABEL[status]
    ]
  })
  return {
    fields: Object.values(ORDER_REQUEST_HEADER_FIELDS).map(key => intl.formatMessage({ id: key })),
    data: contentData
  }
}
