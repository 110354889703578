import styled from 'styled-components'
import { Radio } from '@nv/react-commons/src/Components'
import { RadioGroup } from 'components/RadioGroup'
import { Colors } from 'themes'

export const StyledRadio = styled(Radio)`
  && {
    display: flex;
    flex: 1;
    min-width: 0;
    line-height: 6px;
    align-items: center;
    &&& {
      margin-top: 10px;
    }
    & > span:last-child {
      width: 100%;
    }
    span.ant-radio + * {
      padding-left: 12px;
    }

    &:hover {
      .ant-radio {
        .ant-radio-inner {
          border-color: ${Colors.cherryRed};
        }
      }
    }
  }
`

export const StyledRadioGroup = styled(RadioGroup)`
  .ant-radio-wrapper .ant-radio + span {
    padding: 0 12px;
  }
  margin-bottom: 4px;
`

export const StyledServiceCode = styled.span`
  && {
    color: ${Colors.disabled};
  }
`

export const StyledServiceDescription = styled.span`
  && {
    font-size: 16px;
    font-weight: normal;
    color: ${Colors.warmGrey};
  }
`

export const StyledGroupSeparator = styled.hr`
  border: none;
  border-top: 1px solid ${Colors.balanceBg};
  margin-top: 18px;
`

export const StyledServiceRow = styled.div`
  display: grid;
  grid-template-columns: auto 4px max-content 4px 1fr;
  align-items: center;
  width: 100%;
  gap: 0 8px;
  cursor: pointer;
  padding: 6px 0;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #fff0f2;
    .ant-radio {
      .ant-radio-inner {
        border-color: ${Colors.primary};
      }
    }
  }
`

export const StyledServiceType = styled.div<{ $maxWidth?: number }>`
  display: grid;
  grid-template-columns: ${props => `${props.$maxWidth}px 4px max-content 4px 2fr`};
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Colors.warmGrey};
`

export const StyledServiceDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  color: ${Colors.warmGrey};
`

interface CountryFlowProps {
  $maxOriginWidth: number
  $maxDestWidth: number
}

export const StyledCountryFlow = styled.div<CountryFlowProps>`
  display: grid;
  grid-template-columns: ${props =>
    `${Math.max(80, props.$maxOriginWidth * 8)}px 24px minmax(${Math.min(
      80,
      props.$maxDestWidth * 8
    )}px, ${props.$maxDestWidth * 8}px)`};
  align-items: center;
  width: 100%;
  gap: 4px;
`

export const StyledCountryCell = styled.span`
  padding: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
`

export const StyledArrow = styled.span`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledSeparator = styled.span`
  text-align: center;
  justify-self: center;
  padding-right: 16px;
`

export const StyledServiceGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledServiceInfo = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  color: ${Colors.warmGrey};
`

export const StyleText = styled.span`
  color: ${Colors.disabled};
  padding-left: 2px;
`

export const StyledFlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`
