import React, { useEffect } from 'react'
import styled, { withTheme } from 'styled-components'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { T } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { Vspace } from 'components/Vspace'
import { mpTrackLoadedErrorPage } from 'containers/FPLLogging/mixpanel'
import { trackLoadedErrorPage } from 'containers/FPLLogging/rum'

const StyledContainer = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
`
const Description = styled(Text)`
  && {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
  }
`

const StyledButton = styled(Button)`
  && {
    width: 160px;
  }
`

const FPLErrorPage = props => {
  const {
    theme: { clientErrorImg },
    pageName,
    errors
  } = props

  useEffect(() => {
    errors.forEach(error => {
      const { errorApi, errorMessage, errorStatus } = error
      const trackingData = {
        previousPage: pageName,
        errorApi,
        errorStatus,
        errorMessage
      }
      mpTrackLoadedErrorPage(trackingData)
      trackLoadedErrorPage(trackingData)
    })
  }, [errors, pageName])

  const handleReloadPage = () => {
    window.location.reload()
  }

  return (
    <StyledContainer>
      <img width={180} src={clientErrorImg} />
      <Vspace height={18} />
      <Description>
        <T id='international_server_error' />
      </Description>
      <StyledButton type='primary' size='small' data-analyticsid='4plReloadPage' onClick={handleReloadPage}>
        <T id='international_reload' allCaps />
      </StyledButton>
    </StyledContainer>
  )
}

FPLErrorPage.propTypes = {
  theme: PropTypes.object,
  pageName: PropTypes.string,
  errors: PropTypes.array
}

FPLErrorPage.defaultProps = {
  errors: []
}

export default compose(withTheme)(FPLErrorPage)
