import React, { useContext, useMemo, useRef } from 'react'
import { T, Row, Col } from '@nv/react-commons/src/Components'
import { ButtonFullWidth, CustomCardWrapper, FormHeader, HoverLink, NoMarginIcon, StyledIcon } from './styles'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { DEFAULT_B2B_BUNDLE_FORM_FIELD, KEYBOARD_INPUT_STATUS, MMCC_OC_STEP } from './constants'
import { CreationForm } from './CreationForm'
import { FORM_FIELDS_BY_SECTION } from './FormFields'
import { OrderSummary } from 'containers/FPLOrderCreate/OrderSummary'
import { faArrowLeft, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { Text } from 'components/Text'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { Colors } from 'themes'
import { max } from 'lodash'
import { PreviewStepInfo } from './PreviewStepInfo'
import { faBoxes } from '@fa-pro-light/faBoxes'
import Button from 'components/Button'
import { INPUT_METHODS } from 'containers/FPLCommon/constants'

export const B2BBundleKeyboard = () => {
  const formRef = useRef(null)
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const {
    keyboardInputStatus,
    b2bBundleFormGroup,
    activeGroupId,
    selectedService,
    customsCurrency,
    destinationCountryCode
  } = ocState

  const currentBundleIndex = useMemo(() => {
    return Object.keys(b2bBundleFormGroup).findIndex(bundleKey => Number(bundleKey) === activeGroupId)
  }, [b2bBundleFormGroup, activeGroupId])

  const inactiveBundles = useMemo(() => {
    const otherBundles = []
    Object.values(b2bBundleFormGroup).forEach(bundle => {
      if (bundle.id !== activeGroupId) {
        otherBundles.push(bundle)
      }
    })
    return otherBundles
  }, [b2bBundleFormGroup, activeGroupId])

  const totalB2BBundleGoodValues: number = useMemo(() => {
    if (!b2bBundleFormGroup) {
      return 0
    }
    return Object.values(b2bBundleFormGroup).reduce((res, item) => res + +item.totalCartonValue, 0)
  }, [b2bBundleFormGroup])

  const totalB2BBundleNumberOfCartons: number = useMemo(() => {
    if (!b2bBundleFormGroup) {
      return 0
    }
    return Object.values(b2bBundleFormGroup).reduce((res, item) => res + +item.numberOfCartons, 0)
  }, [b2bBundleFormGroup])

  const handleEditStep = () => {
    updateOCState({ keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING })
  }

  const changeOCForm = value => {
    const updatedFormGroup = { ...b2bBundleFormGroup }
    updatedFormGroup[activeGroupId] = {
      ...updatedFormGroup[activeGroupId],
      ...value
    }
    updateOCState({ b2bBundleFormGroup: updatedFormGroup })
  }

  const selectOrder = orderIndex => {
    formRef.current.validateFields(err => {
      if (!err) {
        updateOCState({ activeGroupId: Number(Object.keys(b2bBundleFormGroup)[orderIndex]) })
      }
    })
  }

  const changeCreationMethod = method => {
    updateOCState({ ocMethod: method })
  }

  const addNewB2BBundleBox = () => {
    formRef.current.validateFields(err => {
      if (!err) {
        handleAddNewBundle()
      }
    })
  }

  const submitB2BBundleOrder = () => {
    formRef.current.validateFields(err => {
      if (!err) {
        updateOCState({
          keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_PREVIEW,
          currentStep: MMCC_OC_STEP.COMMERCIAL_INVOICE
        })
      }
    })
  }

  const handleAddNewBundle = () => {
    const newB2BBundleKey = Number(max(Object.keys(b2bBundleFormGroup))) + 1
    const newB2BBundleForm = {
      ...b2bBundleFormGroup,
      [newB2BBundleKey]: {
        ...DEFAULT_B2B_BUNDLE_FORM_FIELD,
        id: newB2BBundleKey
      }
    }
    updateOCState({ b2bBundleFormGroup: newB2BBundleForm, activeGroupId: Number(newB2BBundleKey) })
  }

  const deleteOrder = () => {
    const findGroupIndex = groupId => Object.values(b2bBundleFormGroup).findIndex(g => g.id === groupId)

    const updateState = (newmmccB2BFormGroup, activeGroupId) => {
      updateOCState({
        activeGroupId,
        b2bBundleFormGroup: newmmccB2BFormGroup
      })
    }

    const deletedGroupIndex = findGroupIndex(activeGroupId)
    const newB2BBundleFormGroup = { ...b2bBundleFormGroup }
    delete newB2BBundleFormGroup[activeGroupId]

    const previousGroup = Object.values(b2bBundleFormGroup)[deletedGroupIndex - 1]
    const nextGroup = Object.values(b2bBundleFormGroup)[deletedGroupIndex + 1]

    if (previousGroup) {
      return updateState(newB2BBundleFormGroup, previousGroup.id)
    }

    if (nextGroup) {
      return updateState(newB2BBundleFormGroup, nextGroup.id)
    }

    newB2BBundleFormGroup[activeGroupId] = {
      ...DEFAULT_B2B_BUNDLE_FORM_FIELD,
      id: activeGroupId
    }

    updateOCState({
      b2bBundleFormGroup: newB2BBundleFormGroup
    })
  }

  return (
    <>
      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_SHOWING && (
        <>
          <Row gutter={14}>
            <Col span={18}>
              <FormHeader>
                <Button size='small' icon={faArrowLeft} onClick={() => changeCreationMethod(undefined)}>
                  <T id='choose_input' allCaps />
                </Button>
                <HoverLink onClick={deleteOrder}>
                  <NoMarginIcon icon={faTrashAlt} color={Colors.lightGreyBalance} />
                  <Text id='delete_bundle_x' values={{ x: currentBundleIndex + 1 }} color={Colors.lightGreyBalance} />
                </HoverLink>
              </FormHeader>
              <div style={{ background: 'white' }}>
                <CreationForm
                  ref={formRef}
                  formFields={FORM_FIELDS_BY_SECTION}
                  dataSource={b2bBundleFormGroup[activeGroupId]}
                  country={destinationCountryCode}
                  onChange={changeOCForm}
                  inactiveBundles={inactiveBundles}
                  customsCurrency={customsCurrency}
                  includePadding
                />
              </div>
            </Col>
            <Col span={6}>
              <CustomCardWrapper>
                <OrderSummary
                  orders={Object.values(b2bBundleFormGroup)}
                  selectedId={currentBundleIndex}
                  onSelectOrder={selectOrder}
                  serviceType={selectedService.type}
                />
              </CustomCardWrapper>
              <ButtonFullWidth type='default' size='medium' onClick={addNewB2BBundleBox}>
                <StyledIcon gap={8} icon={faPlus} color='inherit' />
                <T id='add_another' allCaps />
              </ButtonFullWidth>
              <ButtonFullWidth type='primary' size='medium' onClick={submitB2BBundleOrder}>
                <T id='save_and_continue' allCaps />
              </ButtonFullWidth>
            </Col>
          </Row>
        </>
      )}

      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_PREVIEW && (
        <PreviewStepInfo
          quantity={b2bBundleFormGroup ? Object.keys(b2bBundleFormGroup).length : 0}
          numberOfCartons={totalB2BBundleNumberOfCartons}
          unit={customsCurrency}
          creationMethod={INPUT_METHODS.KEYBOARD}
          totalGoodsValue={totalB2BBundleGoodValues}
          totalRecipient={b2bBundleFormGroup ? Object.keys(b2bBundleFormGroup).length : 0}
          titleIntlKey={'total_recipient'}
          icon={faBoxes}
          onEditStep={handleEditStep}
        />
      )}
    </>
  )
}
