import { ALLOWED_COUNTRY, GLOBAL_COUNTRY_CODE, SERVICE_TYPE } from './constants'
import countryInfo from './countries.json'
import { Service } from './types'

export const getE2EServices = services => {
  if (!services?.length) return []
  return services.filter(
    service =>
      service.type !== SERVICE_TYPE.B2B_BUNDLE &&
      service.type !== SERVICE_TYPE.MMCCB2B &&
      service.type !== SERVICE_TYPE.MMCCB2C
  )
}

export const getMMCCServices = services => {
  if (!services?.length) return []
  return services.filter(
    service =>
      service.type === SERVICE_TYPE.MMCCB2C ||
      service.type === SERVICE_TYPE.MMCCB2B ||
      service.type === SERVICE_TYPE.B2B_BUNDLE
  )
}

export const getCountryInfo = countryCode => countryInfo[countryCode] || {}

export const getSortedServices = (services: Service[]) => {
  return [...services].sort((a, b) => {
    // First sort by XX status (XX goes last)
    if (a.origin_country === 'XX' && b.origin_country !== 'XX') return 1
    if (a.origin_country !== 'XX' && b.origin_country === 'XX') return -1

    // Then sort by origin country name
    const aOriginName = getCountryInfo(a.origin_country).name || ''
    const bOriginName = getCountryInfo(b.origin_country).name || ''
    const originCompare = aOriginName.localeCompare(bOriginName)
    if (originCompare !== 0) return originCompare

    // If origin countries are the same, sort by destination country name
    const aDestName = getCountryInfo(a.destination_country).name || ''
    const bDestName = getCountryInfo(b.destination_country).name || ''
    return aDestName.localeCompare(bDestName)
  })
}

export const getCountriesFromService = (service: Service): { originCountry: string; destinationCountry: string } => {
  let originCountry = service?.origin_country
  let destinationCountry = service?.destination_country

  if (!ALLOWED_COUNTRY[destinationCountry]) {
    destinationCountry = GLOBAL_COUNTRY_CODE
  }

  return {
    originCountry,
    destinationCountry
  }
}
